export const DotsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_480_47548)">
      <path
        d="M4.35 5.7C5.09558 5.7 5.7 5.09558 5.7 4.35C5.7 3.60442 5.09558 3 4.35 3C3.60442 3 3 3.60442 3 4.35C3 5.09558 3.60442 5.7 4.35 5.7Z"
        fill="currentcolor"
      />
      <path
        d="M11.9999 5.7C12.7455 5.7 13.3499 5.09558 13.3499 4.35C13.3499 3.60442 12.7455 3 11.9999 3C11.2543 3 10.6499 3.60442 10.6499 4.35C10.6499 5.09558 11.2543 5.7 11.9999 5.7Z"
        fill="currentcolor"
      />
      <path
        d="M19.6498 5.7C20.3954 5.7 20.9998 5.09558 20.9998 4.35C20.9998 3.60442 20.3954 3 19.6498 3C18.9042 3 18.2998 3.60442 18.2998 4.35C18.2998 5.09558 18.9042 5.7 19.6498 5.7Z"
        fill="currentcolor"
      />
      <path
        d="M4.35 13.3499C5.09558 13.3499 5.7 12.7455 5.7 11.9999C5.7 11.2543 5.09558 10.6499 4.35 10.6499C3.60442 10.6499 3 11.2543 3 11.9999C3 12.7455 3.60442 13.3499 4.35 13.3499Z"
        fill="currentcolor"
      />
      <path
        d="M11.9999 13.3499C12.7455 13.3499 13.3499 12.7455 13.3499 11.9999C13.3499 11.2543 12.7455 10.6499 11.9999 10.6499C11.2543 10.6499 10.6499 11.2543 10.6499 11.9999C10.6499 12.7455 11.2543 13.3499 11.9999 13.3499Z"
        fill="currentcolor"
      />
      <path
        d="M19.6498 13.3499C20.3954 13.3499 20.9998 12.7455 20.9998 11.9999C20.9998 11.2543 20.3954 10.6499 19.6498 10.6499C18.9042 10.6499 18.2998 11.2543 18.2998 11.9999C18.2998 12.7455 18.9042 13.3499 19.6498 13.3499Z"
        fill="currentcolor"
      />
      <path
        d="M4.35 20.9998C5.09558 20.9998 5.7 20.3954 5.7 19.6498C5.7 18.9042 5.09558 18.2998 4.35 18.2998C3.60442 18.2998 3 18.9042 3 19.6498C3 20.3954 3.60442 20.9998 4.35 20.9998Z"
        fill="currentcolor"
      />
      <path
        d="M11.9999 20.9998C12.7455 20.9998 13.3499 20.3954 13.3499 19.6498C13.3499 18.9042 12.7455 18.2998 11.9999 18.2998C11.2543 18.2998 10.6499 18.9042 10.6499 19.6498C10.6499 20.3954 11.2543 20.9998 11.9999 20.9998Z"
        fill="currentcolor"
      />
      <path
        d="M19.6498 20.9998C20.3954 20.9998 20.9998 20.3954 20.9998 19.6498C20.9998 18.9042 20.3954 18.2998 19.6498 18.2998C18.9042 18.2998 18.2998 18.9042 18.2998 19.6498C18.2998 20.3954 18.9042 20.9998 19.6498 20.9998Z"
        fill="currentcolor"
      />
    </g>
    <defs>
      <clipPath id="clip0_480_47548">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
