import { DropdownItemsContainer } from '@/blocks/sections/Header/Components/Dropdown/DropdownItemsContainer';
import { DropdownWrapper } from '@/blocks/sections/Header/Components/Dropdown/DropdownWrapper';
import { getDropdownOverflowShift } from '@/blocks/sections/Header/Components/Dropdown/getDropdownOverflowShift';
import { hasSidebar } from '@/blocks/sections/Header/Components/Dropdown/hasSidebar';
import { INavItem } from '@/blocks/sections/Header/data/types';
import { isRtl } from '@/hooks/useIsRtl/isRtl';
import { useLocale } from 'next-intl';
import React, { useEffect, useRef } from 'react';
import { DropdownItem } from './DropdownItemsContainer/DropdownItem';
import { DropdownSidebar } from './DropdownSidebar';
import { DropdownSubitems } from './DropdownSidebar/DropdownSubitems';
import { useMenuAim } from './useMenuAim';

export function Dropdown(props: { items: INavItem[]; opened: boolean }) {
  const refDropdownItems = useRef<null | HTMLDivElement>(null);
  const refDropdownWrapper = useRef<null | HTMLDivElement>(null);
  const locale = useLocale();

  const { mouseleaveMenu, activeRow, mouseenterRow, clickRow } = useMenuAim({
    menuRef: refDropdownItems,
    submenuDirection: isRtl(locale).isRtl ? 'left' : 'right',
    defaultActiveRow: { rowIndex: 0, hasSubmenus: hasSidebar(props.items[0]) },
  });
  const isSidebarVisible = Boolean(activeRow?.hasSubmenus);
  const activeRowHasWidget = Boolean(props.items[activeRow!.rowIndex]?.widget);

  useEffect(() => {
    if (!refDropdownWrapper.current) {
      return;
    }
    refDropdownWrapper.current.style.setProperty('--viewport-overflow-shift', '0px');
    const dropdownOverflowShift = getDropdownOverflowShift(refDropdownWrapper.current, locale);
    refDropdownWrapper.current.style.setProperty('--viewport-overflow-shift', dropdownOverflowShift + 'px');
  }, [props.opened, isSidebarVisible, locale]);

  useEffect(() => {
    if (!refDropdownWrapper.current || !refDropdownItems.current) {
      return;
    }
    const dropdownItemsHeight = refDropdownItems.current.getBoundingClientRect().height;
    refDropdownWrapper.current.style.setProperty('--dropdown-items-height', `${dropdownItemsHeight}px`);
  }, [props.opened, isSidebarVisible, locale, activeRowHasWidget]);

  return (
    <DropdownWrapper
      ref={refDropdownWrapper}
      onMouseLeave={mouseleaveMenu}
      scrollableSidebar={activeRowHasWidget}
    >
      <DropdownItemsContainer ref={refDropdownItems}>
        {props.items.map((item, dropdownItemIndex) => (
          <DropdownItem
            key={dropdownItemIndex}
            onClick={() => clickRow(dropdownItemIndex, hasSidebar(item))}
            onMouseEnter={() => mouseenterRow(dropdownItemIndex, hasSidebar(item))}
            subitem={item}
            active={activeRow?.rowIndex === dropdownItemIndex}
          />
        ))}
      </DropdownItemsContainer>
      <DropdownSidebar
        visible={isSidebarVisible}
        onMouseEnter={() => mouseenterRow(activeRow?.rowIndex as number, true)}
      >
        {props.items.map((dropdownItem, dropdownItemIndex) => (
          <DropdownSubitems
            key={dropdownItemIndex}
            dropdownItem={dropdownItem}
            visible={dropdownItemIndex === activeRow?.rowIndex}
          />
        ))}
      </DropdownSidebar>
    </DropdownWrapper>
  );
}
