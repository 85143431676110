import { forwardRef } from 'react';

import { useParams } from 'next/navigation';

import { Locale, i18n } from '@/i18n/i18n-config';

import { LangPopupItem } from './LangPopupItem';
import styles from './styles.module.scss';

const orderedLangItems = [
  'en',
  'vi',
  //
  'es',
  'cn',
  //
  'pt',
  'jp',
  //
  'hi',
  'kr',
  //
  'ru',
  'bn',
  //
  'in',
  'th',
  //
  'tr',
  'ar',
].filter((langCode) => i18n.locales.includes(langCode as Locale));

function LangItems() {
  const { lang: currentLang } = useParams();
  return orderedLangItems.map((lang) => (
    <LangPopupItem selected={currentLang === lang} lang={lang} key={lang} />
  ));
}

// eslint-disable-next-line react/display-name
export const LangPopup = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div className={styles.langPopup} ref={ref}>
      <div className={styles.langPopupLangsList}>
        <LangItems />
      </div>
    </div>
  );
});
