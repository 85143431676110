import { WithTrendColor } from '@/blocks/components/WithTrendColor';
import { ComponentPropsWithoutRef } from 'react';
import styles from './styles.module.scss';
import { IconPriceTrend } from '@/blocks/components/Table/IconPriceTrend/IconPriceTrend';
import { FormatPercent, FormatPrice } from '@/blocks/formatters';
import classNames from 'classnames';
import { isNumber } from '@/blocks/components/Table/Cells/utils';

const CellPriceAndChange: React.FC<
  ComponentPropsWithoutRef<'td'> & {
    price?: number | undefined;
    change?: number | undefined;
    priceScale?: number | undefined;
    icon?: boolean | undefined;
  }
> = ({ icon, price, change, className, priceScale, ...rest }) => {
  return (
    <td className={classNames(className, styles.cellNarrow)} {...rest}>
      <div className={classNames(styles.cell, styles.cellPriceAndChange)}>
        {isNumber(price) && isNumber(change) ? (
          <>
            <div className={classNames(styles.cellPriceAndChangePrice)}>
              {<IconPriceTrend negative={change < 0} />}
              <FormatPrice price={price} priceScale={priceScale} />
            </div>
            <WithTrendColor
              valueToCalculateTrend={change}
              className={classNames(styles.cellPriceAndChangeChange)}
            >
              <FormatPercent value={change} />
            </WithTrendColor>
          </>
        ) : null}
      </div>
    </td>
  );
};
export { CellPriceAndChange };
