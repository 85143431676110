import styles from './styles.module.scss';
import classNames from 'classnames';

export function DropdownSidebar(props: {
  visible: boolean;
  onMouseEnter: React.MouseEventHandler;
  children: React.ReactNode;
}) {
  return (
    <div
      className={classNames(styles.dropdownSidebar, props.visible && styles.dropdownSidebarVisible)}
      onMouseEnter={props.onMouseEnter}
    >
      {props.children}
    </div>
  );
}
