export function IconStarConture({ width, height }: { width: string; height: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M12 4.5L14.4891 9.26061L14.4911 9.26095L19.875 10.1554L16.0373 14.0762L16.855 19.5L12 17.0405L11.9945 17.0433L7.14493 19.5L7.9627 14.0761L4.125 10.1554L9.50889 9.26095L9.51091 9.26062L12 4.5Z"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
}
