export const WaveIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_480_47529)">
      <path
        d="M2.25 11.9999C12 -8.78444 12 32.7843 21.75 11.9999"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_480_47529">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
