export const UsdtIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3759 11.8132C13.2967 11.8191 12.8872 11.8435 11.9739 11.8435C11.2474 11.8435 10.7316 11.8218 10.5507 11.8132C7.74327 11.6897 5.64777 11.201 5.64777 10.6158C5.64777 10.0307 7.74327 9.54267 10.5507 9.41719V11.3264C10.7343 11.3397 11.26 11.3707 11.9864 11.3707C12.8582 11.3707 13.2947 11.3344 13.3733 11.3271V9.41851C16.1748 9.54333 18.2656 10.032 18.2656 10.6158C18.2656 11.1996 16.1754 11.6883 13.3733 11.8125L13.3759 11.8132ZM13.3759 9.22105V7.51256H17.2856V4.90723H6.64104V7.51256H10.55V9.22039C7.37277 9.36634 4.9834 9.99571 4.9834 10.7499C4.9834 11.5041 7.37277 12.1328 10.55 12.2794V17.7542H13.3753V12.2774C16.5453 12.1315 18.9307 11.5028 18.9307 10.7492C18.9307 9.99571 16.5472 9.367 13.3753 9.22039L13.3759 9.22105Z"
      fill="currentcolor"
    />
  </svg>
);
