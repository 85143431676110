import { LinkSignin } from '@/blocks/components/PlatformLink';
import styles from './styles.module.scss';
import buttons from '@/styles/button.module.scss';
import classNames from 'classnames';
import { useTranslations } from 'next-intl';

export function ActionLoginLink() {
  const tr = useTranslations();
  return (
    <LinkSignin
      className={classNames(buttons.btn, buttons.btnLink, buttons.btnS, styles.actionLoginLink)}
      data-event-name={'site_menu_login_click'}
      data-event-subcategory={'login'}
    >
      {tr.rich('header.cta.login')}
    </LinkSignin>
  );
}
