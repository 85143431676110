export const IconSupport = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M25.5928 17.678L26.2514 18.2049C27.0199 18.8209 27.2056 19.8747 26.699 20.7015L25.6519 22.3876C25.1621 23.1739 24.1657 23.5306 23.2537 23.2388L22.2235 22.955C21.7337 22.8172 21.2102 22.8821 20.7711 23.1172L20.2306 23.409C19.7746 23.6522 19.4537 24.0737 19.3271 24.552L19.0991 25.4274C18.9133 26.3434 18.0773 27 17.1062 27H14.8938C13.9227 27 13.0867 26.3434 12.9009 25.4355L12.6729 24.5682C12.5463 24.0899 12.2169 23.6765 11.7694 23.4252L11.1952 23.1172C10.7476 22.874 10.2072 22.8172 9.71741 22.955C9.24452 23.0929 8.73786 23.2469 8.73786 23.2469C7.83432 23.5387 6.82944 23.1901 6.33967 22.3957L5.28412 20.6934C4.77746 19.8747 4.96324 18.8209 5.72323 18.2049L6.40722 17.5888C6.79566 17.2402 7.00677 16.762 7.00677 16.2594V15.7406C7.00677 15.238 6.78722 14.7598 6.40722 14.4112L5.72323 13.8032C4.96324 13.1872 4.77746 12.1334 5.29257 11.3147L6.34811 9.61238C6.83788 8.82609 7.83432 8.46942 8.74631 8.76124L9.77652 9.04495C10.2663 9.18276 10.7898 9.11791 11.2289 8.88283L11.7694 8.59101C12.2254 8.34783 12.5463 7.92631 12.6729 7.44805L12.9009 6.57259C13.0867 5.6566 13.9227 5 14.8938 5H17.1062C18.0773 5 18.9133 5.6566 19.0991 6.56448L19.3271 7.43183C19.4537 7.9101 19.7831 8.32351 20.2306 8.56669L20.7711 8.85851C21.2102 9.1017 21.7422 9.15844 22.2319 9.02063L23.2621 8.73692C24.1657 8.4451 25.1706 8.79366 25.6603 9.58806L26.7159 11.2903C27.2225 12.1091 27.0368 13.1629 26.2768 13.7789L25.5421 14.4355C25.1874 14.7517 24.9679 15.1975 24.9426 15.6676L24.9172 16.1621C24.8835 16.7539 25.1368 17.3132 25.5928 17.678Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1982 12.8094C14.8566 12.4345 15.6103 12.2739 16.364 12.3542C17.2043 12.4345 17.9927 12.8094 18.5905 13.4074C19.1882 14.0055 19.5608 14.791 19.6474 15.6389C19.7254 16.3887 19.5608 17.1474 19.1882 17.8079C18.8157 18.4684 18.2526 18.9951 17.5682 19.3164C16.8838 19.6377 16.1214 19.7448 15.3764 19.6109C14.6313 19.486 13.9469 19.1289 13.4098 18.5934C12.8727 18.0578 12.5175 17.3705 12.3875 16.6297C12.2576 15.8888 12.3615 15.1212 12.6821 14.4339C13.0113 13.7466 13.5398 13.1843 14.1982 12.8094Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
