export const IconCopy = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.83795 6.33771C7.05434 6.12132 7.34783 5.99976 7.65385 5.99976H18.3462C18.6522 5.99976 18.9457 6.12132 19.162 6.33771C19.3784 6.5541 19.5 6.84758 19.5 7.1536V8.23053C19.5 8.78281 19.9477 9.23053 20.5 9.23053C21.0523 9.23053 21.5 8.78281 21.5 8.23053V7.1536C21.5 6.31715 21.1677 5.51496 20.5763 4.9235C19.9848 4.33204 19.1826 3.99976 18.3462 3.99976H7.65385C6.81739 3.99976 6.0152 4.33203 5.42374 4.9235C4.83228 5.51496 4.5 6.31715 4.5 7.1536V17.8459C4.5 18.6824 4.83228 19.4846 5.42374 20.076C6.0152 20.6675 6.81739 20.9998 7.65385 20.9998H8.73077C9.28305 20.9998 9.73077 20.552 9.73077 19.9998C9.73077 19.4475 9.28305 18.9998 8.73077 18.9998H7.65385C7.34783 18.9998 7.05434 18.8782 6.83795 18.6618C6.62157 18.4454 6.5 18.1519 6.5 17.8459V7.1536C6.5 6.84758 6.62157 6.5541 6.83795 6.33771ZM13.5 14.3074C13.5 13.5852 14.0855 12.9998 14.8077 12.9998H25.1923C25.9145 12.9998 26.5 13.5852 26.5 14.3074V24.6921C26.5 25.4143 25.9145 25.9998 25.1923 25.9998H14.8077C14.0855 25.9998 13.5 25.4143 13.5 24.6921V14.3074ZM14.8077 10.9998C12.9809 10.9998 11.5 12.4807 11.5 14.3074V24.6921C11.5 26.5189 12.9809 27.9998 14.8077 27.9998H25.1923C27.0191 27.9998 28.5 26.5189 28.5 24.6921V14.3074C28.5 12.4807 27.0191 10.9998 25.1923 10.9998H14.8077Z"
      fill="currentColor"
    />
  </svg>
);
