'use client';

import { PropsWithChildren, createContext } from 'react';

import { FeatureFlags } from '@/blocks/AB/types';

export const FeatureFlagsContext = createContext<FeatureFlags>({});

export function FeatureFlagsProvider({ children, value }: PropsWithChildren<{ value: FeatureFlags }>) {
  // console.log({ featureFlags: value });
  return <FeatureFlagsContext.Provider value={value}>{children}</FeatureFlagsContext.Provider>;
}
