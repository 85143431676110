export const GiftIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.8755 12.75V19.5C18.8755 19.6989 18.8031 19.8897 18.6741 20.0303C18.5452 20.171 18.3703 20.25 18.188 20.25H5.81299C5.63065 20.25 5.45578 20.171 5.32685 20.0303C5.19792 19.8897 5.12549 19.6989 5.12549 19.5V12.75M12 8.25V20.25M12 8.25C12 8.25 14.7397 8.25026 16.1929 6.84682C17.0428 6.02557 16.9955 4.55182 16.1929 3.67619C15.3902 2.80057 14.0393 2.74901 13.2891 3.67619C12 5.26151 12 8.25 12 8.25ZM12 8.25C12 8.25 9.26103 8.25026 7.80783 6.84682C6.95791 6.02557 7.00518 4.55182 7.80783 3.67619C8.61049 2.80057 9.96143 2.74901 10.7117 3.67619C12.0007 5.26151 12 8.25 12 8.25ZM4.4375 8.25H19.5625C19.9422 8.25 20.25 8.58579 20.25 9V12C20.25 12.4142 19.9422 12.75 19.5625 12.75H4.4375C4.0578 12.75 3.75 12.4142 3.75 12V9C3.75 8.58579 4.0578 8.25 4.4375 8.25Z"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
