'use client';

import React, { Fragment, ReactNode, useEffect, useRef } from 'react';

import { motion, useAnimation, useInView } from 'framer-motion';

import styles from './styles.module.scss';

type AnimatedTextProps = {
  children: ReactNode;
  el?: keyof JSX.IntrinsicElements;
  className?: string;
  once?: boolean;
  disableAnimation?: boolean;
};

const defaultAnimations = {
  hidden: {
    // clipPath hide the text box whole
    clipPath: 'inset(0 0 100% 0)',
    y: '100%',
  },
  visible: {
    clipPath: 'inset(-10% -5% -10% -5%)',
    y: '0',

    transition: {
      duration: 0.5,
      easing: 'easeInOut',
    },
  },
};

const splitText = (node: ReactNode): ReactNode => {
  if (typeof node === 'string') {
    // console.log('string', node);
    // Разделение строки на слова и оборачивание каждого слова в <motion.span>
    return node.split(/(\s+)/).map((part, index) => {
      return part.trim() === '' ? (
        part
      ) : (
        <Fragment key={`${part}-${index}`}>
          <motion.span className={styles.inlineBlock} variants={defaultAnimations}>
            {part}
          </motion.span>
        </Fragment>
      );
    });
  } else if (React.isValidElement(node)) {
    // console.log('element', node);
    // Если это React элемент, рекурсивно обработать его дочерние элементы
    return React.cloneElement(node, { ...node.props }, React.Children.map(node.props.children, splitText));
  } else if (Array.isArray(node)) {
    // console.log('array', node);
    // Если это массив ReactNodes, рекурсивно обработать каждый элемент массива
    return node.map((child, index) => <Fragment key={index}>{splitText(child)}</Fragment>);
  }
  return node;
};

export const AnimatedText = ({
  children,
  el: Wrapper = 'h2',
  className,
  once = true,
  disableAnimation = false,
}: AnimatedTextProps) => {
  const controls = useAnimation();
  const ref = useRef(null);
  const isInView = useInView(ref, { amount: 0.5, once });
  // console.log('children', children);
  // console.log('type', typeof children);

  useEffect(() => {
    if (isInView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [isInView]);

  if (disableAnimation) {
    return <Wrapper className={className}>{children}</Wrapper>;
  }

  return (
    <Wrapper className={className}>
      <span className="sr-only">{children}</span>
      <motion.span
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={{
          visible: { transition: { staggerChildren: 0.1 } },
          hidden: {},
        }}
        role="presentation"
        aria-hidden
      >
        {splitText(children)}
      </motion.span>
    </Wrapper>
  );
};
