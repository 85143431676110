export const IconMoney = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" fill="none">
    <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}>
      <path d="M16 20a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z" />
      <path d="M30 8H2v16h28V8Z" />
      <path d="M30 13a6.1 6.1 0 0 1-5-5m0 16a6.1 6.1 0 0 1 5-5M2 19a6.1 6.1 0 0 1 5 5M7 8a6.1 6.1 0 0 1-5 5" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);
