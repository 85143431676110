export const IconLock = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" fill="none">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.5 12V9a5 5 0 0 0-10 0v3h10Zm-12-3v3h-1a4 4 0 0 0-4 4v10a4 4 0 0 0 4 4h16a4 4 0 0 0 4-4V16a4 4 0 0 0-4-4h-1V9a7 7 0 1 0-14 0Zm12 5h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-16a2 2 0 0 1-2-2V16a2 2 0 0 1 2-2h13Zm-4 4.5a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0v-5Z"
      clipRule="evenodd"
    />
  </svg>
);
