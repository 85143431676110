import { LinkI18n } from '@/i18n/routing';

import styles from './styles.module.scss';

export function SelectedProductLabel(props: { href: any; title: any }) {
  const { href, title, ...rest } = props;
  return (
    <LinkI18n href={props.href} className={styles.selectedProductLabel} {...rest}>
      {props.title}
    </LinkI18n>
  );
}
