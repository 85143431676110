import rtl from '@/styles/rtl.module.scss';

export const IconWallet = () => (
  <svg
    className={rtl.dirDependent}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.45 13.45C5.45 13.2015 5.24853 13 5 13C4.75147 13 4.55 13.2015 4.55 13.45V13.8232H3.90179C3.67989 13.8232 3.5 14.0031 3.5 14.225V15.9232V16.0762V16.8232V16.9762V18.6744C3.5 18.8963 3.67989 19.0762 3.90179 19.0762H4.55V19.45C4.55 19.6985 4.75147 19.9 5 19.9C5.24853 19.9 5.45 19.6985 5.45 19.45V19.0762H5.75C6.57843 19.0762 7.25 18.4046 7.25 17.5762C7.25 17.1274 7.05289 16.7246 6.74052 16.4497C7.05289 16.1748 7.25 15.772 7.25 15.3232C7.25 14.4948 6.57843 13.8232 5.75 13.8232H5.45V13.45ZM4.4 15.9232V14.7232H5.75C6.08137 14.7232 6.35 14.9919 6.35 15.3232C6.35 15.6546 6.08137 15.9232 5.75 15.9232H4.4ZM4.4 16.9762H5.75C6.08137 16.9762 6.35 17.2448 6.35 17.5762C6.35 17.9075 6.08137 18.1762 5.75 18.1762H4.4V16.9762Z"
      fill="currentcolor"
    />
    <path
      d="M14.75 11.5C14.75 10.2574 15.7574 9.25 17 9.25H20.75V13.75H17C15.7574 13.75 14.75 12.7426 14.75 11.5Z"
      stroke="currentcolor"
      strokeWidth="1.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 7C3.5 5.34315 4.84315 4 6.5 4H18.5C20.1569 4 21.5 5.34315 21.5 7V15.25C21.5 16.9069 20.1569 18.25 18.5 18.25H9.5C9.08579 18.25 8.75 17.9142 8.75 17.5C8.75 17.0858 9.08579 16.75 9.5 16.75H18.5C19.3284 16.75 20 16.0784 20 15.25V7C20 6.17157 19.3284 5.5 18.5 5.5H6.5C5.67157 5.5 5 6.17157 5 7V10.75C5 11.1642 4.66421 11.5 4.25 11.5C3.83579 11.5 3.5 11.1642 3.5 10.75V7Z"
      fill="currentcolor"
    />
  </svg>
);
