import rtl from '@/styles/rtl.module.scss';

export const IconTrader = () => (
  <svg
    className={rtl.dirDependent}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7501 9C15.9927 9 17.0001 7.99264 17.0001 6.75C17.0001 5.50736 15.9927 4.5 14.7501 4.5C13.5074 4.5 12.5001 5.50736 12.5001 6.75C12.5001 7.99264 13.5074 9 14.7501 9ZM14.7501 10.5C16.8211 10.5 18.5001 8.82107 18.5001 6.75C18.5001 4.67893 16.8211 3 14.7501 3C12.679 3 11.0001 4.67893 11.0001 6.75C11.0001 8.82107 12.679 10.5 14.7501 10.5ZM11.7509 12C11.3367 12 11.0009 12.3358 11.0009 12.75C11.0009 13.1642 11.3367 13.5 11.7509 13.5H16.2509C18.322 13.5 20.0009 15.1789 20.0009 17.25V20.25C20.0009 20.6642 20.3367 21 20.7509 21C21.1651 21 21.5009 20.6642 21.5009 20.25V17.25C21.5009 14.3505 19.1504 12 16.2509 12H11.7509ZM5.00136 13.4977C5.00139 13.0835 4.66562 12.7477 4.2514 12.7476C3.83719 12.7476 3.50139 13.0834 3.50136 13.4976L3.50098 20.25C3.50095 20.6642 3.83672 21 4.25093 21C4.66515 21 5.00095 20.6643 5.00098 20.25L5.00136 13.4977ZM8.75006 7.5C8.75006 7.08579 8.41427 6.75 8.00006 6.75C7.58585 6.75 7.25006 7.08579 7.25006 7.5L7.25006 20.25C7.25006 20.6642 7.58585 21 8.00006 21C8.41428 21 8.75006 20.6642 8.75006 20.25L8.75006 7.5Z"
      fill="currentcolor"
    />
  </svg>
);
