import React, { ElementType, forwardRef, ReactNode } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

export const Container = forwardRef(function Container(
  {
    tag: Component = 'div',
    className,
    variant,
    children,
    ...props
  }: {
    variant?: 'header' | 'footer';
    tag?: ElementType;
  } & React.HTMLAttributes<HTMLDivElement>,
  ref,
) {
  return (
    <Component
      className={classNames(styles.container, variant && styles[variant], className)}
      ref={ref}
      {...props}
    >
      {children}
    </Component>
  );
});
