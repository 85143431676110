export const logoPath = [
  {
    path: 'M755.358 0.120422H785.541L689.499 146.143H658.923L755.358 0.120422Z',
    fill: 'url(#paint0_linear_1897_6239)',
    delay: 0.7,
  },
  {
    path: 'M701.045 66.1354L659.61 0.120422H692.458L708.709 29.1364C713.722 38.0894 713.132 49.1247 707.19 57.4876C705.162 60.3431 703.093 63.2528 701.045 66.1422V66.1354Z',
    fill: 'url(#paint0_linear_1897_6239)',
    delay: 0.7,
  },
  {
    path: 'M743.325 79.9709L784.861 146.142H752.013L735.66 116.963C730.648 108.024 731.238 96.9952 737.173 88.639C739.208 85.7768 741.276 82.8603 743.325 79.9709Z',
    fill: 'url(#paint0_linear_1897_6239)',
    delay: 0.7,
  },
  {
    path: 'M793.169 146.144V0.121582H849.227C859.746 0.121582 868.503 1.76975 875.489 5.07287C882.482 8.376 887.704 12.8796 891.17 18.5974C894.629 24.3151 896.366 30.7653 896.366 37.9549C896.366 43.9032 895.24 48.9766 892.988 53.1954C890.729 57.4141 887.677 60.8258 883.825 63.4371C879.972 66.0484 875.658 67.9475 870.89 69.1209C870.89 69.1209 881.098 72.3358 885.839 75.2456C890.573 78.1485 894.467 82.2181 897.505 87.4475C900.544 92.6701 902.063 98.9779 902.063 106.364C902.063 113.75 900.252 120.723 896.623 126.698C893.001 132.681 887.508 137.415 880.155 140.908C872.803 144.408 863.545 146.158 852.374 146.158H793.182L793.169 146.144ZM819.627 62.2569H845.896C850.407 62.2569 854.49 61.4091 858.146 59.7067C861.802 58.011 864.698 55.61 866.821 52.5035C868.944 49.4039 870.008 45.7277 870.008 41.4818C870.008 35.8658 868.014 31.2062 864.026 27.5165C860.045 23.8267 854.192 21.9819 846.487 21.9819H819.634V62.2637L819.627 62.2569ZM819.627 124.094H848.046C857.651 124.094 864.589 122.229 868.869 118.512C873.149 114.788 875.292 110.047 875.292 104.302C875.292 99.9274 874.214 96.0071 872.057 92.5412C869.9 89.0821 866.848 86.3351 862.894 84.3071C858.939 82.2791 854.286 81.2753 848.928 81.2753H819.627V124.101V124.094Z',
    fill: 'url(#paint0_linear_1897_6239)',
    delay: 0.8,
  },
  {
    path: 'M900.763 22.1452V0H995.875V22.1452H961.454V146.022H935.286V22.1452H900.763Z',
    fill: 'url(#paint0_linear_1897_6239)',
    delay: 0.9,
  },
  {
    path: 'M165.384 146.143V0.120422H225.56C236.534 0.120422 246.009 2.09416 253.979 6.04841C261.948 10.0027 268.107 15.6186 272.448 22.9032C276.795 30.1877 278.966 38.7948 278.966 48.7245C278.966 58.6542 276.761 67.4444 272.353 74.4983C267.944 81.559 261.656 86.9308 253.49 90.6206C245.324 94.3103 235.686 96.1552 224.576 96.1552H186.451V68.3194H218.01C223.301 68.3194 227.764 67.6343 231.386 66.2643C235.014 64.8942 237.755 62.7509 239.62 59.8479C241.478 56.9382 242.414 53.2349 242.414 48.7245C242.414 44.2141 241.485 40.4768 239.62 37.5061C237.755 34.5353 235.014 32.3106 231.386 30.8456C227.764 29.3737 223.267 28.6412 217.909 28.6412H200.566V146.15H165.384V146.143ZM242.896 141.524L209.783 79.4021H247.515L283.971 146.143H250.594C247.372 146.143 244.415 144.366 242.903 141.524H242.896Z',
    fill: 'url(#paint0_linear_1897_6239)',
    delay: 0.3,
  },
  {
    path: 'M327.459 0.120422V137.441C327.459 142.25 323.566 146.143 318.757 146.143H292.278V0.120422H327.459Z',
    fill: 'url(#paint0_linear_1897_6239)',
    delay: 0.4,
  },
  {
    path: 'M523.866 146.143V0.120422H625.693V28.8379H559.054V58.3354H620.402V86.3611H559.054V117.425H625.598V146.143H523.873H523.866Z',
    fill: 'url(#paint0_linear_1897_6239)',
    delay: 0.6,
  },
  {
    path: 'M150.557 24.28C146.217 16.6699 140.058 10.742 132.088 6.48928C124.112 2.24337 114.644 0.120422 103.663 0.120422H43.4875V55.4596H61.1018C70.8077 55.4596 78.6688 47.5918 78.6688 37.8927V28.6412H96.0119C101.37 28.6412 105.86 29.5433 109.489 31.3407C113.118 33.1381 115.858 35.6883 117.723 38.9847C119.581 42.2878 120.517 46.2217 120.517 50.7932C120.517 55.3646 119.588 59.1222 117.723 62.4524C115.858 65.7895 113.118 68.3872 109.489 70.2456C105.86 72.1109 101.404 73.0401 96.1136 73.0401H61.2782C51.4502 73.0401 43.4875 81.0096 43.4875 90.8308V146.143H78.6688V100.869H102.679C113.789 100.869 123.42 98.7936 131.593 94.6426C139.76 90.4917 146.047 84.6654 150.456 77.1503C154.864 69.6352 157.069 60.8518 157.069 50.7864C157.069 40.721 154.898 31.8901 150.551 24.28H150.557Z',
    fill: 'url(#paint0_linear_1897_6239)',
    delay: 0.2,
  },
  {
    path: 'M419.964 78.595L387.788 0.120422H344.081V146.143H378.482V56.0836L414.647 145.166H424.59C435.029 145.166 442.191 134.653 438.366 124.94L419.964 78.595Z',
    fill: 'url(#paint0_linear_1897_6239)',
    delay: 0.5,
  },
  {
    path: 'M463.643 0.120422L432.782 75.1902C431.039 79.4361 431.039 84.1839 432.782 88.4298C437.327 99.4922 442.963 113.403 446.117 121.203L472.861 56.3685V146.136H507.452V0.120422H463.65H463.643Z',
    fill: 'url(#paint0_linear_1897_6239)',
    delay: 0.5,
  },
  {
    path: 'M17.594 90.7218C27.311 90.7218 35.1881 82.8447 35.1881 73.1277C35.1881 63.4108 27.311 55.5337 17.594 55.5337C7.87712 55.5337 0 63.4108 0 73.1277C0 82.8447 7.87712 90.7218 17.594 90.7218Z',
    fill: 'url(#paint0_linear_1897_6239)',
    delay: 1.3,
  },
];
