import { nodeEnv } from '@/global/public-settings';
import { toPascalCase } from '@/utils/string';

export const log = (...args: any[]) => (nodeEnv !== 'production' ? console.log(...args) : null);

export function getClosestEventValue(el: HTMLElement, value: string) {
  return (el?.closest(`[data-event-${value}]`) as HTMLElement)?.dataset?.[`event${toPascalCase(value)}`];
}

export function getClosestLink(el: any) {
  const clickedElement = el;

  if (!clickedElement || !(clickedElement instanceof Element)) {
    return;
  }

  const closestLink = clickedElement.closest('a');

  if (!closestLink || !(closestLink instanceof Element)) {
    return;
  }

  return closestLink;
}
