import { isNil } from 'lodash';

export enum EnvNames {
  PRX_BUILD_NUMBER = 'PRX_BUILD_NUMBER',
  PRX_TRADE_CONDITIONS_ENDPOINT = 'PRX_TRADE_CONDITIONS_ENDPOINT',
  PRX_SCREENSHOTS_DOMAIN = 'PRX_SCREENSHOTS_DOMAIN',
  PRX_LINK_APP = 'PRX_LINK_APP',
  PRX_API_ENDPOINT = 'PRX_API_ENDPOINT',
  PRX_DOMAIN = 'PRX_DOMAIN',
  PRX_AFRICA_DOMAIN = 'PRX_AFRICA_DOMAIN',
  // public
  PRX_INTERCOM_ID = 'PRX_INTERCOM_ID',
  PRX_GTM_ID = 'PRX_GTM_ID',
  PRX_AMPLITUDE_ID = 'PRX_AMPLITUDE_ID',
  PRX_MAIN_SITE_DOMAIN = 'PRX_MAIN_SITE_DOMAIN',
  PRX_PARTNERS_DOMAIN = 'PRX_PARTNERS_DOMAIN',
  PRX_SUPPORTED_LANGS = 'PRX_SUPPORTED_LANGS',
  PRX_DEFAULT_LANG = 'PRX_DEFAULT_LANG',
  PRX_TINY_VERSION = 'PRX_TINY_VERSION',
  PRX_APPSFLYER_ID = 'PRX_APPSFLYER_ID',
  PRX_CAPTCHA_KEY = 'PRX_CAPTCHA_KEY',
  // other
  // DEV_INLINE_CONFIG = 'DEV_INLINE_CONFIG',
  // PRX_SHOW_AMBASSADOR = 'PRX_SHOW_AMBASSADOR',
  // PRX_STATIC_DOMAIN = 'PRX_STATIC_DOMAIN',
  // PRX_IS_STAGING = 'PRX_IS_STAGING',
  // PRX_SHOW_BLOG = 'PRX_SHOW_BLOG',
  // PRX_COV_STRATEGY_CACHE_TTL = 'PRX_COV_STRATEGY_CACHE_TTL',
}

type GetEnvParams = {
  envName: EnvNames;
  defaultValue?: any;
  optional?: boolean;
};

export function getEnv({ envName, defaultValue, optional = false }: GetEnvParams) {
  const isServer = typeof window === 'undefined';
  const shouldLog = isServer || process.env.NODE_ENV === 'development';

  let val;

  try {
    val = isServer ? process.env[envName] : JSON.parse(atob(window?.envs))?.[envName];
  } catch (e) {
    console.error(e);
  }

  if (typeof val === 'string' && val.length) {
    return val;
  }

  if (!isNil(defaultValue)) {
    console.warn(`Warning: no env ${envName}, default value "${defaultValue}" used`);
    return defaultValue;
  }

  if (!optional && shouldLog) {
    console.error(`Error: no ${envName} env, no default value`);
  }
}

export function extractBoolean(val: any, defaultValue: boolean): boolean {
  try {
    return val ? Boolean(parseInt(val, 10)) : defaultValue;
  } catch (e) {
    console.error(`Env extract boolean error: ${val}`);
    return defaultValue;
  }
}

export function extractNumber(val: any, defaultValue: number): number {
  try {
    return val ? parseInt(val, 10) : defaultValue;
  } catch (e) {
    console.error(`Env extract number error: ${val}`);
    return defaultValue;
  }
}
