export const BrokerIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 6C16 8.20914 14.2091 10 12 10C9.79086 10 8 8.20914 8 6C8 3.79086 9.79086 2 12 2C14.2091 2 16 3.79086 16 6ZM18 6C18 9.31371 15.3137 12 12 12C8.68629 12 6 9.31371 6 6C6 2.68629 8.68629 0 12 0C15.3137 0 18 2.68629 18 6ZM2 21C2 18.7909 3.79086 17 6 17H11.5C12.0523 17 12.5 16.5523 12.5 16C12.5 15.4477 12.0523 15 11.5 15H6C2.68629 15 0 17.6863 0 21V23C0 23.5523 0.447715 24 1 24C1.55228 24 2 23.5523 2 23V21Z"
      fill="currentcolor"
    />
    <path
      d="M16.875 14.25V15"
      stroke="currentcolor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.875 20.25V21"
      stroke="currentcolor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.4688 20.25H17.5781C17.8889 20.25 18.187 20.1117 18.4068 19.8656C18.6265 19.6194 18.75 19.2856 18.75 18.9375C18.75 18.5894 18.6265 18.2556 18.4068 18.0094C18.187 17.7633 17.8889 17.625 17.5781 17.625H16.1719C15.8611 17.625 15.563 17.4867 15.3432 17.2406C15.1235 16.9944 15 16.6606 15 16.3125C15 15.9644 15.1235 15.6306 15.3432 15.3844C15.563 15.1383 15.8611 15 16.1719 15H18.2812"
      stroke="currentcolor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
