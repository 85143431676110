import { Logo } from '@/blocks/components/Logo';
import { LinkI18n } from '@/i18n/routing';

import styles from './styles.module.scss';

export function HeaderLogo({ component: Component = LinkI18n }: { component?: any }) {
  return (
    <Component href={'/'} className={styles.logo} title={'PrimeXBT'}>
      <Logo />
    </Component>
  );
}
