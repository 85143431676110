export const TrendUpIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75009 3C4.16431 3 4.50009 3.33579 4.50009 3.75V17.6745L8.36735 11.5973C8.56954 11.2796 8.97599 11.1611 9.31726 11.3204L14.304 13.6475L17.8214 7.19886L16.6942 7.5009C16.2941 7.60811 15.8828 7.37067 15.7756 6.97057C15.6684 6.57047 15.9058 6.15922 16.3059 6.05201L19.2037 5.27556C19.6038 5.16835 20.0151 5.40579 20.1223 5.80589L20.8987 8.70366C21.0059 9.10376 20.7685 9.51502 20.3684 9.62222C19.9683 9.72943 19.5571 9.49199 19.4498 9.09189L19.1361 7.92104L15.2835 14.9841C15.0939 15.3318 14.6667 15.4721 14.3079 15.3046L9.27944 12.958L5.11635 19.5H20.2501C20.6643 19.5 21.0001 19.8358 21.0001 20.25C21.0001 20.6642 20.6643 21 20.2501 21H3.76131C3.68818 21.0012 3.61446 20.9916 3.54257 20.9709C3.45248 20.945 3.36935 20.9027 3.29662 20.8474C3.21042 20.7822 3.14189 20.7011 3.09252 20.611C3.04298 20.5209 3.0114 20.4196 3.00261 20.3118C3.00054 20.2871 2.9997 20.2623 3.00009 20.2374V3.75C3.00009 3.33579 3.33588 3 3.75009 3Z"
      fill="currentcolor"
    />
  </svg>
);
