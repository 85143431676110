'use client';

import React, { useState } from 'react';

import { Container } from '@/blocks/components/Container';
import { DeprecatedTabPanel, DeprecatedTabs } from '@/blocks/components/DeprecatedTabs';
import { UspContent, UspContentProps } from '@/blocks/sections/UspTabs/UspContent';
import { UspHeader, UspHeaderProps } from '@/blocks/sections/UspTabs/UspHeader';
import { EventBlockNames } from '@/providers/analytics/types';

type Props = Omit<UspHeaderProps, 'children'> & {
  contents: UspContentProps[];
  tabs: { value: any; label: React.ReactNode; key?: string | number }[];
  initialSelected?: number;
};

export const UspTabs = ({ title, subtitle, cta, tabs, contents, initialSelected = 0 }: Props) => {
  const [selectedTab, setSelectedTab] = useState(initialSelected);

  return (
    <section data-event-block-name={EventBlockNames.usp_tabs}>
      <Container>
        <UspHeader title={title} subtitle={subtitle}>
          <DeprecatedTabs
            variant={'filled'}
            selected={selectedTab}
            onChange={({ value }) => {
              setSelectedTab(value);
            }}
            values={tabs}
          />
          {contents?.map((content, index) => {
            return (
              <DeprecatedTabPanel key={index} value={index} isActive={index === selectedTab}>
                <UspContent
                  list={content.list}
                  buttons={content.buttons}
                  image={content.image}
                  cta={cta}
                  disclaimer={content.disclaimer}
                  isActive={index === selectedTab}
                />
              </DeprecatedTabPanel>
            );
          })}
        </UspHeader>
      </Container>
    </section>
  );
};

export type { UspContentProps } from './UspContent';
export type { UspHeaderProps } from './UspHeader';
