import { ComponentPropsWithoutRef } from 'react';

import classNames from 'classnames';

import { isNumber } from '@/blocks/components/Table/Cells/utils';
import { WithTrendColor } from '@/blocks/components/WithTrendColor';
import { FormatPercent } from '@/blocks/formatters';

import styles from '../styles.module.scss';

const CellPct: React.FC<
  ComponentPropsWithoutRef<'td'> & {
    pct: number | undefined | null;
    icon?: boolean;
    colored?: boolean;
  }
> = ({ pct, className, colored = true, ...rest }) => {
  return (
    <td className={classNames(className)} {...rest}>
      <div className={classNames(styles.cell)}>
        {isNumber(pct) ? (
          <>
            {colored ? (
              <WithTrendColor valueToCalculateTrend={pct}>
                <FormatPercent value={pct} forcePlusSign={true} />
              </WithTrendColor>
            ) : (
              <FormatPercent value={pct} forcePlusSign={true} />
            )}
          </>
        ) : null}
      </div>
    </td>
  );
};

export { CellPct };
