export const GlossaryIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.25 20.25H5.25C4.42157 20.25 3.75 19.5784 3.75 18.75V16.8125M3.75 16.8125V5.25C3.75 4.42157 4.42157 3.75 5.25 3.75H18.75C19.5784 3.75 20.25 4.42157 20.25 5.25V16.8125H3.75Z"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M11.9443 7V7.77778"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9443 13.2222V13.9999"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4861 13.2223H12.6736C12.9959 13.2223 13.305 13.0789 13.5329 12.8236C13.7609 12.5684 13.8889 12.2222 13.8889 11.8612C13.8889 11.5002 13.7609 11.154 13.5329 10.8987C13.305 10.6435 12.9959 10.5001 12.6736 10.5001H11.2153C10.893 10.5001 10.5839 10.3567 10.3559 10.1014C10.128 9.84614 10 9.49993 10 9.13894C10 8.77795 10.128 8.43175 10.3559 8.17649C10.5839 7.92123 10.893 7.77783 11.2153 7.77783H13.4028"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
