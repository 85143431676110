export const NewsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 3C3.33579 3 3 3.33579 3 3.75V17.8929C3 19.436 4.09576 21 5.775 21H6.45H17.25C17.3373 21 17.4211 20.9851 17.499 20.9577C17.6608 20.9855 17.8281 21 18 21C19.6308 21 21 19.626 21 17.8929V10.8214C21 10.4072 20.6642 10.0714 20.25 10.0714H16.65V3.75C16.65 3.33579 16.3142 3 15.9 3H15.75H3.75ZM15 4.5H4.5V17.8929C4.5 18.864 5.15924 19.5 5.775 19.5H6.45H15.4374C15.1574 19.0208 15 18.4653 15 17.8929V10.8214V4.5ZM17.6404 19.4525C17.603 19.4296 17.5632 19.4099 17.5213 19.3936C17.0518 19.2115 16.65 18.6645 16.65 17.8929V11.5714H19.5V17.8929C19.5 18.8311 18.7692 19.5 18 19.5C17.8766 19.5 17.7561 19.4835 17.6404 19.4525ZM6 7.5C6 7.08579 6.33579 6.75 6.75 6.75H11.25C11.6642 6.75 12 7.08579 12 7.5C12 7.91421 11.6642 8.25 11.25 8.25H6.75C6.33579 8.25 6 7.91421 6 7.5ZM6.75 9.75C6.33579 9.75 6 10.0858 6 10.5C6 10.9142 6.33579 11.25 6.75 11.25H8.25C8.66421 11.25 9 10.9142 9 10.5C9 10.0858 8.66421 9.75 8.25 9.75H6.75Z"
      fill="currentcolor"
    />
  </svg>
);
