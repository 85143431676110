import rtl from '@/styles/rtl.module.scss';

export const IconChart = () => (
  <svg
    className={rtl.dirDependent}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.25009 3C4.66431 3 5.00009 3.33579 5.00009 3.75V17.6745L8.86735 11.5973C9.06954 11.2796 9.47599 11.1611 9.81726 11.3204L14.804 13.6475L18.3214 7.19886L17.1942 7.5009C16.7941 7.60811 16.3828 7.37067 16.2756 6.97057C16.1684 6.57047 16.4058 6.15922 16.8059 6.05201L19.7037 5.27556C20.1038 5.16835 20.5151 5.40579 20.6223 5.80589L21.3987 8.70366C21.5059 9.10376 21.2685 9.51502 20.8684 9.62222C20.4683 9.72943 20.0571 9.49199 19.9498 9.09189L19.6361 7.92104L15.7835 14.9841C15.5939 15.3318 15.1667 15.4721 14.8079 15.3046L9.77944 12.958L5.61635 19.5H20.7501C21.1643 19.5 21.5001 19.8358 21.5001 20.25C21.5001 20.6642 21.1643 21 20.7501 21H4.26131C4.18818 21.0012 4.11446 20.9916 4.04257 20.9709C3.95248 20.945 3.86935 20.9027 3.79662 20.8474C3.71042 20.7822 3.64189 20.7011 3.59252 20.611C3.54298 20.5209 3.5114 20.4196 3.50261 20.3118C3.50054 20.2871 3.4997 20.2623 3.50009 20.2374V3.75C3.50009 3.33579 3.83588 3 4.25009 3Z"
      fill="currentcolor"
    />
  </svg>
);
