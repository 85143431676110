export const LogoSiteJabber = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="184" height="40" viewBox="0 0 184 40" fill="none">
    <path
      d="M37.581 27.3888L27.1035 27.5849C26.7503 27.5849 26.3971 27.7812 26.1616 28.0952C25.1022 29.7825 21.8059 34.4915 18.8627 37.7878C18.2349 38.3764 17.2146 38.2195 16.7829 37.4739C16.7044 37.3169 16.5867 37.16 16.5867 37.1207C15.9588 35.6687 14.036 27.2711 13.1726 26.2115C12.1132 24.9558 3.0091 22.6798 1.5964 22.2089C0.576119 21.8557 0.301424 20.5215 1.08626 19.7759C3.40152 17.5784 7.12949 14.4783 9.79788 12.7124C10.1511 12.5162 10.3865 12.1237 10.3865 11.7313L10.2296 1.76399C10.2296 0.979147 11.0144 0.390525 11.7599 0.743701C13.9967 1.6855 17.9994 4.19696 20.5109 6.3945C20.7856 6.70842 21.2172 6.82613 21.6097 6.70842C23.4148 6.19829 28.4769 4.74634 33.4214 4.47165C34.0886 4.43242 34.6378 4.9818 34.5594 5.68815C34.324 8.04267 33.6568 13.1048 32.558 16.4404C32.4403 16.7936 32.4795 17.1467 32.7542 17.4606C33.8137 18.8341 36.3252 22.2874 38.5227 25.7406C38.9152 26.4862 38.4443 27.3888 37.581 27.3888Z"
      fill="white"
    />
    <path
      d="M25.6907 15.1062C25.6907 16.4011 24.6313 17.4999 23.3362 17.4999C22.0413 17.4999 20.9818 16.4404 20.9818 15.1062C20.9818 13.8111 22.0413 12.7124 23.3362 12.7124C24.6313 12.7124 25.6907 13.8111 25.6907 15.1062ZM37.581 27.3888L27.1035 27.5849C26.7503 27.5849 26.397 27.7812 26.1616 28.0952C25.1022 29.7825 21.8059 34.4915 18.8627 37.7878C18.2349 38.3764 17.2146 38.2195 16.7829 37.4739C16.7044 37.3169 16.5867 37.16 16.5867 37.1207C15.9588 35.6687 14.036 27.2711 13.1726 26.2115C12.1132 24.9558 3.0091 22.6798 1.5964 22.2089C0.576119 21.8557 0.301424 20.5215 1.08626 19.7759C3.40152 17.5784 7.12949 14.4783 9.79788 12.7124C10.1511 12.5162 10.3865 12.1237 10.3865 11.7313L10.2296 1.76399C10.2296 0.979147 11.0144 0.390525 11.7599 0.743701C13.9967 1.6855 17.9994 4.19696 20.5109 6.3945C20.7856 6.70842 21.2172 6.82613 21.6097 6.70842C23.4148 6.19829 28.4769 4.74634 33.4214 4.47165C34.0886 4.43242 34.6378 4.9818 34.5594 5.68815C34.324 8.04267 33.6568 13.1048 32.558 16.4404C32.4403 16.7936 32.4795 17.1467 32.7542 17.4606C33.8137 18.8341 36.3252 22.2874 38.5227 25.7406C38.9152 26.4862 38.4443 27.3888 37.581 27.3888ZM31.42 7.96418C31.4986 7.68947 31.2239 7.41477 30.9099 7.45404C20.3146 8.55281 10.622 16.2834 7.48263 19.0695C6.97249 19.5012 7.12949 20.3253 7.75734 20.5608C7.75734 20.5608 14.5068 21.6595 16.1159 23.033C17.293 24.0532 18.4311 30.1749 18.4311 30.1749C18.6665 30.7636 19.4122 30.8812 19.8438 30.4496C28.987 21.0709 31.0668 10.5541 31.42 7.96418Z"
      fill="url(#paint0_linear_8239_41980)"
    />
    <path
      d="M13.4874 33.4712C13.409 33.1181 13.2912 32.7649 13.1734 32.3724L9.79852 35.9435C9.56303 36.2182 9.44531 36.5714 9.44531 36.9247C9.44531 37.317 9.60231 37.6703 9.87701 37.9057C10.1124 38.1411 10.4656 38.2589 10.8188 38.2589C11.2112 38.2589 11.5645 38.102 11.8392 37.8273L14.076 35.4726C13.8799 34.9232 13.6837 34.256 13.4874 33.4712Z"
      fill="url(#paint1_linear_8239_41980)"
    />
    <path
      d="M11.6427 27.4672C11.368 27.271 10.8187 27.0749 10.1907 26.8394L1.79305 35.3941C1.51836 35.6688 1.40063 36.0219 1.40063 36.3751C1.40063 36.7283 1.51836 37.1207 1.79305 37.3561C2.06774 37.5916 2.42091 37.7486 2.7741 37.7486C3.12727 37.7486 3.48044 37.6308 3.79438 37.3561L12.1136 28.8799C11.9566 28.2913 11.7997 27.7812 11.6427 27.4672Z"
      fill="url(#paint2_linear_8239_41980)"
    />
    <path
      d="M4.06933 24.9165L1.28306 27.742C1.04759 28.0167 0.890625 28.37 0.890625 28.7231C0.890625 29.0763 1.00835 29.4688 1.28306 29.7042C1.55776 29.9397 1.91094 30.0966 2.26414 30.0966C2.61732 30.0966 3.00976 29.9397 3.24521 29.7042L7.13029 25.7799C6.03146 25.5052 4.93268 25.1912 4.06933 24.9165Z"
      fill="url(#paint3_linear_8239_41980)"
    />
    <path
      d="M13.4874 33.4712C13.409 33.1181 13.2912 32.7649 13.1734 32.3724L9.79852 35.9435C9.56303 36.2182 9.44531 36.5714 9.44531 36.9247C9.44531 37.317 9.60231 37.6703 9.87701 37.9057C10.1124 38.1411 10.4656 38.2589 10.8188 38.2589C11.2112 38.2589 11.5645 38.102 11.8392 37.8273L14.076 35.4726C13.8799 34.9232 13.6837 34.256 13.4874 33.4712Z"
      fill="url(#paint4_linear_8239_41980)"
      stroke="url(#paint5_linear_8239_41980)"
      strokeWidth="1.26766"
    />
    <path
      d="M4.06933 24.9165L1.28306 27.742C1.04759 28.0167 0.890625 28.37 0.890625 28.7231C0.890625 29.0763 1.00835 29.4688 1.28306 29.7042C1.55776 29.9397 1.91094 30.0966 2.26414 30.0966C2.61732 30.0966 3.00976 29.9397 3.24521 29.7042L7.13029 25.7799C6.03146 25.5052 4.93268 25.1912 4.06933 24.9165Z"
      stroke="url(#paint6_linear_8239_41980)"
      strokeWidth="1.26766"
    />
    <path
      d="M11.6427 27.4672C11.368 27.271 10.8187 27.0749 10.1907 26.8394L1.79305 35.3941C1.51836 35.6688 1.40063 36.0219 1.40063 36.3751C1.40063 36.7283 1.51836 37.1207 1.79305 37.3561C2.06774 37.5916 2.42091 37.7486 2.7741 37.7486C3.12727 37.7486 3.48044 37.6308 3.79438 37.3561L12.1136 28.8799C11.9566 28.2913 11.7997 27.7812 11.6427 27.4672Z"
      stroke="url(#paint7_linear_8239_41980)"
      strokeOpacity="0.5"
      strokeWidth="1.26766"
    />
    <g filter="url(#filter0_ii_8239_41980)">
      <path
        d="M37.581 27.3888L27.1035 27.5849C26.7503 27.5849 26.3971 27.7812 26.1616 28.0952C25.1022 29.7825 21.8059 34.4915 18.8627 37.7878C18.2349 38.3764 17.2146 38.2195 16.7829 37.4739C16.7044 37.3169 16.5867 37.16 16.5867 37.1207C15.9588 35.6687 14.036 27.2711 13.1726 26.2115C12.1132 24.9558 3.0091 22.6798 1.5964 22.2089C0.576119 21.8557 0.301424 20.5215 1.08626 19.7759C3.40152 17.5784 7.12949 14.4783 9.79788 12.7124C10.1511 12.5162 10.3865 12.1237 10.3865 11.7314L10.2296 1.76399C10.2296 0.979148 11.0144 0.390525 11.7599 0.743701C13.9967 1.6855 17.9994 4.19696 20.5109 6.3945C20.7856 6.70842 21.2172 6.82613 21.6097 6.70842C23.4148 6.19829 28.4769 4.74634 33.4214 4.47165C34.0886 4.43242 34.6378 4.9818 34.5594 5.68815C34.324 8.04267 33.6568 13.1048 32.558 16.4404C32.4403 16.7935 32.4795 17.1467 32.7542 17.4606C33.8138 18.8341 36.3252 22.2874 38.5227 25.7406C38.9152 26.4862 38.4443 27.3888 37.581 27.3888Z"
        stroke="url(#paint8_linear_8239_41980)"
        strokeWidth="1.26766"
      />
    </g>
    <path
      d="M54.128 16.693C53.011 15.6564 51.7823 15.3603 50.4047 15.3603C48.5801 15.3233 47.3887 15.9157 47.3887 17.0633C47.426 18.2479 48.6546 18.6921 50.4791 18.8773C53.0854 19.0994 56.511 19.6918 56.4737 23.3198C56.4365 25.9483 54.1281 27.7623 50.4419 27.7623C48.1333 27.7623 45.8249 27.244 43.9631 25.1709L45.527 23.0977C46.8674 24.5415 48.8409 25.1709 50.5163 25.1709C51.9312 25.1709 53.4951 24.6525 53.5323 23.3568C53.5696 22.0981 52.3408 21.6169 50.2557 21.3948C47.5748 21.1356 44.5216 20.284 44.4845 17.1743C44.4845 14.0645 47.7238 12.8799 50.3674 12.8799C52.5642 12.8799 54.2398 13.3612 55.8408 14.7679L54.128 16.693Z"
      fill="white"
    />
    <path
      d="M62.7284 9.06666C62.7284 11.51 59.0425 11.51 59.0425 9.06666C59.0425 6.69728 62.7284 6.69728 62.7284 9.06666ZM59.4145 13.176V27.318H62.3932V13.176H59.4145Z"
      fill="white"
    />
    <path
      d="M70.958 9.17787V13.2502H75.0914V15.8047H70.9211V22.5054C70.9211 23.9863 71.5908 24.8378 72.9688 24.8378C73.5274 24.8378 74.1977 24.6527 74.7931 24.3565L75.6493 26.8739C74.6813 27.3182 73.825 27.5033 72.8196 27.5033C69.7291 27.5773 67.9791 25.8373 67.9791 22.5424V15.8417H65.2612V13.2872H67.9791V9.47404L70.958 9.17787Z"
      fill="white"
    />
    <path
      d="M80.3036 21.3577C80.5639 23.5049 82.3513 24.9117 84.9207 24.9117C86.3723 24.9117 88.1597 24.3564 89.0161 23.3939L90.9526 25.2449C89.5003 26.8368 87.0426 27.6513 84.8458 27.6513C80.1919 27.6513 77.2131 24.6897 77.2131 20.21C77.2131 15.9157 80.2288 12.8799 84.6598 12.8799C89.2395 12.8799 92.33 15.6564 91.7714 21.3948L80.3036 21.3577ZM88.9417 18.8773C88.7558 16.656 87.1175 15.4714 84.7341 15.4714C82.5747 15.4714 80.7873 16.656 80.3405 18.8773H88.9417Z"
      fill="white"
    />
    <path
      d="M97.767 13.1762V28.9841C97.767 32.5382 96.4639 34.3892 93.2985 34.3892C92.2937 34.3892 91.2883 34.1301 90.3203 33.5007L91.4 31.1684C91.9954 31.4645 92.6288 31.6126 93.2242 31.6126C94.639 31.5756 94.8624 30.613 94.8624 28.9841V13.1762H97.767ZM94.4531 9.02978C94.4531 11.4732 98.139 11.4732 98.139 9.02978C98.139 6.62344 94.4531 6.62344 94.4531 9.02978Z"
      fill="white"
    />
    <path
      d="M113.181 13.2132H116.048C116.048 17.9149 116.048 22.6165 116.048 27.3181H113.218L113.068 25.0229C112.138 26.8369 109.941 27.6884 108.191 27.6884C104.058 27.7254 100.931 25.0599 100.931 20.2471C100.931 15.5455 104.17 12.917 108.266 12.917C110.276 12.917 112.212 13.8425 113.106 15.4715L113.181 13.2132ZM103.909 20.2471C103.909 23.1719 105.994 24.9489 108.526 24.9489C114.632 24.9489 114.632 15.6195 108.526 15.6195C105.957 15.6195 103.909 17.3595 103.909 20.2471Z"
      fill="white"
    />
    <path
      d="M123.048 7.32683V15.3974C123.979 13.7315 126.286 12.88 128.036 12.88C132.133 12.88 135.223 15.4344 135.223 20.2101C135.223 24.8748 132.095 27.5403 127.963 27.5403C126.063 27.5403 124.165 26.8369 123.048 25.0229L122.862 27.2442H120.069V7.25279L123.048 7.32683ZM123.197 20.2471C123.197 23.0238 125.245 24.8378 127.739 24.8378C130.197 24.8378 132.207 23.0608 132.207 20.2471C132.207 17.3596 130.197 15.6567 127.739 15.6567C125.282 15.6937 123.197 17.5447 123.197 20.2471Z"
      fill="white"
    />
    <path
      d="M141.33 7.32683V15.3974C142.261 13.7315 144.57 12.88 146.32 12.88C150.415 12.88 153.506 15.4344 153.506 20.2101C153.506 24.8748 150.378 27.5403 146.245 27.5403C144.346 27.5403 142.447 26.8369 141.33 25.0229L141.144 27.2442H138.352V7.25279L141.33 7.32683ZM141.478 20.2471C141.478 23.0238 143.526 24.8378 146.022 24.8378C148.479 24.8378 150.489 23.0608 150.489 20.2471C150.489 17.3596 148.479 15.6567 146.022 15.6567C143.564 15.6937 141.478 17.5447 141.478 20.2471Z"
      fill="white"
    />
    <path
      d="M158.866 21.3577C159.127 23.5049 160.915 24.9117 163.483 24.9117C164.936 24.9117 166.723 24.3564 167.579 23.3939L169.515 25.2449C168.063 26.8368 165.606 27.6513 163.408 27.6513C158.754 27.6513 155.776 24.6897 155.776 20.21C155.776 15.9157 158.792 12.8799 163.222 12.8799C167.803 12.8799 170.893 15.6564 170.334 21.3948L158.866 21.3577ZM167.504 18.8773C167.318 16.656 165.68 15.4714 163.297 15.4714C161.138 15.4714 159.351 16.656 158.904 18.8773H167.504Z"
      fill="white"
    />
    <path
      d="M176.144 13.213L176.329 15.0641C177.371 13.25 178.973 12.8799 180.499 12.8799C181.877 12.8799 183.217 13.3612 184 14.0645L182.659 16.619C181.952 16.0637 181.244 15.7675 180.165 15.7675C178.117 15.7675 176.329 17.0263 176.329 19.6177V27.244H173.35V13.139L176.144 13.213Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_ii_8239_41980"
        x="-0.000732422"
        y="0"
        width="39.2939"
        height="39.0343"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.253533" />
        <feGaussianBlur stdDeviation="0.126766" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_8239_41980" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.126766" />
        <feGaussianBlur stdDeviation="0.126766" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.441667 0 0 0 0 0.441667 0 0 0 0 0.441667 0 0 0 0.4 0"
        />
        <feBlend mode="normal" in2="effect1_innerShadow_8239_41980" result="effect2_innerShadow_8239_41980" />
      </filter>
      <linearGradient
        id="paint0_linear_8239_41980"
        x1="32.9803"
        y1="5.47525"
        x2="9.85105"
        y2="28.6045"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1745" stopColor="#52BFCA" />
        <stop offset="0.9163" stopColor="#7238DF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_8239_41980"
        x1="6.4151"
        y1="41.0898"
        x2="15.4837"
        y2="32.1757"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1875" stopColor="#25E2EA" />
        <stop offset="1" stopColor="#19A6D1" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_8239_41980"
        x1="-5.68405"
        y1="44.5989"
        x2="14.5468"
        y2="24.7124"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A443FD" />
        <stop offset="1" stopColor="#A959F3" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_8239_41980"
        x1="-2.43076"
        y1="33.3282"
        x2="7.59658"
        y2="23.4717"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.3958" stopColor="#E55EC4" />
        <stop offset="1" stopColor="#8225D0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_8239_41980"
        x1="6.4151"
        y1="41.0898"
        x2="15.4837"
        y2="32.1757"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1875" stopColor="#25E2EA" />
        <stop offset="1" stopColor="#19A6D1" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_8239_41980"
        x1="9.44531"
        y1="38.5433"
        x2="12.8981"
        y2="34.9622"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.0001" stopColor="#E4FCFF" />
        <stop offset="1" stopColor="#E4FCFF" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_8239_41980"
        x1="0.890625"
        y1="29.9549"
        x2="5.41882"
        y2="25.3279"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF5FE" />
        <stop offset="0.769332" stopColor="#FFF5FE" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_8239_41980"
        x1="1.83745"
        y1="37.4025"
        x2="11.5668"
        y2="27.4513"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F6EDFE" />
        <stop offset="0.850896" stopColor="#A851F8" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_8239_41980"
        x1="34.7963"
        y1="4.22135"
        x2="9.63322"
        y2="30.6204"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00924772" stopColor="#2D9FAB" />
        <stop offset="0.0885417" stopColor="#2D9FAB" stopOpacity="0.823892" />
        <stop offset="0.171875" stopColor="#2D9FAB" stopOpacity="0.8" />
        <stop offset="0.302083" stopColor="#2D9FAB" stopOpacity="0.8" />
        <stop offset="1" stopColor="white" stopOpacity="0.5" />
      </linearGradient>
    </defs>
  </svg>
);
