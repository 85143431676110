export const GavelIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_480_65709)">
      <path
        d="M11.4705 1.71914L5.47052 7.71916C5.17762 8.01205 5.17762 8.48693 5.47052 8.77982L6.97069 10.28C7.26358 10.5729 7.73845 10.5729 8.03135 10.28L14.0314 4.27997C14.3243 3.98708 14.3243 3.5122 14.0314 3.21931L12.5312 1.71914C12.2383 1.42625 11.7634 1.42625 11.4705 1.71914Z"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7205 9.96914L13.7205 15.9692C13.4276 16.2621 13.4276 16.7369 13.7205 17.0298L15.2207 18.53C15.5136 18.8229 15.9885 18.8229 16.2813 18.53L22.2814 12.53C22.5743 12.2371 22.5743 11.7622 22.2814 11.4693L20.7812 9.96914C20.4883 9.67625 20.0134 9.67625 19.7205 9.96914Z"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6555 4.65566L19.3443 10.3444"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.40552 9.90566L14.0943 15.5944"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3753 13.875L5.719 20.5312C5.42027 20.8289 5.01573 20.9961 4.594 20.9961C4.17227 20.9961 3.76773 20.8289 3.469 20.5312C3.17131 20.2325 3.00415 19.828 3.00415 19.4062C3.00415 18.9845 3.17131 18.58 3.469 18.2812L10.1253 11.625"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_480_65709">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
