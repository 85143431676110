export const IconDownload = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.5 18.417A1.083 1.083 0 0 0 5.583 19.5h12.833a1.083 1.083 0 0 0 1.084-1.083V14.75a.75.75 0 1 1 1.5 0v3.667A2.583 2.583 0 0 1 18.416 21H5.583A2.584 2.584 0 0 1 3 18.417V14.75a.75.75 0 0 1 1.5 0v3.667ZM12 3a.75.75 0 0 1 .75.75v9.19l3.302-3.304a.75.75 0 0 1 1.06 1.061L12.53 15.28a.75.75 0 0 1-1.061 0l-4.583-4.583a.75.75 0 1 1 1.06-1.06l3.303 3.302V3.75A.75.75 0 0 1 12 3Z"
      clipRule="evenodd"
    />
  </svg>
);
