import { appsflyerId } from '@/global/public-settings';
import { ReferralType, useReferralInfo } from '@/global/xd-storage/ref-utils';
import { useLastUtmSource } from '@/hooks/useLastUtmSource';

type UseMobileStoreLinkConfig = {
  location?: string;
};

export const useMobileStoreLink = ({ location = 'page' }: UseMobileStoreLinkConfig = {}) => {
  const { lastUtmSourceParams } = useLastUtmSource({
    utmcsr: 'pid',
    utmcmd: 'af_channel',
    utmccn: 'c',
    utmcct: 'af_ad',
    utmctr: 'af_keywords',
    utmhostname: 'af_siteid',
    ga_sid: 'clickid',
    pid: 'af_sub1',
    signup: 'af_sub2',
    gclid: 'af_sub3',
    fbclid: 'af_sub4',
    twclid: 'af_sub5',
  });

  const { referralInfo } = useReferralInfo();

  const referralParams: { [param: string]: string } = {};

  let linkBase = 'my6s';

  if (referralInfo && referralInfo.type === ReferralType.cellxpert) {
    referralParams['af_sub5'] = `cellxpert${referralInfo?.value.affid}${referralInfo?.value.affiliate_type}`;
    linkBase = `${appsflyerId}/cellxpert`;
  }

  return {
    googlePlay: `https://go.appxbt.link/${linkBase}?${new URLSearchParams({
      ...lastUtmSourceParams,
      af_web_dp: 'https://play.google.com/store/apps/details?id=com.primexbt.trade',
      af_c_id: location,
      ...referralParams,
    }).toString()}`,
    appStore: `https://go.appxbt.link/${linkBase}?${new URLSearchParams({
      ...lastUtmSourceParams,
      af_web_dp: 'https://apps.apple.com/us/app/primexbt-trading-investing/id1522267195',
      af_c_id: location,
      ...referralParams,
    }).toString()}`,
    universalLink: `https://go.appxbt.link/${linkBase}?${new URLSearchParams({
      ...lastUtmSourceParams,
      af_c_id: location,
      ...referralParams,
    }).toString()}`,
  };
};
