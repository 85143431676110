import React, { MouseEventHandler } from 'react';

import classNames from 'classnames';

import {
  CellActions,
  CellAsset,
  CellChart,
  CellHeading,
  CellPct,
  CellPrice,
  CellPriceAndChange,
} from './Cells';
import styles from './styles.module.scss';

/**
 * It is used as a static table, unlike MUI DataGrid - this
 * table does not have pagination and filtering and other features,
 * it simply displays data, but it renders on the server what is the advantage over
 * DataGrid which is renders on client
 *
 * TODO refactor this Table component and all related components
 */
const Table: React.FC<
  React.PropsWithChildren<{ className?: classNames.ArgumentArray | classNames.Argument }>
> = ({ children, className }) => {
  return (
    <table className={classNames(styles.table, className)}>
      <thead>
        <tr>
          {[children].flat(Infinity).filter((c) => c && (c as React.ReactElement).type === CellHeading)}
        </tr>
      </thead>
      <tbody>
        {Array.isArray(children)
          ? children.flat().filter((c) => {
              return c?.type === TableRow;
            })
          : null}
      </tbody>
    </table>
  );
};

const TableRow: React.FC<
  React.PropsWithChildren<{
    onClick?: MouseEventHandler<HTMLTableRowElement> | undefined;
    className?: classNames.Argument;
  }>
> = ({ children, onClick, className }) => {
  return (
    <tr onClick={onClick} className={classNames(className)}>
      {Array.isArray(children)
        ? children.filter((c) =>
            [CellPriceAndChange, CellAsset, CellPrice, CellPct, CellChart, CellActions].includes(c?.type),
          )
        : null}
    </tr>
  );
};

export {
  Table,
  TableRow,
  CellActions,
  CellChart,
  CellHeading,
  CellAsset,
  CellPrice,
  CellPct,
  CellPriceAndChange,
};
