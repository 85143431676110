import { Container } from '@/blocks/components/Container';
import { BurgerContext } from '@/blocks/sections/Header/Components/HeaderInner';
import { useHeaderScrollStates } from '@/blocks/sections/Header/hooks/useHeaderScrollStates';
import { useContext } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

export function SecondNavContainer(props: { product: React.ReactNode; children: React.ReactNode }) {
  const { pageScrolledDown } = useHeaderScrollStates();
  const { menuOpened } = useContext(BurgerContext);
  return (
    <div
      className={classNames(styles.secondNavRow, !pageScrolledDown && !menuOpened && styles.visible)}
      data-event-category={'site_sub_menu'}
    >
      <Container variant={'header'} className={styles.secondNavRowContainer}>
        {props.product}
        <div className={styles.secondNavRowItemsContainer}>{props.children}</div>
      </Container>
    </div>
  );
}
