export const IconGlobe = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.5 12a9 9 0 0 1-9 9m9-9a9 9 0 0 0-9-9m9 9c0-1.988-4.03-3.6-9-3.6s-9 1.612-9 3.6m18 0c0 1.988-4.03 3.6-9 3.6s-9-1.612-9-3.6m9 9a9 9 0 0 1-9-9m9 9c1.988 0 3.6-4.03 3.6-9s-1.612-9-3.6-9m0 18c-1.988 0-3.6-4.03-3.6-9s1.612-9 3.6-9m-9 9a9 9 0 0 1 9-9"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
