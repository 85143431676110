'use client';
import { useIsRtl } from '@/hooks/useIsRtl';
import styles from './styles.module.scss';
import { Options, Splide, SplideEventHandlers, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/core';
import { SliderDots } from './Pagination';
import classNames from 'classnames';
import './styles.scss';
import { WithClassName } from '@/blocks/components/types';
import React, { forwardRef, PropsWithChildren } from 'react';
import { SliderArrows } from '@/blocks/components/Slider/Arrows';
import { Container } from '@/blocks/components/Container';

export interface SliderProps extends WithClassName, PropsWithChildren {
  config?: Options;
  slideClassName?: string;
  dotsClassName?: string;
  arrowsContainerClassName?: string;
  trackContainerClassName?: string;
  shadow?: boolean;
  dimNotActive?: boolean;
  dimNotVisible?: boolean;
  pagination?: boolean;
  sliderArrows?: boolean;
  isNavigation?: boolean;
  controls?: any;
  onPaginationUpdated?: SplideEventHandlers['onPaginationUpdated'];
  onMove?: SplideEventHandlers['onMove'];
}

export const Slider = forwardRef<any, SliderProps>(function Slider(
  {
    children,
    config,
    shadow = false,
    dimNotActive = false,
    dimNotVisible = false,
    className,
    trackContainerClassName,
    controls,
    slideClassName,
    dotsClassName,
    arrowsContainerClassName,
    pagination = true,
    sliderArrows = true,
    isNavigation = false,
    onPaginationUpdated,
    onMove,
  },
  ref,
) {
  const { direction } = useIsRtl();

  const defaultOptions: Options = {
    type: 'loop',
    gap: '8px',
    cloneStatus: true,
    flickMaxPages: 1,
    perMove: 1,
    updateOnMove: true,
    snap: true,
    padding: 0,
    autoplay: true,
    interval: 3500,
    speed: 800,
    mediaQuery: 'min',
    isNavigation,
    pagination,
    direction,
    // breakpoints: {
    //   768: {
    //     gap: '30px',
    //     height: '295px',
    //     padding: '0',
    //     fixedWidth: '555px',
    //   },
    // },
  };

  const options: Options = {
    ...defaultOptions,
    ...config,
  };

  return (
    <div className={classNames(styles.sliderContainer, className)}>
      <Splide
        onPaginationUpdated={onPaginationUpdated}
        onMove={onMove}
        hasTrack={false}
        options={options}
        ref={ref}
      >
        <div
          className={classNames(styles.trackWrapper, {
            [styles.shadow]: shadow,
          })}
        >
          {sliderArrows && <SliderArrows arrowsContainerClassName={arrowsContainerClassName} />}
          <Container className={trackContainerClassName}>
            <SplideTrack>
              {!children
                ? null
                : [children].flat().map((child, index) => (
                    <SplideSlide
                      key={index}
                      className={classNames(styles.slide, slideClassName, {
                        [styles.slideOpacity]: dimNotActive,
                        [styles.dimNotVisible]: dimNotVisible,
                        [styles.navigation]: isNavigation,
                      })}
                    >
                      {child}
                    </SplideSlide>
                  ))}
            </SplideTrack>
          </Container>
        </div>
        {pagination ? <SliderDots className={dotsClassName} /> : null}
      </Splide>
    </div>
  );
});

export * from './SliderWithHeader';
