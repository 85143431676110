import { EnrichmentPlugin } from '@amplitude/analytics-types';

type ParamsMap = Record<string, any>;

export const pageViewTrackingEnrichment = ({ utmParams }: { utmParams: ParamsMap }): EnrichmentPlugin => {
  return {
    name: 'page-view-tracking-enrichment',
    type: 'enrichment',
    setup: async () => undefined,
    execute: async (event) => {
      if (event.event_type !== '[Amplitude] Page Viewed') {
        return event;
      }

      event.event_properties = {
        ...event.event_properties,
        ...utmParams,
      };

      return event;
    },
  };
};
