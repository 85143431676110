import { useEffect, useRef, useState } from 'react';

function getScroll() {
  return window.scrollY || document.documentElement.scrollTop;
}

export const useHeaderScrollStates = (): { pageScrolledDown: boolean; pageScrolled: boolean } => {
  const [pageScrolledDown, setPageScrolledDown] = useState(false);
  const [pageScrolled, setPageScrolled] = useState(true);
  const lastScroll = useRef(0);

  const updateHeaderState = () => {
    const scroll = getScroll();
    if (scroll > lastScroll.current) {
      setPageScrolledDown(true);
    } else if (scroll < lastScroll.current) {
      setPageScrolledDown(false);
    }
    setPageScrolled(scroll !== 0);
    lastScroll.current = scroll <= 0 ? 0 : scroll;
  };

  useEffect(() => {
    window.addEventListener('scroll', updateHeaderState);
    updateHeaderState();
    return () => window.removeEventListener('scroll', updateHeaderState);
  }, []);

  return { pageScrolledDown, pageScrolled };
};
