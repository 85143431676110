export const IconUsers = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 7.5C12 9.15685 10.6569 10.5 9 10.5C7.34315 10.5 6 9.15685 6 7.5C6 5.84315 7.34315 4.5 9 4.5C10.6569 4.5 12 5.84315 12 7.5ZM13.5 7.5C13.5 9.98528 11.4853 12 9 12C6.51472 12 4.5 9.98528 4.5 7.5C4.5 5.01472 6.51472 3 9 3C11.4853 3 13.5 5.01472 13.5 7.5ZM15.75 3C15.3358 3 15 3.33579 15 3.75C15 4.16421 15.3358 4.5 15.75 4.5C17.4069 4.5 18.75 5.84315 18.75 7.5C18.75 9.15685 17.4069 10.5 15.75 10.5C15.3358 10.5 15 10.8358 15 11.25C15 11.6642 15.3358 12 15.75 12C18.2353 12 20.25 9.98528 20.25 7.5C20.25 5.01472 18.2353 3 15.75 3ZM5.95082 15.75C4.73534 15.75 3.75 16.7353 3.75 17.9508V20.25C3.75 20.6642 3.41421 21 3 21C2.58579 21 2.25 20.6642 2.25 20.25V17.9508C2.25 15.9069 3.90691 14.25 5.95082 14.25H12.0492C14.0931 14.25 15.75 15.9069 15.75 17.9508V20.25C15.75 20.6642 15.4142 21 15 21C14.5858 21 14.25 20.6642 14.25 20.25V17.9508C14.25 16.7353 13.2647 15.75 12.0492 15.75H5.95082ZM18 13.5C17.5858 13.5 17.25 13.8358 17.25 14.25C17.25 14.6642 17.5858 15 18 15H18.0492C19.2647 15 20.25 15.9853 20.25 17.2008V20.25C20.25 20.6642 20.5858 21 21 21C21.4142 21 21.75 20.6642 21.75 20.25V17.2008C21.75 15.1569 20.0931 13.5 18.0492 13.5H18Z"
      fill="currentColor"
    />
  </svg>
);
