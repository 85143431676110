export const CalculatorIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_407_43984)">
      <path
        d="M16.5 6H7.5V10.5H16.5V6Z"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 20.25V3.75C19.5 3.33579 19.1642 3 18.75 3L5.25 3C4.83579 3 4.5 3.33579 4.5 3.75L4.5 20.25C4.5 20.6642 4.83579 21 5.25 21H18.75C19.1642 21 19.5 20.6642 19.5 20.25Z"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 15C8.87132 15 9.375 14.4963 9.375 13.875C9.375 13.2537 8.87132 12.75 8.25 12.75C7.62868 12.75 7.125 13.2537 7.125 13.875C7.125 14.4963 7.62868 15 8.25 15Z"
        fill="currentcolor"
      />
      <path
        d="M12 15C12.6213 15 13.125 14.4963 13.125 13.875C13.125 13.2537 12.6213 12.75 12 12.75C11.3787 12.75 10.875 13.2537 10.875 13.875C10.875 14.4963 11.3787 15 12 15Z"
        fill="currentcolor"
      />
      <path
        d="M15.75 15C16.3713 15 16.875 14.4963 16.875 13.875C16.875 13.2537 16.3713 12.75 15.75 12.75C15.1287 12.75 14.625 13.2537 14.625 13.875C14.625 14.4963 15.1287 15 15.75 15Z"
        fill="currentcolor"
      />
      <path
        d="M8.25 18.75C8.87132 18.75 9.375 18.2463 9.375 17.625C9.375 17.0037 8.87132 16.5 8.25 16.5C7.62868 16.5 7.125 17.0037 7.125 17.625C7.125 18.2463 7.62868 18.75 8.25 18.75Z"
        fill="currentcolor"
      />
      <path
        d="M12 18.75C12.6213 18.75 13.125 18.2463 13.125 17.625C13.125 17.0037 12.6213 16.5 12 16.5C11.3787 16.5 10.875 17.0037 10.875 17.625C10.875 18.2463 11.3787 18.75 12 18.75Z"
        fill="currentcolor"
      />
      <path
        d="M15.75 18.75C16.3713 18.75 16.875 18.2463 16.875 17.625C16.875 17.0037 16.3713 16.5 15.75 16.5C15.1287 16.5 14.625 17.0037 14.625 17.625C14.625 18.2463 15.1287 18.75 15.75 18.75Z"
        fill="currentcolor"
      />
    </g>
    <defs>
      <clipPath id="clip0_407_43984">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
