'use client';

import React, {
  ComponentPropsWithRef,
  ComponentPropsWithoutRef,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import { HTMLMotionProps, motion, useInView } from 'framer-motion';

import { TRANSITION_EASINGS } from '@/blocks/animations/transition-utils';

interface AppearInViewProps extends HTMLMotionProps<'div'> {
  delay?: number;
  duration?: number;
  yOffset?: number;
  initialScale?: number;
  disableScale?: boolean;
  once?: boolean;
  amount?: number;
}

// eslint-disable-next-line react/display-name
export const AppearInView = forwardRef<HTMLDivElement, AppearInViewProps>((props, ref) => {
  const {
    className,
    children,
    delay = 0.1,
    amount = 0.5,
    duration = 0.8,
    yOffset = 0,
    initialScale = 0.96,
    disableScale = false,
    once = true,
    ...rest
  } = props;
  const opacityDuration = disableScale ? duration : duration - 0.15;
  const transitionFn = 'cubic-bezier(0.15, 0.55, 0.55, 1)';

  const localRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(localRef, { amount, once });
  // merge refs
  useImperativeHandle(ref, () => localRef.current as HTMLDivElement);

  return (
    <motion.div
      ref={localRef}
      style={{
        y: isInView ? 0 : yOffset,
        scale: disableScale ? 1 : isInView ? 1 : initialScale,
        opacity: isInView ? 1 : 0,
        transition: `transform ${duration}s ${transitionFn} ${delay}s, opacity ${opacityDuration}s ${transitionFn} ${delay}s`,
        transformStyle: 'preserve-3d',
        transformOrigin: 'bottom',
      }}
      className={className}
      {...rest}
    >
      {children}
    </motion.div>
  );
});

export const StaggerChildren = ({
  children,
  className,
  delay = 0.2,
  once = true,
}: ComponentPropsWithoutRef<'div'> & {
  delay?: number;
  once?: boolean;
}) => {
  return (
    <motion.div
      className={className}
      initial="hidden"
      whileInView="visible"
      viewport={{ once }}
      variants={{
        visible: {
          transition: {
            staggerChildren: 0.2,
            delayChildren: delay,
          },
        },
        hidden: {},
      }}
    >
      {children}
    </motion.div>
  );
};

interface StaggeredChildProps extends ComponentPropsWithRef<'div'> {
  yOffset?: number;
}

// eslint-disable-next-line react/display-name
export const StaggeredChild = forwardRef<HTMLDivElement, StaggeredChildProps>((props, ref) => {
  const { children, className, yOffset = 20 } = props;

  return (
    <motion.div
      ref={ref}
      variants={{
        hidden: {
          y: yOffset,
          opacity: 0,
        },
        visible: {
          y: 0,
          opacity: 1,

          transition: {
            easing: TRANSITION_EASINGS.easeOutCubic,
            duration: 0.5,
          },
        },
      }}
      className={className}
    >
      {children}
    </motion.div>
  );
});
