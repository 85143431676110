'use client';

import { ReactNode } from 'react';

import classNames from 'classnames';
import { useTranslations } from 'next-intl';

import { IconMenuChat } from '@/blocks/components/Icons/IconMenuChat';
import { ShowIntercomBtn } from '@/blocks/components/Intercom';
import buttons from '@/styles/button.module.scss';

import styles from './styles.module.scss';

export function ContactAndSupportButton({ label }: { label?: ReactNode }) {
  const tr = useTranslations();
  return (
    <ShowIntercomBtn
      className={classNames(
        buttons.btn,
        buttons.btnM,
        buttons.btnOutline,
        buttons.btnIconRight,
        styles.supportBtn,
      )}
    >
      {label || tr.rich('footer.suppportButton.action')}
      <IconMenuChat />
    </ShowIntercomBtn>
  );
}
