export const FormatAssetName = (props: { assetName: string }) => {
  return props?.assetName?.split('/').join(' / ') || '-';
};

export const extractAssetName = ({
  name,
  description,
  category,
}: {
  name: string;
  description?: string;
  category?: string;
}): string => {
  return category === 'forex' ? name : description || name;
};

export const extractAssetDescription = ({
  name,
  description,
  category,
}: {
  name: string;
  description?: string;
  category?: string;
}): string => {
  return category === 'forex' ? description || name : name;
};
