import { ComponentPropsWithoutRef } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

const CellActions: React.FC<ComponentPropsWithoutRef<'td'>> = ({ children, className, ...rest }) => {
  return (
    <td className={classNames(styles.cellActions, styles.cellNarrow, className)} {...rest}>
      <div className={classNames(styles.cell, styles.cellActions)}>{children}</div>
    </td>
  );
};

export { CellActions };
