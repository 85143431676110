'use client';
import styles from './styles.module.scss';
import tabsStyles from '@/blocks/components/Controls/Tabs/styles.module.scss';
import React, { useState } from 'react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import { DropdownLinkWidget } from '@/blocks/sections/Header/Components/Widgets/Link';
import classNames from 'classnames';
import { useTabsData } from './useTabsData';

export function ConvertersWidget() {
  const data = useTabsData();
  const [selectedTab, setSelectedTab] = useState<string>(data[0].title);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <TabContext value={selectedTab}>
      <div className={styles.convertersWidgetWrapper}>
        <div className={styles.tabsStickyWrapper}>
          <TabList
            onChange={handleChange}
            scrollButtons={false}
            className={classNames(tabsStyles.tabs, styles.tabsWrapper)}
            variant="scrollable"
          >
            {data.map((tabData, index) => (
              <Tab key={index} disableRipple value={tabData.title} label={tabData.title} />
            ))}
          </TabList>
        </div>
        {data.map((tabData, index) => (
          <TabPanel key={index} value={tabData.title} style={{ padding: 0 }}>
            <ul>
              <li>
                {tabData.subitems?.map((subItem, subIndex) => (
                  <DropdownLinkWidget navItem={subItem} key={subIndex} />
                ))}
              </li>
            </ul>
          </TabPanel>
        ))}
      </div>
    </TabContext>
  );
}
