'use client';

import React from 'react';

import classNames from 'classnames';
import { useTranslations } from 'next-intl';

import { PartnersSignupLink } from '@/blocks/components/PlatformLink/LinkPartners';
import { PartnersSigninLink } from '@/blocks/components/PlatformLink/PartnersClientLink';
import { ActionLang } from '@/blocks/sections/Header/Components/Actions/ActionLang';
import styles from '@/blocks/sections/Header/Components/Actions/ActionLoginLink/styles.module.scss';
import { ActionsList } from '@/blocks/sections/Header/Components/Actions/ActionsContainer';
import { HeaderLogo } from '@/blocks/sections/Header/Components/Actions/Logo';
import { HeaderInner } from '@/blocks/sections/Header/Components/HeaderInner';
import buttons from '@/styles/button.module.scss';

function PartnersHeaderActions() {
  const tr = useTranslations();
  return (
    <ActionsList>
      <ActionLang />
      <PartnersSigninLink
        className={classNames(buttons.btn, buttons.btnLink, buttons.btnS, styles.actionLoginLink)}
      >
        {tr.rich('header.cta.login')}
      </PartnersSigninLink>
      <PartnersSignupLink
        className={classNames(
          buttons.btn,
          buttons.btnPrimary,
          buttons.btnXS,
          buttons.btnIconLeft,
          styles.actionSignupButton,
        )}
      >
        {tr.rich('sections.button.register')}
      </PartnersSignupLink>
    </ActionsList>
  );
}

export function HeaderPartners() {
  return (
    <HeaderInner
      headerData={[]}
      slotScrolledDown={null}
      slotActions={<PartnersHeaderActions />}
      slotLogo={<HeaderLogo />}
      slotBanner={null}
      slotMenuUnderCtaButtons={null}
    ></HeaderInner>
  );
}
