import { INavItem } from '@/blocks/sections/Header/data/types';
import { usePathname } from '@/i18n/routing';

export const isIncludesPage = (arr: any[], pathname: string): any => {
  for (let obj of arr) {
    if (obj.href === pathname) {
      return obj;
    }
    if (obj.subitems) {
      let result = isIncludesPage(obj.subitems, pathname);
      if (result) {
        return result;
      }
    }
  }
  return;
};

export function useSelectedProduct(headerData: INavItem[]) {
  const pathname = usePathname();

  const selectedProduct = headerData
    .at(0)
    ?.subitems?.find(
      (product) => product.href === pathname || isIncludesPage(product.subitems || [], pathname),
    );

  return {
    selectedProduct,
    selectedProductSection:
      pathname !== selectedProduct?.href
        ? selectedProduct?.subitems?.find(
            (subitem) =>
              subitem.href === pathname || (subitem.subitems && isIncludesPage(subitem.subitems, pathname)),
          )
        : null,
  };
}
