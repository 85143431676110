import React, { ReactNode } from 'react';

import { Options, Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/core';
import classNames from 'classnames';

import { AnimatedText } from '@/blocks/animations/animated-text';
import { SlideNext, SlidePrev } from '@/blocks/components/Slider/Arrows';
import { SliderProps } from '@/blocks/components/Slider/index';
import { useIsRtl } from '@/hooks/useIsRtl';
import typography from '@/styles/typography.module.scss';

import { SliderDots } from './Pagination';
import styles from './styles.module.scss';
import './styles.scss';

export type SliderWithHeaderProps = {
  title: ReactNode;
  subheading: ReactNode;
} & SliderProps;

export const SliderWithHeader = ({
  title,
  subheading,
  children,
  config,
  shadow = false,
  dimNotActive = false,
  slideClassName,
  className,
  pagination = false,
}: SliderWithHeaderProps) => {
  const { direction } = useIsRtl();

  const defaultOptions: Options = {
    type: 'loop',
    gap: '8px',
    cloneStatus: true,
    flickMaxPages: 1,
    perMove: 1,
    updateOnMove: true,
    snap: true,
    autoplay: true,
    interval: 3500,
    mediaQuery: 'min',
    isNavigation: true,
    pagination,
    direction,
  };

  const options: Options = {
    ...defaultOptions,
    ...config,
  };

  return (
    <div className={classNames(styles.sliderContainer, className)}>
      <Splide hasTrack={false} options={options}>
        <div className={styles.grid}>
          <div className={styles.header}>
            <div className={styles.headerContent}>
              <AnimatedText el={'h2'} className={typography.h2}>
                {title}
              </AnimatedText>
              <p className={classNames(typography.bodyM, typography.headerSubhead)}>{subheading}</p>
            </div>
          </div>

          <SplideTrack
            className={classNames(styles.headerTrackWrapper, {
              [styles.shadow]: shadow,
            })}
          >
            {!children
              ? null
              : [children].flat().map((child, index) => (
                  <SplideSlide
                    key={index}
                    className={classNames(styles.slide, slideClassName, {
                      [styles.slideOpacity]: dimNotActive,
                    })}
                  >
                    {child}
                  </SplideSlide>
                ))}
          </SplideTrack>

          <div className={classNames(styles.headerArrows, 'splide__arrows')}>
            <SlidePrev className={classNames('splide__arrow splide__arrow--prev', styles.prevButton)} />
            <SlideNext className={'splide__arrow splide__arrow--next'} />
          </div>
        </div>

        {pagination ? <SliderDots /> : null}
      </Splide>
    </div>
  );
};
