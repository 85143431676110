export const AnlIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4951 16.5C13.8088 16.5 16.4951 13.8137 16.4951 10.5C16.4951 10.0179 16.4383 9.54909 16.3309 9.09989L13.0558 12.375C12.47 12.9608 11.5202 12.9608 10.9345 12.375L8.99512 10.4357L5.54262 13.8882C6.62369 15.4653 8.43857 16.5 10.4951 16.5ZM11.9951 11.3143L15.7349 7.57459C14.7085 5.74013 12.7466 4.5 10.4951 4.5C7.18141 4.5 4.49512 7.18629 4.49512 10.5C4.49512 11.1938 4.61287 11.8601 4.82949 12.48L7.93446 9.375C8.52025 8.78921 9.46999 8.78921 10.0558 9.375L11.9951 11.3143ZM10.4951 3C13.1586 3 15.4978 4.38841 16.8285 6.48096L18.9648 4.34467C19.2577 4.05178 19.7326 4.05178 20.0254 4.34467C20.3183 4.63756 20.3183 5.11244 20.0254 5.40533L17.5318 7.89896C17.8315 8.70929 17.9951 9.58553 17.9951 10.5C17.9951 12.0906 17.5 13.5655 16.6555 14.779L20.427 18.5506C20.9467 19.0702 20.9467 19.9127 20.427 20.4323C19.9074 20.952 19.0649 20.952 18.5453 20.4323L14.7736 16.6607C13.5602 17.505 12.0855 18 10.4951 18C8.02503 18 5.83384 16.8059 4.4673 14.9635L3.15045 16.2803C2.85755 16.5732 2.38268 16.5732 2.08979 16.2803C1.79689 15.9874 1.79689 15.5126 2.08979 15.2197L3.67809 13.6314C3.23963 12.6784 2.99512 11.6178 2.99512 10.5C2.99512 6.35786 6.35298 3 10.4951 3Z"
      fill="currentcolor"
    />
  </svg>
);
