export const LoopIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.58089 19.7196L5.56187 17.7016L16.6965 17.7012C19.0725 17.7011 20.9987 15.7754 20.9992 13.3997L20.9995 12.0034C20.9996 11.5892 20.6639 11.2534 20.2496 11.2533C19.8354 11.2532 19.4995 11.5889 19.4994 12.0031L19.4991 13.3994C19.4988 14.9469 18.2441 16.2012 16.6965 16.2013L5.56151 16.2017L7.58105 14.1824C7.87396 13.8895 7.87396 13.4147 7.58105 13.1218C7.28814 12.8289 6.81324 12.8289 6.52033 13.1218L3.22032 16.4214C3.07964 16.5621 3.00062 16.7529 3.00064 16.9518C3.00066 17.1508 3.07972 17.3415 3.22042 17.4822L6.52039 20.7804C6.81335 21.0733 7.28825 21.0732 7.5811 20.7802C7.87395 20.4873 7.87386 20.0125 7.58089 19.7196ZM17.4792 3.22461C17.1863 2.93174 16.7114 2.93174 16.4185 3.22461C16.1256 3.51749 16.1256 3.99234 16.4185 4.28522L18.4378 6.30432L7.30284 6.30431C4.92643 6.30431 2.99996 8.23056 2.99996 10.6067V12.0033C2.99996 12.4175 3.33577 12.7532 3.75 12.7532C4.16424 12.7532 4.50004 12.4175 4.50004 12.0033V10.6067C4.50004 9.05894 5.7549 7.80422 7.30284 7.80423L18.4378 7.80424L16.4185 9.82331C16.1256 10.1162 16.1256 10.591 16.4185 10.8839C16.7114 11.1768 17.1863 11.1768 17.4792 10.8839L20.7789 7.58459C20.9196 7.44394 20.9986 7.25319 20.9986 7.05429C20.9986 6.85538 20.9196 6.66463 20.7789 6.52398L17.4792 3.22461Z"
      fill="currentcolor"
    />
  </svg>
);
