'use client';

import React, { ComponentPropsWithoutRef, RefObject, useLayoutEffect, useRef } from 'react';

import classNames from 'classnames';

import { LinkI18n } from '@/i18n/routing';
import { useBlockViewportPosition } from '@/providers/BlockViewportPositionProvider';
import scrollbar from '@/styles/scrollbar.module.scss';

import styles from './styles.module.scss';

type TabsProps = {
  selected: any;
  values: { value: any; label: React.ReactNode; key?: string | number }[];
  navigation?: boolean;
  variant?: 'filled';
  onChange?: (args: { value: any }) => void;
  onHover?: (args: { value: any }) => void;
  className?: classNames.Argument | classNames.ArgumentArray;
  tabClassName?: classNames.Argument | classNames.ArgumentArray;
  activeTabClassName?: classNames.Argument | classNames.ArgumentArray;
};

type TabProps = {
  value: any;
  label: React.ReactNode;
  active: boolean;
  navigation?: boolean;
  variant?: 'filled';
  onClick?: (args: { value: any }) => void;
  onHover?: (args: { value: any }) => void;
  tabClassName?: classNames.Argument | classNames.ArgumentArray;
  activeTabClassName?: classNames.Argument | classNames.ArgumentArray;
  parentRef?: RefObject<HTMLElement>;
};

function DeprecatedTab(props: TabProps) {
  const { setPosition } = useBlockViewportPosition();

  const className = classNames(styles.tab, props.tabClassName, {
    [styles.tabActive]: props.active,
    [props.activeTabClassName as string]: props.active,
    [styles.tabFilled]: props.variant === 'filled',
  });

  if (props.navigation) {
    return React.createElement(
      LinkI18n,
      {
        className,
        href: props.value,
        scroll: false,
        onClick: () => {
          if (!props.parentRef?.current) {
            return;
          }
          setPosition(props.parentRef);
        },
      },
      props.label,
    );
  }

  return React.createElement(
    'button',
    {
      className,
      onClick: (e) => {
        e.currentTarget.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        if (props.onClick) {
          props.onClick({ value: props.value });
        }
      },
      onMouseOver: () => props.onHover && props.onHover({ value: props.value }),
    },
    props.label,
  );
}

export const DeprecatedTabsOverflowContainer: React.FC<
  React.PropsWithChildren<{ className?: classNames.Argument | classNames.ArgumentArray }>
> = ({ children, className }) => {
  return <div className={classNames(styles.tabsOverflowContainer, className)}>{children}</div>;
};

export const DeprecatedTabs = (props: TabsProps) => {
  const ref = useRef<HTMLUListElement | null>(null);
  const { position, setPosition } = useBlockViewportPosition();

  useLayoutEffect(() => {
    if (!props.navigation || !position || !ref.current) {
      return;
    }
    window.scrollTo(0, ref.current.offsetTop - position.top);

    return () => {
      setPosition();
    };
  }, [props.navigation, position, setPosition]);

  return (
    <ul
      className={classNames(styles.tabsContainer, scrollbar.noScrollbarWithDescendants, props.className)}
      ref={ref}
    >
      {props.values.map((value) => (
        <DeprecatedTab
          variant={props.variant}
          active={props.selected === value.value}
          value={value.value}
          label={value.label}
          key={value.key || value.value}
          onClick={props.onChange}
          onHover={props.onHover}
          tabClassName={props.tabClassName}
          activeTabClassName={props.activeTabClassName}
          navigation={props.navigation}
          parentRef={ref}
        />
      ))}
    </ul>
  );
};

interface TabPanelProps extends ComponentPropsWithoutRef<'div'> {
  value: number;
  isActive: boolean;
}

export function DeprecatedTabPanel(props: TabPanelProps) {
  const { children, value, isActive, ...other } = props;

  return (
    <div role="tabpanel" className={styles.tabPanel} hidden={!isActive} {...other}>
      {children}
    </div>
  );
}
