export const HomeIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 9.84864L12 4.49876L19.5 9.84864L19.5 19.1644C19.5 19.2091 19.4818 19.2813 19.4004 19.356C19.3146 19.4346 19.1725 19.4987 19 19.4987H15.75V11.8281C15.75 11.4139 15.4142 11.0781 15 11.0781H9C8.58579 11.0781 8.25 11.4139 8.25 11.8281V19.4987H5C4.82753 19.4987 4.68544 19.4346 4.59965 19.356C4.5182 19.2813 4.5 19.2091 4.5 19.1644V9.84864ZM9.75 19.4987H14.25V12.5781H9.75V19.4987ZM3 9.84864C3 9.36387 3.23427 8.90899 3.62893 8.62748L11.1289 3.2776C11.6501 2.90581 12.3499 2.90581 12.8711 3.2776L20.3711 8.62748C20.7657 8.90899 21 9.36387 21 9.84864V19.1644C21 19.6509 20.7893 20.1175 20.4142 20.4614C20.0391 20.8054 19.5304 20.9987 19 20.9987H5C4.46957 20.9987 3.96086 20.8054 3.58579 20.4614C3.21071 20.1175 3 19.6509 3 19.1644V9.84864Z"
      fill="currentcolor"
    />
  </svg>
);

export const HomeIconFilled = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.4539 20.2174V15.5219C14.4539 15.3143 14.3677 15.1152 14.2143 14.9685C14.0608 14.8217 13.8527 14.7393 13.6358 14.7393H10.3632C10.1462 14.7393 9.93814 14.8217 9.78471 14.9685C9.63128 15.1152 9.54508 15.3143 9.54508 15.5219V20.2174C9.54508 20.425 9.45889 20.624 9.30546 20.7708C9.15203 20.9175 8.94393 21 8.72695 21H3.81814C3.60115 21 3.39306 20.9175 3.23963 20.7708C3.0862 20.624 3 20.425 3 20.2174V11.1726C3.00001 11.0635 3.02385 10.9557 3.06999 10.8559C3.11614 10.7562 3.18356 10.6668 3.26794 10.5935L11.4493 3.20384C11.5999 3.07269 11.7963 3 12 3C12.2037 3 12.4001 3.07269 12.5507 3.20384L20.7321 10.5935C20.8164 10.6668 20.8839 10.7562 20.93 10.8559C20.9761 10.9557 21 11.0635 21 11.1726V20.2174C21 20.425 20.9138 20.624 20.7604 20.7708C20.6069 20.9175 20.3988 21 20.1819 21H15.272C15.055 21 14.847 20.9175 14.6935 20.7708C14.5401 20.624 14.4539 20.425 14.4539 20.2174Z"
      fill="currentcolor"
    />
  </svg>
);
