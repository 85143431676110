'use client';
import { useEffect, useState } from 'react';

export type Breakpoint = 'desktop' | 'tablet' | 'mobile';

export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState<Breakpoint | null>(null);

  useEffect(() => {
    const tablet = window.matchMedia('(min-width: 768px)');
    const desktop = window.matchMedia('(min-width: 992px)');

    const onChange = () => {
      setBreakpoint(desktop.matches ? 'desktop' : tablet.matches ? 'tablet' : 'mobile');
    };

    if (breakpoint === null) {
      onChange();
    }

    tablet.addEventListener('change', onChange);
    desktop.addEventListener('change', onChange);

    return () => {
      tablet.removeEventListener('change', onChange);
      desktop.removeEventListener('change', onChange);
    };
  }, []);

  return breakpoint;
};
