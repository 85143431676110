export const BuyCryptoIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.95 13.45C4.95 13.2015 4.74853 13 4.5 13C4.25147 13 4.05 13.2015 4.05 13.45V13.8232H3.40179C3.17989 13.8232 3 14.0031 3 14.225V15.9232V16.0762V16.8232V16.9762V18.6744C3 18.8963 3.17989 19.0762 3.40179 19.0762H4.05V19.45C4.05 19.6985 4.25147 19.9 4.5 19.9C4.74853 19.9 4.95 19.6985 4.95 19.45V19.0762H5.25C6.07843 19.0762 6.75 18.4046 6.75 17.5762C6.75 17.1274 6.55289 16.7246 6.24052 16.4497C6.55289 16.1748 6.75 15.772 6.75 15.3232C6.75 14.4948 6.07843 13.8232 5.25 13.8232H4.95V13.45ZM3.9 15.9232V14.7232H5.25C5.58137 14.7232 5.85 14.9919 5.85 15.3232C5.85 15.6546 5.58137 15.9232 5.25 15.9232H3.9ZM3.9 16.9762H5.25C5.58137 16.9762 5.85 17.2448 5.85 17.5762C5.85 17.9075 5.58137 18.1762 5.25 18.1762H3.9V16.9762Z"
      fill="currentcolor"
    />
    <path
      d="M14.25 11.5C14.25 10.2574 15.2574 9.25 16.5 9.25H20.25V13.75H16.5C15.2574 13.75 14.25 12.7426 14.25 11.5Z"
      stroke="currentcolor"
      strokeWidth="1.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 7C3 5.34315 4.34315 4 6 4H18C19.6569 4 21 5.34315 21 7V15.25C21 16.9069 19.6569 18.25 18 18.25H9C8.58579 18.25 8.25 17.9142 8.25 17.5C8.25 17.0858 8.58579 16.75 9 16.75H18C18.8284 16.75 19.5 16.0784 19.5 15.25V7C19.5 6.17157 18.8284 5.5 18 5.5H6C5.17157 5.5 4.5 6.17157 4.5 7V10.75C4.5 11.1642 4.16421 11.5 3.75 11.5C3.33579 11.5 3 11.1642 3 10.75V7Z"
      fill="currentcolor"
    />
  </svg>
);
