import React from 'react';

import { RecapAsset } from '@/api/trading/types';
import { MarketsTable } from '@/blocks/sections/Markets';
import { renderCell } from '@/blocks/sections/Markets/useAssetsPresets/renderCell';

import { ColumnHeaders } from '@/app/[lang]/index/IndexMarketsSection/Tables/types';

import styles from './styles.module.scss';

export function CfdTable({
  markets,
  assetsNames,
  columnHeaders,
}: {
  markets: RecapAsset[];
  assetsNames: string[];
  columnHeaders: ColumnHeaders;
}) {
  const columns = [
    {
      title: columnHeaders.assets,
      renderCell: renderCell.asset,
      name: 'asset',
    },
    {
      title: columnHeaders.priceAndChange,
      renderCell: renderCell.priceAndChange,
      name: 'price-and-change',
    },
    {
      title: columnHeaders.buy,
      renderCell: renderCell.buy,
      name: 'buy',
    },
    {
      title: columnHeaders.sell,
      renderCell: renderCell.sell,
      name: 'sell',
    },
    // {
    //   title: tr('tpages.index.sections.markets.able.headers.spread'),
    //   renderCell: renderCell.spread,
    //   name: 'spread',
    // },
    {
      title: columnHeaders.chg24,
      renderCell: renderCell.chg24,
      name: 'chg24',
    },
    {
      title: columnHeaders.trend,
      renderCell: renderCell.trend,
      name: 'trend',
    },
    {
      title: '',
      renderCell: renderCell.actions(columnHeaders.cta, columnHeaders.enCta),
      name: 'actions',
    },
  ];

  return (
    <MarketsTable
      assets={markets}
      className={styles.table}
      assetsToDisplay={assetsNames}
      columns={columns}
      columnsVisibilityModel={{
        desktop: ['asset', 'price', 'buy', 'sell', 'chg24', 'trend', 'actions'],
        tablet: ['asset', 'price', 'buy', 'sell', 'chg24'],
        mobile: ['asset', 'price-and-change'],
      }}
    ></MarketsTable>
  );
}
