export const IconHandshake = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" fill="none">
    <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}>
      <path d="M23.5 9h-5l-5.7 5.54a1 1 0 0 0 .17 1.55c2.18 1.4 5.16 1.3 7.53-1.09l5 4 2-2m-2 2-5 5-8-2-7-5m2 7 3 2 4 1m-5-18 7-2 7 2M5.05 7.9l-3.1 6.2a1 1 0 0 0 .45 1.35L5.5 17l4-8-3.1-1.55a1 1 0 0 0-1.35.44Z" />
      <path d="M26.6 7.45 23.5 9l4 8 3.1-1.55a1 1 0 0 0 .45-1.34l-3.1-6.21a1 1 0 0 0-1.34-.45Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M.5 0h32v32H.5z" />
      </clipPath>
    </defs>
  </svg>
);
