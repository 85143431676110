export const BookIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.25 20.25H5.25C4.42157 20.25 3.75 19.5784 3.75 18.75V16.8125M3.75 16.8125V5.25C3.75 4.42157 4.42157 3.75 5.25 3.75H18.75C19.5784 3.75 20.25 4.42157 20.25 5.25V16.8125H3.75Z"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
