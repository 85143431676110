'use client';

import { useEffect } from 'react';

import { ErrorPageContent } from '@/blocks/components/ErrorPageLayout';

export default function Error({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
  useEffect(() => {
    console.error(error);
  }, [error]);

  return <ErrorPageContent reset={reset} />;
}
