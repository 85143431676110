export const AppleIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.0101 8.14526C19.9344 7.12668 18.1026 6.50203 16.5081 6.50203C15.1444 6.49886 13.8206 6.96219 12.7565 7.81512C11.7145 6.9797 10.4227 6.5174 9.08731 6.50203C5.62826 6.45513 2.91392 9.34392 3.00209 12.8039C3.03975 14.2872 3.37124 15.7482 3.97739 17.1025C4.58353 18.4568 5.45229 19.6774 6.53336 20.6937C7.09023 21.2185 7.8269 21.5101 8.59209 21.5087H16.8176C17.2262 21.5104 17.6307 21.428 18.006 21.2666C18.3813 21.1053 18.7194 20.8684 18.9992 20.5708C20.4858 18.9764 21.0101 17.4569 21.0101 17.4569C19.4354 16.3755 18.7591 14.5616 18.7591 12.5066C18.7591 10.7902 19.8546 9.24169 21.0101 8.14714V8.14526Z"
      stroke="#3760FF"
      strokeWidth="1.50067"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5082 2H16.4144C15.7487 1.99966 15.1019 2.22062 14.5755 2.62811C14.0492 3.0356 13.6732 3.6065 13.5068 4.25101"
      stroke="#3760FF"
      strokeWidth="1.50067"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
