export const SmartphoneIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_480_44860)">
      <path
        d="M18 20.25V3.75C18 2.92157 17.3284 2.25 16.5 2.25L7.5 2.25C6.67157 2.25 6 2.92157 6 3.75L6 20.25C6 21.0784 6.67157 21.75 7.5 21.75H16.5C17.3284 21.75 18 21.0784 18 20.25Z"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 6.75C12.6213 6.75 13.125 6.24632 13.125 5.625C13.125 5.00368 12.6213 4.5 12 4.5C11.3787 4.5 10.875 5.00368 10.875 5.625C10.875 6.24632 11.3787 6.75 12 6.75Z"
        fill="currentcolor"
      />
    </g>
    <defs>
      <clipPath id="clip0_480_44860">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
