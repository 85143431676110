import classNames from 'classnames';

import { IconLogIn } from '@/blocks/components/Icons';
import { LinkSignin } from '@/blocks/components/PlatformLink';
import buttons from '@/styles/button.module.scss';

export function ActionLoginIcon() {
  return (
    <LinkSignin
      className={classNames(buttons.btn, buttons.btnLink, buttons.btnM, buttons.btnIcon)}
      data-event-name={'site_menu_login_click'}
      data-event-subcategory={'login'}
    >
      <IconLogIn />
    </LinkSignin>
  );
}
