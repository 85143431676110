'use client';

import { PropsWithChildren } from 'react';

import { default as CircularProgress } from '@mui/material/CircularProgress';
import classNames from 'classnames';
import { useLocale } from 'next-intl';

import { WithClassName } from '@/blocks/components/types';
import { useDownloadAPKModal } from '@/providers/WithAPKModal';
import { useAnalytics } from '@/providers/analytics/WithAnalytics';
import { downloadAppEventProps } from '@/providers/analytics/types';

export function OpenAPKModalButton({
  className,
  children,
  location,
}: PropsWithChildren<WithClassName> & {
  location: string;
}) {
  const { openAPKModal, loading } = useDownloadAPKModal();
  const { track } = useAnalytics();
  const locale = useLocale();

  const handleClick = () => {
    openAPKModal();

    track({
      event_name: downloadAppEventProps.event_name,
      event_category: downloadAppEventProps.event_category,
      event_subcategory: downloadAppEventProps.event_subcategory,
      event_label: downloadAppEventProps.label_apk,
      event_action: downloadAppEventProps.event_action,
      location: location,
      language: locale,
    });
  };

  return (
    <button className={classNames(className)} onClick={handleClick}>
      {loading ? <CircularProgress size={24} sx={{ color: 'var(--palette-gray-400)' }} /> : children}
    </button>
  );
}
