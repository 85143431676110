import { useEffect, useState } from 'react';

import { useGtmLoadedContext } from '@/providers/GtmLoadedProvider';
import { getCookie } from '@/utils/cookies';

type ParamsMap = Record<string, string>;

const parseLastUtmSourceCookie = (cookieValue: string) => {
  const ignoredValues = ['undefined', '(not set)'];
  const divider = '|';
  return cookieValue.split(divider).reduce<ParamsMap>((acc, entry) => {
    const [key, value] = entry.split('=');
    if (value && !ignoredValues.includes(String(value))) {
      acc[key] = value;
    }
    return acc;
  }, {});
};

function mapKeyNames(obj: ParamsMap, paramsNamesMap: ParamsMap) {
  return Object.entries(obj).reduce<ParamsMap>((acc, [key, value]) => {
    const newParamName = paramsNamesMap[key];
    if (newParamName) {
      acc[newParamName] = value;
    }
    return acc;
  }, {});
}

export function useLastUtmSource(paramsNamesMap: ParamsMap) {
  const { isUtmCookieSet } = useGtmLoadedContext();
  const [lastUtmSourceParams, setLastUtmSourceParams] = useState<undefined | ParamsMap>();

  useEffect(() => {
    if (!lastUtmSourceParams && isUtmCookieSet) {
      const interval = setInterval(() => {
        const cookie = getCookie('last_utm_source');
        if (cookie) {
          const cookieParams = parseLastUtmSourceCookie(cookie);
          const mappedParams = mapKeyNames(cookieParams, paramsNamesMap);
          setLastUtmSourceParams(mappedParams);

          clearInterval(interval);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [paramsNamesMap, lastUtmSourceParams, isUtmCookieSet]);

  return { lastUtmSourceParams };
}
