import { WithClassName } from '@/blocks/components/types';
import classNames from 'classnames';
import styles from './styles.module.scss';

export function WithTrendColor(
  props: React.PropsWithChildren<{ negative?: boolean; valueToCalculateTrend?: number } & WithClassName>,
) {
  if (typeof props.negative !== 'boolean' && typeof props.valueToCalculateTrend !== 'number') {
    return null;
  }

  const isNegative =
    props.negative || (typeof props.valueToCalculateTrend === 'number' && props.valueToCalculateTrend < 0);

  return (
    <div className={classNames(isNegative ? styles.negative : styles.positive, props.className)}>
      {props.children}
    </div>
  );
}
