const formatters: Record<string, Intl.NumberFormat> = {};

export function getMoneyFormatter(
  locale: string,
  currency: string,
  scale: number,
  fraction?: { min?: number; max?: number; preserveInsignificantZero?: boolean },
  displaySymbol?: boolean,
  compactNotation?: boolean,
  signDisplay?: Intl.NumberFormatOptions['signDisplay'],
): Intl.NumberFormat {
  const cacheKey = `${currency}_${scale}_${locale}_${JSON.stringify(
    fraction,
  )}_${displaySymbol}_${compactNotation}`;

  if (!formatters[cacheKey]) {
    const maximumFractionDigits = fraction && typeof fraction.max === 'number' ? fraction.max : scale;
    const minimumFractionDigits =
      fraction && typeof fraction.min === 'number'
        ? fraction.min
        : fraction?.preserveInsignificantZero
          ? maximumFractionDigits
          : scale;
    formatters[cacheKey] = new Intl.NumberFormat(locale, {
      style: displaySymbol ? 'currency' : undefined,
      currency: displaySymbol ? currency : undefined,
      currencyDisplay: displaySymbol ? 'symbol' : undefined,
      notation: compactNotation ? 'compact' : undefined,
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: maximumFractionDigits,
      signDisplay,
    });
  }

  return formatters[cacheKey];
}
