'use client';

import { PropsWithChildren, ReactNode, useContext } from 'react';

import classNames from 'classnames';

import { WithClassName } from '@/blocks/components/types';
import { ActionGift } from '@/blocks/sections/Header/Components/Actions/ActionGift';
import { BurgerContext } from '@/blocks/sections/Header/Components/HeaderInner';
import { useHeaderScrollStates } from '@/blocks/sections/Header/hooks/useHeaderScrollStates';
import { siteVersion } from '@/global/public-settings';

import { ActionBurger } from '../ActionBurger';
import { ActionLang } from '../ActionLang';
import { ActionLoginIcon } from '../ActionLoginIcon';
import { ActionLoginLink } from '../ActionLoginLink';
import { ActionSignupButton, ActionSignupPromoButton } from '../ActionSignupButton';
import { ActionSupport } from '../ActionSupport';
import styles from './styles.module.scss';

export function ActionsList(props: PropsWithChildren<WithClassName>) {
  return <div className={classNames(styles.actionsContainer, props.className)}>{props.children}</div>;
}

function ActionsMobmenu({ slot }: { slot: ReactNode }) {
  const { menuOpened, setMenuOpened } = useContext(BurgerContext);
  return (
    <ActionsList className={styles.actionsMobmenu}>
      {slot}
      <ActionLang />
      <ActionLoginIcon />
      <ActionBurger onClick={() => setMenuOpened(!menuOpened)} opened={menuOpened} />
    </ActionsList>
  );
}

function ActionsDesktop({ slot }: { slot: ReactNode }) {
  return (
    <ActionsList className={styles.actionsDesktop}>
      <ActionSupport />
      <ActionLang />
      <ActionLoginLink />
      {slot}
    </ActionsList>
  );
}

function ActionsMobile({ slot }: { slot: ReactNode }) {
  const { menuOpened, setMenuOpened } = useContext(BurgerContext);
  return (
    <ActionsList className={styles.actionsMobile}>
      {slot}
      <ActionBurger onClick={() => setMenuOpened(!menuOpened)} opened={menuOpened} />
    </ActionsList>
  );
}

function ActionsSecondLevelMobmenu() {
  const { menuOpened, setMenuOpened } = useContext(BurgerContext);
  return (
    <ActionsList className={styles.actionsSecondLevelMobmenu}>
      <ActionBurger onClick={() => setMenuOpened(!menuOpened)} opened={menuOpened} />
    </ActionsList>
  );
}

function ActionsDesktopPageScrolledDown({ slot }: { slot: ReactNode }) {
  return (
    <ActionsList className={styles.pageScrolledDownActions}>
      <ActionSupport />
      <ActionLoginLink />
      {slot}
    </ActionsList>
  );
}

function HeaderActionsLayout({ children }: PropsWithChildren) {
  const { menuOpened, secondMenuLevel, productMenuOpened } = useContext(BurgerContext);
  const { pageScrolledDown } = useHeaderScrollStates();

  return (
    <div
      className={classNames(styles.headerActions, {
        [styles.mobmenuVisible]: menuOpened,
        [styles.secondLevelMobmenu]: secondMenuLevel || productMenuOpened,
        [styles.pageScrolledDown]: pageScrolledDown,
      })}
    >
      {children}
    </div>
  );
}

export function HeaderCommonActions() {
  return (
    <HeaderActionsLayout>
      <ActionsMobmenu slot={<ActionGift />} />
      <ActionsDesktop slot={<ActionSignupPromoButton />} />
      <ActionsMobile slot={<ActionSignupPromoButton />} />
      <ActionsSecondLevelMobmenu />
      <ActionsDesktopPageScrolledDown slot={<ActionSignupPromoButton />} />
    </HeaderActionsLayout>
  );
}

export function HeaderTurkishActions() {
  const { menuOpened, setMenuOpened } = useContext(BurgerContext);
  return (
    <HeaderActionsLayout>
      <ActionsList className={styles.actionsMobmenu}>
        <ActionLang />
        <ActionLoginIcon />
        <ActionBurger onClick={() => setMenuOpened(!menuOpened)} opened={menuOpened} />
      </ActionsList>
      <ActionsList className={styles.actionsDesktop}>
        <ActionLang />
        <ActionLoginLink />
        <ActionSignupButton />
      </ActionsList>
      <ActionsMobile slot={<ActionSignupButton />} />
      <ActionsSecondLevelMobmenu />
      <ActionsList className={styles.pageScrolledDownActions}>
        <ActionLoginLink />
        <ActionSignupButton />
      </ActionsList>
    </HeaderActionsLayout>
  );
}

export function HeaderActionsWithoutPromotions() {
  return (
    <HeaderActionsLayout>
      <ActionsMobmenu slot={null} />
      <ActionsDesktop slot={<ActionSignupButton />} />
      <ActionsMobile slot={<ActionSignupButton />} />
      <ActionsSecondLevelMobmenu />
      <ActionsDesktopPageScrolledDown slot={<ActionSignupButton />} />
    </HeaderActionsLayout>
  );
}
