import { createSharedPathnamesNavigation } from 'next-intl/navigation';
import { defineRouting } from 'next-intl/routing';
import { i18n } from './i18n-config';

export const routing = defineRouting({
  ...i18n,
  localePrefix: 'as-needed',
});

const { Link, redirect, usePathname, useRouter } = createSharedPathnamesNavigation(routing);

export { Link as LinkI18n, redirect, usePathname, useRouter };
