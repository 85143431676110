import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';

import { useIntercomWrap } from '@/blocks/components/Intercom';
import { i18n } from '@/i18n/i18n-config';
import { LinkI18n, usePathname } from '@/i18n/routing';
import typography from '@/styles/typography.module.scss';

import IconCheck from './check.svg';
import styles from './styles.module.scss';

function getLangFullName(langCode: string) {
  return {
    en: 'English',
    es: 'Español',
    pt: 'Português (Brasil)',
    hi: 'हिंदी',
    in: 'Bahasa Indonesia',
    tr: 'Türkçe',
    vi: 'Tiếng Việt',
    cn: '中文',
    jp: '日本語',
    kr: '한국어',
    bn: 'বাংলা',
    th: 'ไทย',
    ar: 'العربية',
    fr: 'Français',
    de: 'Deutsch',
    it: 'Italiano',
    ru: 'Русский',
  }[langCode];
}

export function LangPopupItem({ lang, selected = false }: { lang: string; selected?: boolean }) {
  const currentPathname = usePathname();
  const urlQuery = useSearchParams();
  const { hardShutdown } = useIntercomWrap();

  const updateCookieLang = (lang: string) => {
    document.cookie = `prx-lang=${lang}; path=/; max-age=31536000; SameSite=Lax`;
  };

  const Component = lang === i18n.defaultLocale ? Link : LinkI18n;

  return (
    <Component
      scroll={false}
      onClick={() => {
        hardShutdown();
        updateCookieLang(lang);
      }}
      href={currentPathname + (urlQuery.size ? '?' + urlQuery.toString() : '')}
      className={classNames(styles.langPopupItem, selected && styles.langPopupSelectedLangItem)}
      {...(lang === i18n.defaultLocale ? { hrefLang: lang } : { locale: lang })}
      data-event-name={'site_menu_lang_click'}
      data-event-subcategory={'lang'}
      data-event-label={lang}
    >
      <div className={styles.langPopupItemCheckContainer}>
        {!selected ? null : <Image src={IconCheck} alt={''} width={16} height={16} />}
      </div>
      <span className={typography.bodyM}>{getLangFullName(lang)}</span>
    </Component>
  );
}
