import classNames from 'classnames';
import Link from 'next/link';

import { INavItem } from '@/blocks/sections/Header/data/types';
import { LinkI18n } from '@/i18n/routing';
import typography from '@/styles/typography.module.scss';

import styles from './styles.module.scss';

export function DropdownLinkWidget(props: { navItem: INavItem }) {
  const Component = props.navItem.href ? (props.navItem.noLangPrefix ? Link : LinkI18n) : 'div';

  return (
    <Component
      // @ts-ignore
      href={props.navItem.href}
      className={classNames(typography.bodyM, styles.usualNavItem)}
      {...props.navItem.linkProps}
    >
      {props.navItem.icon ? <div className={styles.iconSlot}>{props.navItem.icon}</div> : null}
      {props.navItem.title}
    </Component>
  );
}
