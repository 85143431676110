export const IconMetatrader5 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={41} height={39} viewBox="0 0 41 39" fill="none">
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.6}
      d="M36.37 34.42c-.99 0-1.98-.24-2.96-.86a6.85 6.85 0 0 1-2.6-2.71c-.49-1.11-.49-2.35.13-3.33.62-1.11 1.85-1.73 3.45-1.73 1 0 1.98.25 2.96.86 2.47 1.36 3.46 4.07 2.47 6.05a4 4 0 0 1-3.45 1.72Zm-1.98-7.52c-1.1 0-2.1.5-2.59 1.23a3.06 3.06 0 0 0-.12 2.35 4.9 4.9 0 0 0 1.97 2.22c.74.49 1.6.74 2.47.74 1.11 0 2.1-.5 2.6-1.24.85-1.48-.13-3.45-1.98-4.56a3.8 3.8 0 0 0-2.35-.74Z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.6}
      d="m38.83 28-.98-.24c.37-1.85.61-4.56-.74-7.03-1.11-1.85-2.96-3.45-5.43-4.68l.5-1c2.83 1.24 4.8 2.97 5.91 5.07a12.1 12.1 0 0 1 .74 7.89ZM25.14 13.47c.47.34.89.76 1.24 1.23h.24c.62.12 1.24.25 1.85.5l.37-1a12.9 12.9 0 0 0-2.1-.49h-.24c-.25-.12-.5-.12-.74-.12-.37-.12-.62-.12-.86-.12.12-.13.12-.13.24 0Zm8.02 8.88c-.12 0-.12 0-.25-.13-.74-.37-1.72-.61-2.46-.86l.37-.99 1.1.37c-.36-.37-.61-.74-1.1-.98l.49-1c1.48.75 2.34 2.72 2.34 2.72.13.25 0 .5-.12.62-.12.25-.25.25-.37.25Zm-5.67 9.86c-.87-.5-1.85-.98-2.6-1.6l-.61.86c.37.37.86.62 1.36.87-.74-.13-1.36-.5-2.22-1-.37-.24-.74-.48-.99-.73-.12 0-.25 0-.25.12-.37.13-.74.13-1.23.13.62.49 1.23 1.1 1.85 1.48.99.61 2.47 1.35 4.07 1.1h.12l.5-.12c.24-.12.37-.24.37-.5-.13-.24-.25-.48-.37-.6Zm7.03 1.48a8.98 8.98 0 0 1-5.55 3.09c-2.1.37-4.57-.37-6.66-1.73a18.72 18.72 0 0 1-4.69-4.69 3.7 3.7 0 0 1-1.23-.5c-.25-.11-.37-.11-.5-.24l.13.13c.12.24.24.37.49.61a22.26 22.26 0 0 0 5.3 5.43 10.75 10.75 0 0 0 6.17 1.97c.5 0 .86 0 1.23-.12a9.72 9.72 0 0 0 6.17-3.45l-.86-.5Zm-29.48-.24c-1.6 0-3.08-.74-3.7-1.98-.99-1.97.25-4.68 2.84-5.8.86-.49 1.72-.61 2.71-.61 1.6 0 3.08.74 3.7 1.97.5.99.5 2.1-.12 3.2a5.56 5.56 0 0 1-2.72 2.6c-.98.37-1.85.62-2.71.62Zm1.73-7.53c-.74 0-1.48.25-2.22.5-1.98.98-3.09 2.96-2.35 4.44.5.86 1.48 1.48 2.72 1.48.74 0 1.48-.25 2.22-.5a5.23 5.23 0 0 0 2.22-2.1c.37-.86.49-1.6.12-2.34-.37-.86-1.48-1.48-2.71-1.48Z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.6}
      d="M13.55 37.01h-.37a10.77 10.77 0 0 1-6.9-3.08l-.62-.62.74-.74c.24.25.37.37.5.62 1.72 1.6 3.94 2.71 6.16 2.71 1.97.13 4.31-.61 6.66-2.1l.61.87c-2.34 1.48-4.68 2.34-6.78 2.34Zm12.58-7.64c0-.25-.12-.25-.25-.37l-.12-.13h-.12c-.13.13-.13.13-.25.13l-.99.37c.13.12.13.12.25.12l-1.97 2.1.74.74c.86-.74 1.6-1.6 2.34-2.47l.12-.12.13-.13c.12 0 .12-.12.12-.24Zm-10.98 3.2c-.24 0-.61 0-.86-.12a.96.96 0 0 1-.5-.5c-.12-.24.13-.49.25-.61l2.1-1.6.74.74c-.25.24-.5.37-.74.61.5-.12.99-.37 1.48-.49l.37.99a4.4 4.4 0 0 1-2.84.98ZM9.48 20c.37-.49.86-.86 1.36-1.23.37-.25.61-.37.98-.5l.74-1.35-2.22.86a5.47 5.47 0 0 0-2.46 3.09v.12c0 .25.24.5.5.5h.11c.13 0 .25 0 .37-.13l2.47-1.11-.62-.74c-.37 0-.86.25-1.23.5Zm5.3-5.8c-.12.13-.12.13-.24.13.37-.37.86-.74 1.23-.99-.37 0-.62.13-.99.13-2.7.24-4.93.74-6.78 1.6-1.73.86-3.95 2.34-5.18 4.93a9.89 9.89 0 0 0-.5 6.79l1-.37a8.5 8.5 0 0 1 .36-6.05c1-2.34 3.09-3.7 4.7-4.44 1.6-.74 3.44-1.23 5.79-1.48.24 0 .5-.12.61-.24Zm11.97 14.19c-.25.12-.37.25-.5.37.13.12.13.12.25.12.25 0 .37-.12.5-.24l.36-.5c-.37 0-.49.13-.61.25ZM21.2 11.11c-1.11 0-2.1-.61-2.96-1.6a5.88 5.88 0 0 1-1.11-3.58c0-1.35.5-2.59 1.23-3.45A3.67 3.67 0 0 1 21.32 1c2.22.12 3.95 2.34 3.95 5.18 0 1.36-.5 2.59-1.24 3.45a3.82 3.82 0 0 1-2.83 1.48Zm0-9.25c-1.6 0-2.96 1.73-2.96 3.95 0 1.11.24 2.1.86 2.96.62.74 1.23 1.11 1.97 1.11h.13c1.6 0 2.96-1.73 2.96-3.95 0-2.1-1.24-3.94-2.96-4.07Z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.6}
      d="M9.73 14.69c-.13-1.97.37-4.93 2.34-7.28.37-.37.74-.74 1.23-1.1.13-.13.13-.13.25-.13a8.22 8.22 0 0 1 3.7-1.97c.25-.13.37-.13.62-.25l.37.99c-.25.12-.37.12-.62.24-.86.25-1.6.62-2.34.99-.37.25-.62.5-1 .74-.11.12-.11.12-.24.12-.5.37-.74.74-1.1 1.11-1.73 2.1-2.1 4.81-2.1 6.54H9.73Zm1.6 7.15a1.71 1.71 0 0 1 0-1.1c.12-.5.12-.87.25-1.24a3.81 3.81 0 0 1-.25-.99l-.99.25a21.1 21.1 0 0 0 1.11 3.7c-.12-.37-.12-.5-.12-.62Zm3.33-9c.12.37.12.62.12.86l.74-.12c.13-.12.25-.12.37-.25-.12-.61-.24-1.85-.24-1.85 0-.12-.13-.37-.25-.37s-.37-.12-.5 0c-.73.37-1.35 1.85-1.47 2.6l.98.24c.01-.38.1-.76.25-1.11Zm13.07 0c.25.62.37 1.48.37 2.59l.37.37c.25.37.37.74.5.99.49-2.96.12-4.94-1.36-5.68-.25-.12-.37-.12-.5 0-.24.25-.36.37-.36.5 0 .74-.13 1.35-.25 1.97l.99.25c.24-.13.24-.5.24-.99Zm3.46-3.58a8.72 8.72 0 0 0-3.83-4.19c-.74-.37-1.6-.86-2.59-.99l-.24.99c.86.12 1.6.5 2.34.86 0 0 2.1 1.11 3.33 3.7 1.48 3.21 1.23 7.28-.74 11.97v.12c.12.86.12 1.73-.12 2.59l.24-.25c1.6-3.33 2.47-6.29 2.6-9 .12-2.1-.13-4.2-1-5.8Z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.6}
      d="M20.58 30.97a9.22 9.22 0 0 1-6.78-2.84 9.42 9.42 0 0 1-2.84-6.78c0-2.59.99-5.06 2.84-6.78a9.42 9.42 0 0 1 6.78-2.84c2.59 0 5.06.99 6.78 2.84a9.42 9.42 0 0 1 2.84 6.78c0 2.59-.99 5.06-2.84 6.78a9.22 9.22 0 0 1-6.78 2.84Zm0-18.5a8.71 8.71 0 0 0-8.76 8.76 8.8 8.8 0 0 0 8.76 8.75 8.71 8.71 0 0 0 8.76-8.75 8.71 8.71 0 0 0-8.76-8.76Zm2.59 8.02c.62.61.99 1.35.99 2.46 0 .74-.13 1.24-.5 1.73-.24.5-.74.86-1.23 1.23a5.8 5.8 0 0 1-2.1.5 6.85 6.85 0 0 1-2.83-.62l.49-1.11a5 5 0 0 0 2.34.5c.5 0 .99-.13 1.24-.25.37-.25.61-.5.86-.74.25-.37.25-.62.25-.99 0-.5-.13-.86-.25-1.23-.25-.37-.5-.62-.86-.74-.37-.25-.87-.25-1.6-.25-.51 0-1.01.08-1.49.25l-.5-.5v-4.8h5.8l-.24 1.23h-4.2v2.83c.38-.12.75-.12 1.12-.12 1.23-.25 2.1.12 2.7.62Z"
    />
  </svg>
);
