export const LearnIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 11V16.6667C6 18.5076 8.83691 20 12 20C15.1631 20 18 18.5076 18 16.6667V11M6 11L12 14.25L18 11M6 11L3 9.25L12 4.25L21 9.25M18 11L21 9.25M21 9.25V16.25"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
