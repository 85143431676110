export const SpiralIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_480_47543)">
      <path
        d="M12 12C12.1989 12 12.3897 12.079 12.5303 12.2197C12.671 12.3603 12.75 12.5511 12.75 12.75C12.75 13.1478 12.592 13.5294 12.3107 13.8107C12.0294 14.092 11.6478 14.25 11.25 14.25C10.6533 14.25 10.081 14.0129 9.65901 13.591C9.23705 13.169 9 12.5967 9 12C9 11.2044 9.31607 10.4413 9.87868 9.87868C10.4413 9.31607 11.2044 9 12 9C12.9946 9 13.9484 9.39509 14.6517 10.0983C15.3549 10.8016 15.75 11.7554 15.75 12.75C15.75 13.9435 15.2759 15.0881 14.432 15.932C13.5881 16.7759 12.4435 17.25 11.25 17.25C9.85761 17.25 8.52226 16.6969 7.53769 15.7123C6.55312 14.7277 6 13.3924 6 12C6 10.4087 6.63214 8.88258 7.75736 7.75736C8.88258 6.63214 10.4087 6 12 6C13.7902 6 15.5071 6.71116 16.773 7.97703C18.0388 9.2429 18.75 10.9598 18.75 12.75C18.75 13.7349 18.556 14.7102 18.1791 15.6201C17.8022 16.5301 17.2497 17.3569 16.5533 18.0533C15.8569 18.7497 15.0301 19.3022 14.1201 19.6791C13.2102 20.056 12.2349 20.25 11.25 20.25C9.06196 20.25 6.96354 19.3808 5.41637 17.8336C3.86919 16.2865 3 14.188 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C13.2804 3 14.5482 3.25219 15.7312 3.74217C16.9141 4.23216 17.9889 4.95034 18.8943 5.85571C19.7997 6.76108 20.5178 7.83591 21.0078 9.01884C21.4978 10.2018 21.75 11.4696 21.75 12.75"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_480_47543">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
