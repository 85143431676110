import { isRtl } from '@/hooks/useIsRtl/isRtl';

export function getDropdownOverflowShift(el: HTMLElement, locale: string) {
  const OFFSET = parseInt(window.getComputedStyle(el).getPropertyValue('--padding-container')) || 20;
  const overflowRight = el.getBoundingClientRect().right - (window.innerWidth - OFFSET);
  const overflowLeft = el.getBoundingClientRect().left - OFFSET;
  const localeMultiplier = isRtl(locale).isRtl ? 1 : -1;
  const viewportOverflow = {
    right: overflowRight > 0 ? overflowRight * localeMultiplier : 0,
    left: overflowLeft < 0 ? overflowLeft * localeMultiplier : 0,
  };

  return viewportOverflow.right && viewportOverflow.left
    ? viewportOverflow.right
    : viewportOverflow.right || viewportOverflow.left;
}
