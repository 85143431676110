export const WindowsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3887_21949)">
      <path
        d="M20.2485 19.8736L12.7451 18.5099V13.1206H20.2485V19.8736Z"
        stroke="#3760FF"
        strokeWidth="1.50067"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.7439 17.964L3.74121 16.8723V13.1206H9.7439V17.964Z"
        stroke="#3760FF"
        strokeWidth="1.50067"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.2485 3.36621L12.7451 4.73088V10.1192H20.2485V3.36621Z"
        stroke="#3760FF"
        strokeWidth="1.50067"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.7439 5.27588L3.74121 6.36762V10.1193H9.7439V5.27588Z"
        stroke="#3760FF"
        strokeWidth="1.50067"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3887_21949">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
