import React from 'react';

import { RecapAsset } from '@/api/trading/types';
import { MarketsTable } from '@/blocks/sections/Markets';
import { renderCell } from '@/blocks/sections/Markets/useAssetsPresets/renderCell';
import { getCryptoFuturesAssetsNames } from '@/data';

import { ColumnHeaders } from '@/app/[lang]/index/IndexMarketsSection/Tables/types';

import styles from './styles.module.scss';

export function CryptoFuturesTable({
  markets,
  columnHeaders,
}: {
  markets: RecapAsset[];
  columnHeaders: ColumnHeaders;
}) {
  return (
    <MarketsTable
      assets={markets}
      className={styles.table}
      assetsToDisplay={getCryptoFuturesAssetsNames()}
      columns={[
        {
          title: columnHeaders.assets,
          renderCell: renderCell.asset,
          name: 'asset',
        },
        {
          title: columnHeaders.priceAndChange,
          renderCell: renderCell.priceAndChange,
          name: 'price-and-change',
        },
        {
          title: columnHeaders.price,
          renderCell: renderCell.price,
          name: 'price',
        },
        {
          title: columnHeaders.high24,
          renderCell: renderCell.high24,
          name: 'high24',
        },
        {
          title: columnHeaders.low24,
          renderCell: renderCell.low24,
          name: 'low24',
        },
        {
          title: columnHeaders.volume24,
          renderCell: renderCell.vol24,
          name: 'vol24',
        },
        {
          title: columnHeaders.trend,
          renderCell: renderCell.trend,
          name: 'trend',
        },
        {
          title: '',
          renderCell: renderCell.actions(columnHeaders.cta, columnHeaders.enCta),
          name: 'actions',
        },
      ]}
      columnsVisibilityModel={{
        desktop: ['asset', 'price', 'high24', 'low24', 'vol24', 'trend', 'actions'],
        tablet: ['asset', 'price', 'high24', 'low24', 'vol24'],
        mobile: ['asset', 'price-and-change'],
      }}
    ></MarketsTable>
  );
}
