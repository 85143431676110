'use client';

import { SVGAttributes } from 'react';

import { LogoChristmas } from '@/blocks/components/Logo/LogoChristmas';
import { LogoDefault } from '@/blocks/components/Logo/LogoDefault';
import { LogoShortChristmas } from '@/blocks/components/Logo/LogoShortChristmas';
import { LogoShortDefault } from '@/blocks/components/Logo/LogoShortDefault';
import { useIsClient } from '@/hooks/useIsClient/useIsClient';

export type LogoProps = SVGAttributes<SVGElement>;

export function Logo(props: LogoProps) {
  const { isClient } = useIsClient();
  return isClient && window?.inlineConfig?.ny2025 ? <LogoChristmas {...props} /> : <LogoDefault {...props} />;
}

export function LogoShort(props: LogoProps) {
  const { isClient } = useIsClient();
  return isClient && window?.inlineConfig?.ny2025 ? (
    <LogoShortChristmas {...props} />
  ) : (
    <LogoShortDefault {...props} />
  );
}
