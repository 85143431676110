import { HelpCenterArticle, helpCenterArticles } from '@/blocks/components/PlatformLink/HelpCenter/articles';
import { convertLangCode } from '@/utils/string/lang';
import classNames from 'classnames';
import { useLocale } from 'next-intl';
import Link from 'next/link';
import React, { HTMLAttributes } from 'react';

export function useHelpCenterLink(params?: { article?: HelpCenterArticle }) {
  const lang = useLocale();
  return new URL(
    helpCenterArticles[params?.article!] || '',
    `https://primexbt.help/${convertLangCode(lang)}/`,
  ).toString();
}

export function HelpCenterLink({
  className,
  article,
  children,
  ...otherProps
}: HTMLAttributes<HTMLAnchorElement> & Parameters<typeof useHelpCenterLink>[0]) {
  const link = useHelpCenterLink({ article });
  return (
    <Link href={link} target={'_blank'} className={classNames(className)} {...otherProps}>
      {children}
    </Link>
  );
}
