import classNames from 'classnames';
import Image from 'next/image';

import { LinkSignup, platformRewardsWelcomeBonusURL } from '@/blocks/components/PlatformLink';

import iconGift from '@/app/images/icons/gift.png';

import styles from './styles.module.scss';

export function ActionGift() {
  return (
    <LinkSignup
      className={classNames(styles.actionGift)}
      data-event-name={'site_menu_register_click'}
      data-event-subcategory={'register'}
      params={{ 'init-product': null, redirect: platformRewardsWelcomeBonusURL }}
    >
      <Image src={iconGift} alt={''} />
    </LinkSignup>
  );
}
