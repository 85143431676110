import { ComponentPropsWithRef, createElement, forwardRef } from 'react';

import classNames from 'classnames';

import styles from './Section.module.scss';

type SectionProps = ComponentPropsWithRef<'div'> & {
  tag?: keyof JSX.IntrinsicElements;
  marginType?: 'padding' | 'margin' | false;
};

export const Section = forwardRef<HTMLDivElement, SectionProps>(
  ({ children, className, tag = 'section', marginType = 'padding', ...rest }, ref) => {
    return createElement(
      tag,
      {
        className: classNames(
          {
            [styles.section]: marginType === 'padding',
            [styles.sectionMargin]: marginType === 'margin',
          },
          className,
        ),
        ref: ref,
        ...rest,
      },
      children,
    );
  },
);

Section.displayName = 'Section';
