import { ReactNode } from 'react';

import classNames from 'classnames';
import { motion } from 'framer-motion';
import Image from 'next/image';

import { useTabContentVariants } from '@/blocks/animations/useTabContentVariants';
import styles from '@/blocks/sections/UspTabs/styles.module.scss';
import typography from '@/styles/typography.module.scss';

export type UspItemProps = {
  title: ReactNode;
  description: ReactNode;
};

type UspListProps = {
  items: UspItemProps[];
};

export type UspContentProps = {
  list: UspItemProps[];
  buttons: ReactNode;
  image: any;
  cta?: ReactNode;
  disclaimer?: ReactNode;
};

export const UspItem = ({ title, description }: UspItemProps) => {
  return (
    <div className={styles.uspItem}>
      <dt className={classNames(typography.h5, styles.title)}>{title}</dt>
      <dd className={classNames(typography.bodyM, styles.subtitle)}>{description}</dd>
    </div>
  );
};

export const UspList = ({ items }: UspListProps) => {
  return (
    <dl>
      {items?.map((usp, i) => {
        return <UspItem key={i} {...usp} />;
      })}
    </dl>
  );
};

export const UspContent = ({
  list,
  buttons,
  image,
  cta,
  disclaimer,
  isActive,
}: UspContentProps & {
  isActive: boolean;
}) => {
  const { variants, transition } = useTabContentVariants();

  return (
    <motion.div
      className={styles.contentWrapper}
      initial={'inactive'}
      animate={isActive ? 'active' : 'inactive'}
      variants={variants}
      transition={transition}
    >
      <div className={styles.info}>
        <UspList items={list} />
        <div className={styles.wrapperButtons}>
          {cta && <div className={styles.btnWrapper}>{cta}</div>}
          <div className={styles.uspButtons}>{buttons}</div>
        </div>
        {disclaimer && <div className={styles.disclaimer}>{disclaimer}</div>}
      </div>
      <div className={styles.uspImage}>
        <Image src={image} alt={''} width={750} height={450} sizes={'100vw'} loading={'eager'} />
      </div>
    </motion.div>
  );
};
