'use client';
import { extractXDCookie } from '@/global/xd-storage/extract-xd-cookie';
import { getCookie } from '@/utils/cookies';
import React, { useEffect } from 'react';

export function InitXDClient() {
  useEffect(() => {
    const uidCookie = extractXDCookie(getCookie('prm-uid'));
    if (uidCookie) {
      window.dataLayer = window.dataLayer ?? [];
      window.dataLayer.push({ UID: uidCookie.val });
    }
  }, []);
  return <></>;
}
