import { ReactNode } from 'react';

import classNames from 'classnames';

import { LinkSignup } from '@/blocks/components/PlatformLink';
import buttons from '@/styles/button.module.scss';
import context from '@/styles/contexts.module.scss';
import typography from '@/styles/typography.module.scss';

import styles from './styles.module.scss';

export const PromoCard = (props: {
  title: ReactNode;
  description: ReactNode;
  cta: ReactNode;
  enCta: ReactNode;
  reward: ReactNode;
  footnote: ReactNode;
  params?: { [key: string]: any };
}) => {
  return (
    <div className={classNames(context.light, styles.promoCard)}>
      <div className={classNames(styles.promoCardContent)}>
        <h4 className={classNames(typography.h4, styles.promoCardTitle)}>{props.title}</h4>
        <p className={classNames(typography.bodyM, styles.promoCardSubtitle)}>{props.description}</p>
        <div className={classNames(styles.promoCardCtaContainer)}>
          <LinkSignup
            className={classNames(buttons.btn, buttons.btnM, buttons.btnPrimary)}
            params={props.params}
            data-event-label={props.enCta}
          >
            {props.cta}
          </LinkSignup>
          <p className={classNames(typography.bodyM, styles.promoCardFootnote)}>{props.footnote}</p>
        </div>
      </div>
      <div className={classNames(styles.promoCardBonus, typography.h2)}>{props.reward}</div>
    </div>
  );
};
