import { ComponentPropsWithoutRef } from 'react';

import classNames from 'classnames';

import { AssetIcon } from '@/blocks/components/AssetIcon';
import { FormatAssetName } from '@/blocks/formatters';
import { extractAssetDescription, extractAssetName } from '@/blocks/formatters/format-asset-name';
import typography from '@/styles/typography.module.scss';

import styles from './styles.module.scss';

type CellAssetProps = ComponentPropsWithoutRef<'td'> & {
  asset: { name: string; description: string; category: string };
};

const CellAsset = ({ className, asset, ...rest }: CellAssetProps) => {
  return (
    <td className={classNames(className, styles.td)} {...rest}>
      <div className={classNames(styles.cell, styles.cellAsset)}>
        <AssetIcon assetName={asset.name} />
        <div>
          <span className={styles.assetFullName}>
            <FormatAssetName assetName={extractAssetName(asset)} />
          </span>
          <span className={classNames(typography.bodyS, styles.assetName)}>
            <FormatAssetName assetName={extractAssetDescription(asset)} />
          </span>
        </div>
      </div>
    </td>
  );
};
export { CellAsset };
