'use client';
import React, { PropsWithChildren } from 'react';
import { AppRouterCacheProvider } from '@mui/material-nextjs/v14-appRouter';
import stylisRTLPlugin from 'stylis-plugin-rtl';

// https://mui.com/material-ui/integrations/nextjs/
// https://mui.com/material-ui/customization/right-to-left/
export default function ThemeRegistry({
  children,
  isRtl,
}: PropsWithChildren & {
  isRtl: boolean;
}) {
  return (
    <AppRouterCacheProvider
      options={{
        prepend: true,
        // enableCssLayer: true,
        stylisPlugins: isRtl ? [stylisRTLPlugin] : [],
        key: isRtl ? 'rtl' : 'ltr',
      }}
    >
      {children}
    </AppRouterCacheProvider>
  );
}
