import { ReactNode, useState } from 'react';

import classNames from 'classnames';
import Link from 'next/link';

import { INavItem } from '@/blocks/sections/Header/data/types';
import { LinkI18n } from '@/i18n/routing';
import typography from '@/styles/typography.module.scss';

import styles from './styles.module.scss';

export function MobmenuTopLevelCollapse({
  initiallyOpened = false,
  ...props
}: {
  title: ReactNode;
  children: React.ReactNode;
  initiallyOpened?: boolean;
}) {
  const [opened, setOpened] = useState<boolean>(initiallyOpened);
  return (
    <div
      className={classNames(styles.topLevelItem, styles.burgerMenuCollapseSection, opened && styles.opened)}
    >
      <div onClick={() => setOpened(!opened)} className={styles.topLevelItemSummary}>
        <div className={typography.subheadingM}>{props.title}</div>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.75 7.5L12 16.5L20.25 7.5"
            stroke="currentcolor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <ul className={styles.list}>{props.children}</ul>
    </div>
  );
}

export function MobmenuTopLevelItem(props: { navItem: INavItem }) {
  const Component = props.navItem.href ? (props.navItem.noLangPrefix ? Link : LinkI18n) : 'div';

  return (
    <div className={classNames(styles.topLevelItem)}>
      <Component
        // @ts-ignore
        href={props.navItem.href}
        className={styles.topLevelItemSummary}
        {...props.navItem.linkProps}
      >
        <div className={typography.subheadingM}>{props.navItem.title}</div>
      </Component>
    </div>
  );
}
