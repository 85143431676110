'use client';

import React, { useState } from 'react';

import classNames from 'classnames';
import Image from 'next/image';

import qrIcon from '@/blocks/sections/Footer/assets/qr.svg';
import { QrPopup } from '@/blocks/sections/Footer/components/FooterLeftActionGroup/FooterMobileApps/FooterMobileAppsBlockQr/QrPopup';
import buttons from '@/styles/button.module.scss';

import styles from './styles.module.scss';

export function FooterMobileAppsBlockQr() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <button
        className={classNames(buttons.btn, buttons.btnSocialL, buttons.btnSocialOutline, styles.codeWrapper)}
        onClick={handleOpen}
      >
        <Image src={qrIcon} alt={'media icon'} width={24} height={24} />
      </button>
      <QrPopup isOpen={open} onClose={handleClose} />
    </>
  );
}
