import { defaultLang, supportedLangs } from '@/global/public-settings';

export type Locale = string;

const allLocales = [
  'en',
  'es',
  'pt',
  'hi',
  'in',
  'tr',
  'vi',
  'cn',
  'jp',
  'kr',
  'bn',
  'th',
  'ar',
  'ru',
];

const locales: (Locale)[] = (
  supportedLangs ? JSON.parse(supportedLangs) : allLocales
).filter((locale: Locale) => allLocales.indexOf(locale as any) !== -1);

let defaultLocale = defaultLang

if (defaultLocale && !locales.includes(defaultLocale)) {
  if (locales.length) {
    defaultLocale = locales[0]
    console.warn(`Warning: declared default locale is not in the locales list (${locales.join(', ')}), first locale from list was assigned as default`)
  } else {
    console.warn(`Error: declared default locale is not in the locales list because locales list is empty`)
  }
}

export function isLocaleSupported(locale: string) {
  return locales.includes(locale);
}

export const i18n = {
  defaultLocale,
  locales,
} as const;
