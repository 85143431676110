export const FlagIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 4V15M5 4V2M5 4L6.31951 3.67012C8.0358 3.24105 9.85224 3.51134 11.3692 4.42154V4.42154C13.039 5.4234 15.0632 5.6456 16.9105 5.02982L20 4V15L16.9105 16.0298C15.0632 16.6456 13.039 16.4234 11.3692 15.4215V15.4215C9.85224 14.5113 8.0358 14.2411 6.31951 14.6701L5 15M5 22V15"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
