'use client';

import React, { ReactNode, createContext, useContext, useState } from 'react';

import dynamic from 'next/dynamic';

const DownloadAPKModalContext = createContext<{
  openAPKModal: (...args: any[]) => any;
  loading: boolean;
}>({
  openAPKModal: () => {},
  loading: false,
});

const APKModal = dynamic(() => import('@/blocks/components/APKModal/APKModal'));

export function WithAPKModal(props: { children: ReactNode }) {
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <DownloadAPKModalContext.Provider
      value={{
        openAPKModal: () => {
          setLoading(true);
          setOpened(true);
        },
        loading,
      }}
    >
      {props.children}
      {opened ? (
        <APKModal isOpen={opened} onClose={() => setOpened(false)} onLoad={() => setLoading(false)} />
      ) : null}
    </DownloadAPKModalContext.Provider>
  );
}

export function useDownloadAPKModal() {
  return useContext(DownloadAPKModalContext);
}
