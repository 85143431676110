'use client';

import React from 'react';

import classNames from 'classnames';
import Image from 'next/image';

import { useMobileStoreLink } from '@/blocks/components/PlatformLink/useMobileStoreLink';
import googleIcon from '@/blocks/sections/Footer/assets/google-play-icon.svg';
import { downloadAppEventProps } from '@/providers/analytics/types';
import buttons from '@/styles/button.module.scss';

import appleIcon from '@/blocks/sections/Footer/components/FooterSocialLinks/icons/apple-icon.svg';

export function FooterMobileAppAndroidButton() {
  const { googlePlay } = useMobileStoreLink({ location: 'footer' });

  const link = {
    href: googlePlay,
    imageSrc: googleIcon,
    linkProps: {
      'data-event-label': downloadAppEventProps.label_google,
    },
  };

  return (
    <a
      href={link.href}
      target={'_blank'}
      className={classNames(buttons.btn, buttons.btnSocialL, buttons.btnSocialOutline)}
      data-event-name={downloadAppEventProps.event_name}
      data-event-category={downloadAppEventProps.event_category}
      data-event-subcategory={downloadAppEventProps.event_subcategory}
      data-event-action={downloadAppEventProps.event_action}
      data-event-location={'footer'}
      data-event-include-url
      {...link.linkProps}
    >
      <Image src={link.imageSrc} alt={'media icon'} width={24} height={24} />
    </a>
  );
}

export function FooterMobileAppIOSButton() {
  const { appStore } = useMobileStoreLink({ location: 'footer' });

  const link = {
    href: appStore,
    imageSrc: appleIcon,
    linkProps: {
      'data-event-label': downloadAppEventProps.label_apple,
    },
  };

  return (
    <a
      href={link.href}
      target={'_blank'}
      className={classNames(buttons.btn, buttons.btnSocialL, buttons.btnSocialOutline)}
      data-event-name={downloadAppEventProps.event_name}
      data-event-category={downloadAppEventProps.event_category}
      data-event-subcategory={downloadAppEventProps.event_subcategory}
      data-event-action={downloadAppEventProps.event_action}
      data-event-location={'footer'}
      data-event-include-url
      {...link.linkProps}
    >
      <Image src={link.imageSrc} alt={'media icon'} width={24} height={24} />
    </a>
  );
}
