export const IconCalendar = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.25 5.00024C12.25 4.44796 11.8023 4.00024 11.25 4.00024C10.6977 4.00024 10.25 4.44796 10.25 5.00024V6.00024H6.91667C5.44391 6.00024 4.25 7.09466 4.25 8.44469V13.0002V15.0002V25.5558C4.25 26.9058 5.44391 28.0002 6.91667 28.0002H25.5833C27.0561 28.0002 28.25 26.9058 28.25 25.5558V15.0002V13.0002V8.44469C28.25 7.09466 27.0561 6.00024 25.5833 6.00024H22.25V5.00024C22.25 4.44796 21.8023 4.00024 21.25 4.00024C20.6977 4.00024 20.25 4.44796 20.25 5.00024V6.00024H12.25V5.00024ZM20.25 8.00024H12.25V9.00024C12.25 9.55253 11.8023 10.0002 11.25 10.0002C10.6977 10.0002 10.25 9.55253 10.25 9.00024V8.00024H6.91667C6.67752 8.00024 6.49389 8.0884 6.3825 8.19051C6.274 8.28997 6.25 8.38132 6.25 8.44469V13.0002H26.25V8.44469C26.25 8.38132 26.226 8.28997 26.1175 8.19051C26.0061 8.0884 25.8225 8.00024 25.5833 8.00024H22.25V9.00024C22.25 9.55253 21.8023 10.0002 21.25 10.0002C20.6977 10.0002 20.25 9.55253 20.25 9.00024V8.00024ZM6.25 25.5558V15.0002H26.25V25.5558C26.25 25.6192 26.226 25.7105 26.1175 25.81C26.0061 25.9121 25.8225 26.0002 25.5833 26.0002H6.91667C6.67752 26.0002 6.49389 25.9121 6.3825 25.81C6.274 25.7105 6.25 25.6192 6.25 25.5558Z"
        fill="currentColor"
      />
    </svg>
  );
};
