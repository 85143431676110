export function CheckCircle() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM17.437 9.0361C17.7885 8.68463 17.7885 8.11478 17.437 7.76331C17.0855 7.41184 16.5157 7.41184 16.1642 7.76331L10.8006 13.1269L9.03698 11.3633C8.68551 11.0118 8.11566 11.0118 7.76419 11.3633C7.41272 11.7148 7.41272 12.2846 7.76419 12.6361L10.1642 15.0361C10.333 15.2049 10.5619 15.2997 10.8006 15.2997C11.0393 15.2997 11.2682 15.2049 11.437 15.0361L17.437 9.0361Z"
        fill="#3760FF"
      />
      <path
        d="M8.40039 11.9997L10.8004 14.3997L16.8004 8.39966"
        stroke="white"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
