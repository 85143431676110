export const AmbassadorIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 6C16 8.20914 14.2091 10 12 10C9.79086 10 8 8.20914 8 6C8 3.79086 9.79086 2 12 2C14.2091 2 16 3.79086 16 6ZM18 6C18 9.31371 15.3137 12 12 12C8.68629 12 6 9.31371 6 6C6 2.68629 8.68629 0 12 0C15.3137 0 18 2.68629 18 6ZM2 21C2 18.7909 3.79086 17 6 17H11.5C12.0523 17 12.5 16.5523 12.5 16C12.5 15.4477 12.0523 15 11.5 15H6C2.68629 15 0 17.6863 0 21V23C0 23.5523 0.447715 24 1 24C1.55228 24 2 23.5523 2 23V21Z"
      fill="currentcolor"
    />
    <path
      d="M15 20.25V18.45M15 18.45V15H19.5L18.375 16.65L19.5 18.45H15Z"
      stroke="currentcolor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
