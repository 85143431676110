'use client';
import React, { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';
import { Fiat } from '@/app/[lang]/buy-crypto/components/ConverterWidget/data';

export const DEFAULT_SELECTED_FIAT = 'USD';

type Context = {
  amount: string;
  setAmount(value: string): void;
  isPayAmountChanging: boolean;
  setIsPayAmountChanging(value: boolean): void;
  selectedFiat: Fiat;
  setSelectedFiat(value: Fiat): void;
};

const BuyCryptoWidgetContext = createContext<Context | undefined>(undefined);

type BuyCryptoWidgetProviderProps = PropsWithChildren<{
  initialSelectedFiat?: Fiat;
}>;

export function BuyCryptoWidgetProvider({
  children,
  initialSelectedFiat = DEFAULT_SELECTED_FIAT,
}: BuyCryptoWidgetProviderProps) {
  const [amount, setAmount] = useState('');
  const [isPayAmountChanging, setIsPayAmountChanging] = useState(true);
  const [selectedFiat, setSelectedFiat] = useState<Fiat>(initialSelectedFiat?.toUpperCase() as Fiat);

  const contextValue = useMemo(
    () => ({
      amount,
      isPayAmountChanging,
      selectedFiat,
      setAmount,
      setIsPayAmountChanging,
      setSelectedFiat,
    }),
    [amount, isPayAmountChanging, selectedFiat],
  );

  return <BuyCryptoWidgetContext.Provider value={contextValue}>{children}</BuyCryptoWidgetContext.Provider>;
}

export const useBuyCryptoWidgetContext = () => {
  const context = useContext(BuyCryptoWidgetContext);
  if (!context) {
    throw new Error('useBuyCryptoWidget must be used within a BuyCryptoWidgetProvider');
  }
  return context;
};
