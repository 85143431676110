export const IconWindows = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={24} viewBox="0 0 22 24" fill="none">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.076 2.724 21.997.984v10.463l-11.92.094V2.724Zm-1.085.16L0 4.11l.008 7.499 8.987-.051-.004-8.672Zm.003 18.352-.007-8.68-8.98-.058V20l8.987 1.236Zm13.003 1.817L22 12.638l-11.94-.02.016 8.752 11.921 1.683Z"
      clipRule="evenodd"
    />
  </svg>
);
