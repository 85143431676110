import Decimal from 'decimal.js';

export function calcSpreadInPips(ask: number, bid: number, scale: number): number {
  if (!ask || !bid || !scale) {
    return 0;
  }
  return new Decimal(ask).minus(bid).mul(Math.pow(10, scale)).toNumber();
}

export function calcSpread(ask: number, bid: number): number {
  if (!ask || !bid) {
    return 0;
  }
  return new Decimal(ask).minus(bid).toNumber();
}
