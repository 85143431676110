export const IconCrown = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path
      d="M12.5001 2.75098C12.1662 2.75098 11.8392 2.84654 11.5578 3.02637C11.2764 3.20621 11.0523 3.46281 10.912 3.76589L10.9062 3.77863L7.7033 10.9605L4.80354 7.83702C4.55317 7.55655 4.21899 7.36405 3.85042 7.28828C3.47598 7.21131 3.08663 7.25906 2.74188 7.42424C2.39713 7.58942 2.11595 7.86295 1.94132 8.203C1.76669 8.54306 1.70821 8.93094 1.77482 9.30737C1.77713 9.32042 1.7797 9.33343 1.78252 9.34637L3.90757 19.0823C3.98424 19.4788 4.19577 19.8368 4.50659 20.0954C4.82236 20.358 5.22044 20.5013 5.63112 20.5H19.3711C19.7825 20.5 20.1807 20.3551 20.4959 20.0908C20.8049 19.8315 21.0147 19.4737 21.0902 19.078L23.215 9.34356C23.2185 9.32733 23.2216 9.31101 23.2244 9.29462C23.2867 8.92025 23.2257 8.53575 23.0505 8.19909C22.8752 7.86243 22.5953 7.59187 22.2529 7.42819C21.9105 7.26451 21.5241 7.21659 21.1521 7.29164C20.7843 7.36584 20.4502 7.55623 20.199 7.83458L17.297 10.9605L14.0941 3.77863L14.0883 3.76589C13.948 3.46281 13.7239 3.20621 13.4425 3.02637C13.1611 2.84654 12.8341 2.75098 12.5001 2.75098Z"
      fill="url(#paint0_radial_2937_8523)"
    />
    <path
      d="M12.5001 2.75098C12.1662 2.75098 11.8392 2.84654 11.5578 3.02637C11.2764 3.20621 11.0523 3.46281 10.912 3.76589L10.9062 3.77863L7.7033 10.9605L4.80354 7.83702C4.55317 7.55655 4.21899 7.36405 3.85042 7.28828C3.47598 7.21131 3.08663 7.25906 2.74188 7.42424C2.39713 7.58942 2.11595 7.86295 1.94132 8.203C1.76669 8.54306 1.70821 8.93094 1.77482 9.30737C1.77713 9.32042 1.7797 9.33343 1.78252 9.34637L3.90757 19.0823C3.98424 19.4788 4.19577 19.8368 4.50659 20.0954C4.82236 20.358 5.22044 20.5013 5.63112 20.5H19.3711C19.7825 20.5 20.1807 20.3551 20.4959 20.0908C20.8049 19.8315 21.0147 19.4737 21.0902 19.078L23.215 9.34356C23.2185 9.32733 23.2216 9.31101 23.2244 9.29462C23.2867 8.92025 23.2257 8.53575 23.0505 8.19909C22.8752 7.86243 22.5953 7.59187 22.2529 7.42819C21.9105 7.26451 21.5241 7.21659 21.1521 7.29164C20.7843 7.36584 20.4502 7.55623 20.199 7.83458L17.297 10.9605L14.0941 3.77863L14.0883 3.76589C13.948 3.46281 13.7239 3.20621 13.4425 3.02637C13.1611 2.84654 12.8341 2.75098 12.5001 2.75098Z"
      fill="url(#paint1_angular_2937_8523)"
      fillOpacity="0.08"
    />
    <path
      d="M12.5001 2.75098C12.1662 2.75098 11.8392 2.84654 11.5578 3.02637C11.2764 3.20621 11.0523 3.46281 10.912 3.76589L10.9062 3.77863L7.7033 10.9605L4.80354 7.83702C4.55317 7.55655 4.21899 7.36405 3.85042 7.28828C3.47598 7.21131 3.08663 7.25906 2.74188 7.42424C2.39713 7.58942 2.11595 7.86295 1.94132 8.203C1.76669 8.54306 1.70821 8.93094 1.77482 9.30737C1.77713 9.32042 1.7797 9.33343 1.78252 9.34637L3.90757 19.0823C3.98424 19.4788 4.19577 19.8368 4.50659 20.0954C4.82236 20.358 5.22044 20.5013 5.63112 20.5H19.3711C19.7825 20.5 20.1807 20.3551 20.4959 20.0908C20.8049 19.8315 21.0147 19.4737 21.0902 19.078L23.215 9.34356C23.2185 9.32733 23.2216 9.31101 23.2244 9.29462C23.2867 8.92025 23.2257 8.53575 23.0505 8.19909C22.8752 7.86243 22.5953 7.59187 22.2529 7.42819C21.9105 7.26451 21.5241 7.21659 21.1521 7.29164C20.7843 7.36584 20.4502 7.55623 20.199 7.83458L17.297 10.9605L14.0941 3.77863L14.0883 3.76589C13.948 3.46281 13.7239 3.20621 13.4425 3.02637C13.1611 2.84654 12.8341 2.75098 12.5001 2.75098Z"
      fill="url(#paint2_angular_2937_8523)"
      fillOpacity="0.04"
    />
    <defs>
      <radialGradient
        id="paint0_radial_2937_8523"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(6.24352 20.5) rotate(-54.8246) scale(21.7142 151.312)"
      >
        <stop stopColor="#060606" />
        <stop offset="1" stopColor="#111111" />
      </radialGradient>
      <radialGradient
        id="paint1_angular_2937_8523"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(5.69625 2.75096) rotate(53.3351) scale(22.127 163.669)"
      >
        <stop stopColor="#424242" />
        <stop offset="1" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint2_angular_2937_8523"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(6.26307 20.5) rotate(-33.8892) scale(15.0927 198.698)"
      >
        <stop stopColor="#5C5C5C" />
        <stop offset="1" stopOpacity="0.04" />
      </radialGradient>
    </defs>
  </svg>
);
