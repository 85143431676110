import { ComponentPropsWithoutRef } from 'react';

import classNames from 'classnames';

import { INavItem } from '@/blocks/sections/Header/data/types';
import { useHeaderScrollStates } from '@/blocks/sections/Header/hooks/useHeaderScrollStates';
import { isIncludesPage, useSelectedProduct } from '@/blocks/sections/Header/hooks/useSelectedProduct';
import { usePathname } from '@/i18n/routing';

import { NavItem } from './NavItem';
import styles from './styles.module.scss';

declare module 'csstype' {
  interface Properties {
    [index: `--${string}`]: any;
  }
}

interface Props extends ComponentPropsWithoutRef<'div'> {
  headerData: INavItem[];
}

export const NavItems = ({ headerData, className }: Props) => {
  const pathname = usePathname();
  const { pageScrolledDown } = useHeaderScrollStates();

  const { selectedProduct } = useSelectedProduct(headerData);

  return (
    <div className={classNames(styles.navItems, pageScrolledDown && styles.pageScrolledDown, className)}>
      {headerData.map((navItem, index) => (
        <NavItem
          key={index}
          selected={selectedProduct && isIncludesPage(navItem.subitems || [], pathname)}
          navItem={navItem}
        />
      ))}
    </div>
  );
};
