import { LogoProps } from '@/blocks/components/Logo/index';

export function LogoShortDefault(props: LogoProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="14" fill="none" viewBox="0 0 24 14" {...props}>
      <path fill="#2575D2" d="M2.053 8.464a1.479 1.479 0 1 0 0-2.958 1.479 1.479 0 0 0 0 2.958Z" />
      <path
        fill="currentcolor"
        d="M23.418.848h-2.537l-8.106 12.274h2.57L23.418.848ZM12.832.848l3.483 5.549.314-.441.203-.286c.5-.703.549-1.63.128-2.383L15.593.847h-2.76ZM19.87 7.56l3.491 5.562H20.6l-1.375-2.453a2.23 2.23 0 0 1 .127-2.38l.517-.73ZM11.678 1.383c.67.358 1.187.856 1.551 1.496.366.64.548 1.382.548 2.228 0 .846-.185 1.584-.555 2.216a3.77 3.77 0 0 1-1.586 1.47c-.687.35-1.497.524-2.43.524H7.187v3.805H4.23V8.473c0-.826.67-1.496 1.495-1.496h2.929c.444 0 .819-.078 1.124-.234a1.61 1.61 0 0 0 .692-.655c.157-.28.235-.596.235-.98 0-.385-.079-.715-.235-.993a1.575 1.575 0 0 0-.692-.643c-.305-.15-.683-.227-1.133-.227H7.187v.778c0 .815-.66 1.477-1.476 1.477H4.23V.848h5.058c.923 0 1.72.178 2.39.535Z"
      />
    </svg>
  );
}
