export const AndroidIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3887_21928)">
      <path
        d="M16.4526 14.625C16.4526 15.2204 15.9699 15.7031 15.3745 15.7031C14.7791 15.7031 14.2964 15.2204 14.2964 14.625C14.2964 14.0296 14.7791 13.5469 15.3745 13.5469C15.9699 13.5469 16.4526 14.0296 16.4526 14.625Z"
        fill="#3760FF"
        stroke="#3760FF"
        strokeWidth="0.09375"
      />
      <path
        d="M9.70264 14.625C9.70264 15.2204 9.21994 15.7031 8.62451 15.7031C8.02908 15.7031 7.54639 15.2204 7.54639 14.625C7.54639 14.0296 8.02908 13.5469 8.62451 13.5469C9.21994 13.5469 9.70264 14.0296 9.70264 14.625Z"
        fill="#3760FF"
        stroke="#3760FF"
        strokeWidth="0.09375"
      />
      <path
        d="M2.25 18.0001V15.856C2.25 10.4672 6.57656 6.01881 11.9653 6.00006C13.2486 5.9955 14.5202 6.24432 15.7071 6.73226C16.8941 7.2202 17.973 7.93765 18.882 8.84348C19.7911 9.7493 20.5124 10.8257 21.0045 12.0109C21.4967 13.1961 21.75 14.4667 21.75 15.7501V18.0001C21.75 18.199 21.671 18.3897 21.5303 18.5304C21.3897 18.671 21.1989 18.7501 21 18.7501H3C2.80109 18.7501 2.61032 18.671 2.46967 18.5304C2.32902 18.3897 2.25 18.199 2.25 18.0001Z"
        stroke="#3760FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.99951 5.25L5.91232 8.16281"
        stroke="#3760FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.9999 5.25L18.103 8.14688"
        stroke="#3760FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3887_21928">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
