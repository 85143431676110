export const Warning = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.2472 4.48712L20.8169 18.1059C21.3475 19.0654 20.662 20.25 19.5697 20.25H4.43028C3.33801 20.25 2.65253 19.0654 3.18309 18.1059L10.7528 4.48712C11.2981 3.50429 12.7019 3.50429 13.2472 4.48712Z"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 13.7933V10.2063"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 17.4456C12.4142 17.4456 12.75 17.1098 12.75 16.6956C12.75 16.2813 12.4142 15.9456 12 15.9456C11.5858 15.9456 11.25 16.2813 11.25 16.6956C11.25 17.1098 11.5858 17.4456 12 17.4456Z"
      fill="currentcolor"
    />
  </svg>
);
