import { useEffect, useRef, useState } from 'react';

type Loc = { x: number; y: number };

function useRefState<T>(
  initialState: T,
): [React.MutableRefObject<T>, React.Dispatch<React.SetStateAction<T>>] {
  const [internalState, setInternalState] = useState<T>(initialState);

  const stateRef = useRef<T>(internalState);

  const setState = (newState: React.SetStateAction<T>) => {
    if (newState instanceof Function) {
      stateRef.current = newState(stateRef.current);
      setInternalState(newState);
    } else {
      stateRef.current = newState;
      setInternalState(newState);
    }
  };

  return [stateRef, setState];
}

interface UseMenuAimProps {
  menuRef: React.MutableRefObject<null | HTMLElement>;
  tolerance?: number;
  submenuDirection?: 'right' | 'left' | 'below' | 'above';
  defaultActiveRow?: { rowIndex: number; hasSubmenus: boolean } | null;
}

export function useMenuAim(opts: UseMenuAimProps) {
  const MOUSE_LOCS_TRACKED = 3;
  const DELAY = 300;

  const options: Required<UseMenuAimProps> = {
    tolerance: 75,
    submenuDirection: 'right',
    defaultActiveRow: null,
    ...opts,
  };

  const mouseLocs = useRef<Loc[]>([]);
  const lastDelayLoc = useRef<Loc | null>(null);
  const [activeRow, setActiveRow] = useRefState<null | { rowIndex: number; hasSubmenus: boolean }>(
    options.defaultActiveRow,
  );
  const timeoutId = useRef<null | NodeJS.Timeout>(null);

  function mouseleaveMenu() {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    setActiveRow(options.defaultActiveRow);
  }

  function mouseenterRow(rowIndex: number, hasSubmenus: boolean) {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    possiblyActivate(rowIndex, hasSubmenus);
  }

  function clickRow(rowIndex: number, hasSubmenus: boolean) {
    setActiveRow({ rowIndex, hasSubmenus });
  }

  function possiblyActivate(rowIndex: number, hasSubmenus: boolean) {
    const delay = activationDelay();
    if (delay) {
      timeoutId.current = setTimeout(function () {
        possiblyActivate(rowIndex, hasSubmenus);
      }, delay);
    } else {
      setActiveRow({ rowIndex, hasSubmenus });
    }
  }

  function activationDelay() {
    if (activeRow.current === null || !activeRow.current.hasSubmenus) {
      return 0;
    }

    const offset = opts.menuRef.current?.getBoundingClientRect() as DOMRect;

    const upperLeft: Loc = {
      x: offset.left,
      y: offset.top, // changed
    };

    const upperRight: Loc = {
      x: offset.left + offset.width,
      y: upperLeft.y,
    };

    const lowerLeft: Loc = {
      x: offset.left,
      y: offset.top + offset.height, // changed
    };

    const lowerRight: Loc = {
      x: offset.left + offset.width,
      y: lowerLeft.y,
    };

    if (options.submenuDirection == 'right') {
      upperRight.y -= options.tolerance;
      lowerRight.y += options.tolerance;
    } else if (options.submenuDirection == 'left') {
      upperLeft.y -= options.tolerance;
      lowerLeft.y += options.tolerance;
    } else if (options.submenuDirection == 'above') {
      upperLeft.x -= options.tolerance;
      upperRight.x += options.tolerance;
    } else if (options.submenuDirection == 'below') {
      lowerLeft.x -= options.tolerance;
      lowerRight.x += options.tolerance;
    }

    const loc = mouseLocs.current[mouseLocs.current.length - 1];
    const prevLoc = mouseLocs.current[0] || loc;

    if (!loc) {
      return 0;
    }

    if (
      prevLoc.x < offset.left ||
      prevLoc.x > lowerRight.x ||
      prevLoc.y < offset.top ||
      prevLoc.y > lowerRight.y
    ) {
      return 0;
    }

    if (lastDelayLoc.current && loc.x == lastDelayLoc.current.x && loc.y == lastDelayLoc.current.y) {
      return 0;
    }

    function slope(a: Loc, b: Loc) {
      return (b.y - a.y) / (b.x - a.x);
    }

    let decreasingCorner = upperRight;
    let increasingCorner = lowerRight;

    if (options.submenuDirection == 'left') {
      decreasingCorner = lowerLeft;
      increasingCorner = upperLeft;
    } else if (options.submenuDirection == 'below') {
      decreasingCorner = lowerRight;
      increasingCorner = lowerLeft;
    } else if (options.submenuDirection == 'above') {
      decreasingCorner = upperLeft;
      increasingCorner = upperRight;
    }

    const decreasingSlope = slope(loc, decreasingCorner);
    const increasingSlope = slope(loc, increasingCorner);
    const prevDecreasingSlope = slope(prevLoc, decreasingCorner);
    const prevIncreasingSlope = slope(prevLoc, increasingCorner);

    if (decreasingSlope < prevDecreasingSlope && increasingSlope > prevIncreasingSlope) {
      lastDelayLoc.current = loc;
      return DELAY;
    }

    lastDelayLoc.current = null;
    return 0;
  }

  useEffect(() => {
    function mousemoveDocument(e: MouseEvent) {
      const newLocs = [...mouseLocs.current, { x: e.pageX, y: e.pageY }];
      if (mouseLocs.current.length >= MOUSE_LOCS_TRACKED) {
        newLocs.shift();
      }
      mouseLocs.current = [...newLocs];
    }

    window.addEventListener('mousemove', mousemoveDocument);
    return () => window.removeEventListener('mousemove', mousemoveDocument);
  }, []);

  return { mouseleaveMenu, activeRow: activeRow.current, mouseenterRow, clickRow };
}
