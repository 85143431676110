'use client';
import React, { ReactNode } from 'react';
import '@splidejs/react-splide/css/core';
import typography from '@/styles/typography.module.scss';
import styles from './style.module.scss';
import classNames from 'classnames';
import Image from 'next/image';
import { LogoTrustpilot } from '@/blocks/sections/Testimonials/components/ReviewsSlider/logos/LogoTrustpilot';
import rtl from '@/styles/rtl.module.scss';

export interface CardsContentProps {
  itemsData: { text: ReactNode; author: ReactNode; icon: ReactNode; rating: string }[];
}

export default function CardsContent(props: CardsContentProps) {
  return (
    <div className={styles.wrapper}>
      {props.itemsData.map((el, key) => (
        <div key={key} className={styles.wrapperReviewItem}>
          <div>
            <div className={classNames(typography.bodyM, styles.review)}>{el.text}</div>
          </div>
          <div className={styles.wrapperInfo}>
            <p className={classNames(typography.subheadingM, styles.author)}>{el.author}</p>
            <div className={styles.wrapperImg}>
              <div>
                <Image src={el.rating} alt={'icon'} className={rtl.dirDependent} />
              </div>
              <div className={styles.wrapperTrustpilot}>
                <LogoTrustpilot textColor={'#FFFFFF'} />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
