export const LogoReviews = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="192" height="40" viewBox="0 0 192 40" fill="none">
    <g clipPath="url(#clip0_8234_13414)">
      <path
        d="M50.9053 27.9415L46.0589 21.7028C49.1706 20.9743 51.346 18.943 51.346 15.5762V15.5187C51.346 13.8299 50.7285 12.3275 49.6854 11.2833C48.3524 9.95035 46.2651 9.15279 43.6607 9.15279H35.9724C35.217 9.15279 34.5661 9.79716 34.5661 10.5882V28.9296C34.5661 29.7209 35.217 30.3653 35.9724 30.3653C36.7604 30.3653 37.4068 29.7191 37.4068 28.9296V22.2362H42.9146L48.6176 29.6451C48.9307 30.0627 49.3536 30.3653 49.9106 30.3653C50.6298 30.3653 51.346 29.7144 51.346 28.9577C51.346 28.574 51.1825 28.2541 50.9053 27.9415ZM48.476 15.6066L48.4762 15.6651H48.476C48.476 18.1625 46.3688 19.6298 43.4545 19.6298H37.4068V11.8177H43.4839C46.6164 11.8177 48.476 13.2222 48.476 15.6066Z"
        fill="white"
      />
      <path
        d="M55.4854 28.7832C55.4854 29.5732 56.1363 30.2179 56.8916 30.2179H69.8036C70.5358 30.2179 71.1209 29.6318 71.1209 28.8995C71.1209 28.1682 70.5361 27.5821 69.8036 27.5821H58.3263V20.9148H68.3365C69.069 20.9148 69.6549 20.3297 69.6549 19.5974C69.6549 18.9016 69.069 18.279 68.3365 18.279H58.3263V11.7896H69.6572C70.3882 11.7896 70.9755 11.2034 70.9755 10.4712C70.9755 9.7389 70.3882 9.15279 69.6572 9.15279H56.8916C56.1365 9.15279 55.4854 9.79716 55.4854 10.5884V28.7832Z"
        fill="white"
      />
      <path
        d="M83.0663 30.4532L83.2203 30.4527C83.989 30.4394 84.4679 30.0064 84.7717 29.3297L92.7293 10.9491C92.8001 10.7723 92.836 10.5955 92.836 10.3833C92.836 9.66031 92.2248 9.00613 91.4297 9.00613C90.7996 9.00613 90.2974 9.48176 90.0513 9.9717C90.0485 9.97722 90.046 9.98275 90.0435 9.98852L83.1552 26.4885L76.2941 10.047C76.0495 9.45187 75.5508 9.00613 74.8504 9.00613C74.0579 9.00613 73.4137 9.69345 73.4137 10.4114C73.4137 10.6623 73.4519 10.8406 73.5581 11.054L81.4755 29.3241C81.7515 30.0122 82.2741 30.4532 83.0663 30.4532Z"
        fill="white"
      />
      <path
        d="M96.5947 10.4415V28.9294C96.5947 29.7206 97.2456 30.365 98.001 30.365C98.789 30.365 99.4354 29.7189 99.4354 28.9294V10.4415C99.4354 9.65202 98.789 9.00613 98.001 9.00613C97.2456 9.00613 96.5947 9.65026 96.5947 10.4415Z"
        fill="white"
      />
      <path
        d="M118.839 9.15329L118.805 9.15279H106.039C105.285 9.15279 104.634 9.79716 104.634 10.5884V28.7832C104.634 29.5732 105.285 30.2179 106.039 30.2179C106.039 30.2179 118.952 30.2179 118.952 30.2179C119.683 30.2179 120.269 29.6318 120.269 28.8995C120.269 28.1682 119.683 27.5821 118.952 27.5821H107.474V20.9148H117.484C118.217 20.9148 118.802 20.3297 118.802 19.5974C118.802 18.9016 118.217 18.279 117.484 18.279H107.474V11.7896H118.805C119.536 11.7896 120.124 11.2034 120.124 10.4712C120.124 9.75046 119.554 9.17112 118.839 9.15329Z"
        fill="white"
      />
      <path
        d="M144.006 30.4818H144.247C144.932 30.4672 145.424 29.9981 145.665 29.3085C145.664 29.3108 152.238 11.0585 152.238 11.0585C152.239 11.056 152.24 11.0538 152.241 11.0512C152.306 10.8503 152.4 10.613 152.4 10.4121C152.4 9.69268 151.72 9.00687 150.964 9.00687C150.288 9.00687 149.815 9.45436 149.601 10.0947C149.602 10.092 144.163 25.7356 144.163 25.7356L139.034 10.0952C138.822 9.45637 138.351 9.00687 137.642 9.00687V9.00913L137.495 9.00687C136.75 9.00687 136.314 9.45637 136.102 10.0952L130.973 25.7351L125.564 10.1495C125.354 9.52091 124.816 9.00687 124.113 9.00687C123.325 9.00687 122.649 9.68941 122.649 10.4415C122.649 10.6457 122.711 10.8496 122.779 11.0525L129.354 29.309C129.596 30.0318 130.089 30.4682 130.772 30.4818H131.013C131.697 30.4672 132.19 29.9979 132.43 29.308L137.495 14.2533L142.589 29.3085C142.83 29.9981 143.324 30.4672 144.006 30.4818Z"
        fill="white"
      />
      <path
        d="M167.222 24.6154V24.6739C167.222 26.5696 165.488 27.905 162.906 27.905C160.368 27.905 158.484 27.1359 156.602 25.5412L156.596 25.5365C156.41 25.3547 156.082 25.2042 155.716 25.2042C154.958 25.2042 154.338 25.8157 154.338 26.6105C154.338 27.0816 154.56 27.4794 154.884 27.7016C157.219 29.5547 159.764 30.5114 162.818 30.5114C167.061 30.5114 170.092 28.1027 170.092 24.4396V24.3798C170.092 21.1253 167.94 19.3496 163.196 18.315C163.196 18.315 163.195 18.3147 163.195 18.3147C158.884 17.3906 157.826 16.427 157.826 14.5788V14.5203C157.826 12.7946 159.445 11.4659 161.937 11.4659C163.789 11.4659 165.413 11.9807 167.095 13.2059C167.095 13.2064 167.096 13.2066 167.096 13.2071C167.347 13.387 167.599 13.4613 167.924 13.4613C168.684 13.4613 169.3 12.8465 169.3 12.0844C169.3 11.5482 168.983 11.1522 168.696 10.9365C168.695 10.9355 168.693 10.9342 168.692 10.9332C166.77 9.55182 164.759 8.85948 161.995 8.85948C157.929 8.85948 154.984 11.3627 154.984 14.7262L155.246 14.9879C155.245 14.9879 154.984 14.7279 154.984 14.7279V14.7824L155.246 15.0454L154.984 14.7839C154.984 18.2793 157.194 19.9051 162.087 20.9681C162.088 20.9681 162.088 20.9683 162.089 20.9683C166.194 21.8349 167.222 22.7995 167.222 24.6154Z"
        fill="white"
      />
      <path
        d="M173.325 27.4779L173.3 27.4777C172.748 27.4777 172.355 27.8729 172.355 28.4058V28.7343C172.355 29.2664 172.749 29.6793 173.3 29.6793C173.832 29.6793 174.228 29.2674 174.228 28.7343C174.228 28.8212 174.158 28.8912 174.071 28.8912C174.071 28.8912 174.228 28.8737 174.228 28.727V28.4058C174.228 27.8802 173.845 27.49 173.325 27.4779ZM172.526 28.889C172.517 28.8907 172.512 28.8912 172.512 28.8912C172.517 28.8912 172.522 28.8912 172.526 28.8907L172.526 28.889Z"
        fill="white"
      />
      <path
        d="M176.675 21.5125V28.882C176.675 29.3315 177.023 29.6793 177.471 29.6793C177.923 29.6793 178.253 29.3325 178.253 28.882V21.5125C178.253 21.0618 177.905 20.7152 177.455 20.7152C177.026 20.7152 176.675 21.0829 176.675 21.5125ZM176.56 18.5086C176.56 18.9905 176.954 19.3383 177.455 19.3383C177.976 19.3383 178.367 18.9895 178.367 18.5086V18.2962C178.367 17.7947 177.976 17.4652 177.455 17.4652C176.954 17.4652 176.56 17.7937 176.56 18.2962L176.56 18.5086Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M189.512 25.2093L189.512 25.1737C189.512 22.6911 187.575 20.6168 184.94 20.6168C182.289 20.6168 180.351 22.7252 180.351 25.2061L180.384 25.2387L180.351 25.2071V25.2387C180.351 27.7203 182.273 29.7943 184.907 29.7943C187.558 29.7943 189.51 27.6887 189.512 25.2093ZM187.902 25.2387V25.2061C187.902 23.4728 186.609 22.0462 184.907 22.0462C183.159 22.0462 181.961 23.4723 181.961 25.1737V25.2061C181.961 26.9391 183.238 28.3474 184.94 28.3474C186.688 28.3474 187.902 26.9398 187.902 25.2387Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.0208 10.7763L24.7592 11.4092C27.2187 13.3953 28.421 15.9607 28.6445 19.0651C28.4577 25.0571 25.6306 28.9083 20.7741 30.5599C15.1633 32.2505 11.6079 30.8826 8.16757 27.3772L7.95085 28.7247L9.3496 30.1823C14.2151 35.2527 22.2819 35.4191 27.3523 30.5537C32.4226 25.6879 32.5891 17.6214 27.7234 12.5508L26.0208 10.7763Z"
        fill="#FEEF7C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.25923 27.9739L8.10451 27.2356C4.59257 22.2589 4.44542 14.9728 9.25163 10.9616C12.8143 7.98837 19.8834 6.44724 25.5389 11.4267L25.961 10.6842L24.5829 9.24774C19.7171 4.17735 11.6506 4.01085 6.57995 8.87659C1.50955 13.7421 1.34331 21.8088 6.20879 26.8792L7.25923 27.9739Z"
        fill="#5CD3CB"
      />
      <path
        d="M4.2207 19.7511C4.2207 26.7961 9.93271 32.5081 16.9777 32.5081C24.0239 32.5081 29.7357 26.7961 29.7357 19.7511C29.7357 12.7049 24.0239 6.99287 16.9777 6.99287C9.93271 6.99287 4.2207 12.7049 4.2207 19.7511ZM13.1001 22.0584C13.2342 21.634 13.085 21.1715 12.7285 20.905C11.7842 20.1999 9.92267 18.8097 8.67183 17.8755C8.49128 17.7406 8.41745 17.5053 8.48851 17.2914C8.55958 17.0774 8.75948 16.9333 8.98498 16.9333H13.8331C14.2844 16.9333 14.6847 16.644 14.8266 16.2158C15.2088 15.0614 15.9684 12.7672 16.4722 11.2464C16.543 11.0327 16.7426 10.888 16.9679 10.8878C17.1931 10.8875 17.3935 11.0314 17.4649 11.2449C17.9739 12.7669 18.7426 15.0649 19.1285 16.2188C19.2714 16.6455 19.671 16.9333 20.121 16.9333C21.2678 16.9333 23.4772 16.9333 24.9814 16.9333C25.2062 16.9333 25.4061 17.0769 25.4774 17.2904C25.5487 17.5038 25.4759 17.7389 25.2961 17.8742C24.049 18.8137 22.1864 20.2164 21.2442 20.9261C20.8897 21.193 20.7422 21.6549 20.8761 22.0777C21.2405 23.2284 21.9712 25.5359 22.4649 27.0947C22.5337 27.3124 22.4534 27.5499 22.2663 27.681C22.0792 27.8121 21.8288 27.8068 21.6475 27.6677C20.3947 26.7059 18.5643 25.3009 17.6057 24.5654C17.2288 24.2761 16.7045 24.2768 16.3285 24.5676C15.3793 25.3014 13.5737 26.6976 12.3314 27.6582C12.1506 27.798 11.8998 27.8043 11.7122 27.6732C11.5246 27.5424 11.4437 27.3048 11.5125 27.0869C12.0062 25.5236 12.736 23.2121 13.1001 22.0584Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_8234_13414">
        <rect width="192" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
