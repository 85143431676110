'use client';
import { WithClassName } from '@/blocks/components/types';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { MiniChartCreator } from './miniChartCreator';
import { useEffect, useId, useMemo, useRef, useState } from 'react';

export const DEFAULT_SPARKLINE_MINI_CHART_WIDTH = 128;
export const DEFAULT_SPARKLINE_MINI_CHART_HEIGHT = 34;

function debounce(func: Function, timeout = 300) {
  let timer: any;
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(args);
    }, timeout);
  };
}

export const SparklineChart = ({
  chartValues,
  height = DEFAULT_SPARKLINE_MINI_CHART_HEIGHT,
  width = DEFAULT_SPARKLINE_MINI_CHART_WIDTH,
  negative,
  autosize = false,
  className,
}: {
  chartValues: number[];
  width?: number;
  height?: number;
  negative?: boolean;
  autosize?: boolean;
} & WithClassName) => {
  const [dimensions, setDimensions] = useState({ width: width, height: height });
  const ref = useRef<HTMLElement | null>(null);
  const chartCreator = useMemo(
    () => new MiniChartCreator(dimensions.width, dimensions.height),
    [dimensions.width, dimensions.height],
  );

  const id = useId();

  useEffect(() => {
    if (ref.current !== null && autosize) {
      const chart = ref.current;
      const obs = new ResizeObserver(
        debounce(() => {
          const r = chart?.getBoundingClientRect();
          setDimensions({
            width: r.width,
            height: r.height,
          });
        }, 300),
      );
      obs.observe(ref.current);
      return () => obs.unobserve(chart);
    }
  }, [autosize]);

  return (
    <svg
      className={classNames(negative ? styles.chartNegative : styles.chartPositive, className)}
      width={chartCreator.chartWidth}
      height={chartCreator.chartHeight}
      viewBox={`2 0 ${chartCreator.chartWidth - 2} ${chartCreator.chartHeight}`}
      preserveAspectRatio="none"
      // @ts-ignore
      ref={ref}
    >
      <defs>
        <linearGradient
          id={`${id}`}
          x1="64.7711"
          y1="2.06061"
          x2="63.9967"
          y2="33.9999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.26" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
      </defs>
      <path d={chartCreator.getPath(chartValues)} fill={`url(#${id})`}></path>
    </svg>
  );
};
