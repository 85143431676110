'use client';
import React, { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';

type AnimationState = 'cover' | 'products';

type Context = {
  slideState: AnimationState | null;
  setSlideState(value: AnimationState): void;
};

const defaultValue = {
  slideState: null,
  setSlideState: () => {},
};

const HeroAnimationContext = createContext<Context>(defaultValue);

export function HeroAnimationProvider({ children }: PropsWithChildren) {
  const [slideState, setSlideState] = useState<AnimationState>('cover');

  const contextValue = useMemo(
    () => ({
      slideState,
      setSlideState,
    }),
    [slideState, setSlideState],
  );

  return <HeroAnimationContext.Provider value={contextValue}>{children}</HeroAnimationContext.Provider>;
}

export const useHeroAnimationContext = () => {
  return useContext(HeroAnimationContext);
};
