import { PropsWithChildren } from 'react';
import scrollbars from '@/styles/scrollbar.module.scss';

export function NoScrollbarIncludingDescendants({
  children,
  Component = 'div',
}: { Component?: keyof JSX.IntrinsicElements } & PropsWithChildren) {
  return <Component className={scrollbars.noScrollbarWithDescendants}>{children}</Component>;
}

export function NoScrollbar({
  children,
  Component = 'div',
}: { Component?: keyof JSX.IntrinsicElements } & PropsWithChildren) {
  return <Component className={scrollbars.noScrollbar}>{children}</Component>;
}
