import classNames from 'classnames';
import Image, { ImageProps } from 'next/image';

import { WithClassName } from '@/blocks/components/types';

import styles from './styles.module.scss';

export function getAssetIcon(assetName: string) {
  return `/static/assets/symbol/default/${assetName.toUpperCase()}/logo.svg`;
}

export function getCurrencyIcon(currencyName: string) {
  return `/static/assets/currency/default/${currencyName.toUpperCase()}/logo.svg`;
}

export const AssetIcon = (props: { assetName: string; loading?: ImageProps['loading'] } & WithClassName) => {
  return (
    <Image
      src={getAssetIcon(props.assetName)}
      alt={props.assetName}
      className={classNames(props.className)}
      loading={props.loading || 'lazy'}
      width={32}
      height={32}
    />
  );
};

export const FreePairIcon = ({
  from,
  to,
  pairName,
  width = 24,
  height = 24,
  className,
  ...props
}: { pairName?: string; from?: string; to?: string } & Omit<ImageProps, 'src' | 'alt'>) => {
  if (!pairName && !(from && to)) {
    console.error('Error: <FreePairIcon/> - either pairName or from/to should be specified');
    return null;
  }

  if (pairName && from && to) {
    console.error('Error: <FreePairIcon/> - pairName and from/to cannot be specified all at once');
    return null;
  }

  const [_from, _to] = pairName ? pairName.split('/') : [from, to];

  if (!_from || !_to) {
    console.error('Error: <FreePairIcon/> - cannot parse from/to}');
    return null;
  }

  return (
    <div
      className={classNames(styles.freePairIcon, className)}
      style={{
        width,
        height,
      }}
    >
      <CurrencyIcon currencyName={_from} width={18} height={18} {...props} />
      <CurrencyIcon currencyName={_to} width={18} height={18} {...props} />
    </div>
  );
};

export const CurrencyIcon = ({
  width = 32,
  height = 32,
  className,
  loading = 'lazy',
  currencyName,
  ...props
}: { currencyName: string } & Omit<ImageProps, 'src' | 'alt'>) => {
  return (
    <Image
      src={getCurrencyIcon(currencyName)}
      alt={currencyName}
      className={classNames(className)}
      {...props}
      loading={loading}
      width={width}
      height={height}
    />
  );
};
