export const IconFire = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 24C19.5 23.5788 21.5763 21.5 22 19L17 24Z" fill="currentcolor" />
    <path
      d="M17 24C19.5 23.5788 21.5762 21.5 22 19"
      stroke="currentcolor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 12L17.2838 3C19.9825 5.24 26 11.0188 26 18C26 20.6522 24.9464 23.1957 23.0711 25.0711C21.1957 26.9464 18.6522 28 16 28C13.3478 28 10.8043 26.9464 8.92893 25.0711C7.05357 23.1957 6 20.6522 6 18C6 14.1788 7.8025 10.7175 9.875 8L14 12Z"
      stroke="currentcolor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
