'use client';
import { useLocale } from 'next-intl';
import { useCallback, useEffect, useState } from 'react';

export function FormatPercent({
  value,
  suffix = true,
  forcePlusSign = false,
  fraction,
  truncate,
  compact = false,
  compactNotationThreshold = 1000000,
}: {
  value: number;
  suffix?: boolean;
  fraction?: { min?: number; max?: number };
  forcePlusSign?: boolean;
  truncate?: number;
  compact?: boolean;
  compactNotationThreshold?: number;
}) {
  const locale = useLocale();
  const compactNotation = Boolean(compact && value >= compactNotationThreshold);

  const createFormatter = useCallback(() => {
    return getFormatter(locale, fraction, suffix, compactNotation);
  }, [locale, fraction, suffix, compactNotation]);

  const [formatter, setFormatter] = useState(createFormatter);
  const [formatted, setFormatted] = useState(
    getFormatted({ value, formatter, suffix, truncate, forcePlusSign }),
  );

  useEffect(() => {
    setFormatter(createFormatter);
  }, [createFormatter]);

  useEffect(() => {
    setFormatted(getFormatted({ value, formatter, suffix, truncate, forcePlusSign }));
  }, [formatter, value, forcePlusSign, suffix, truncate]);

  return <>{formatted}</>;
}

const formatters: Record<string, Intl.NumberFormat> = {};

function getFormatted({
  value,
  forcePlusSign,
  truncate,
  formatter,
  suffix,
}: {
  value: number;
  suffix?: boolean;
  fraction?: { min?: number; max?: number };
  forcePlusSign?: boolean;
  truncate?: number;
  formatter: Intl.NumberFormat;
}) {
  if (typeof value !== 'number' || isNaN(value)) {
    return '';
  }
  let formattedValue = value;
  if (truncate != null && truncate >= 0) {
    formattedValue = Math.trunc(formattedValue * 10 ** truncate) / 10 ** truncate;
  }

  const plusSign = forcePlusSign && formattedValue > 0 ? '+' : '';
  return `${plusSign}${formatter.format(suffix ? formattedValue / 100 : formattedValue)}`;
}

function getFormatter(
  locale: string,
  fraction?: { min?: number; max?: number },
  suffix = true,
  compactNotation?: boolean,
): Intl.NumberFormat {
  const cacheKey = `${locale}_${JSON.stringify(fraction)}_${suffix}_${compactNotation}`;

  if (!formatters[cacheKey]) {
    formatters[cacheKey] = new Intl.NumberFormat(locale, {
      style: suffix ? 'percent' : undefined,
      minimumFractionDigits: fraction && typeof fraction.min === 'number' ? fraction.min : 0,
      maximumFractionDigits: fraction && typeof fraction.max === 'number' ? fraction.max : 2,
      notation: compactNotation ? 'compact' : undefined,
    });
  }

  return formatters[cacheKey];
}
