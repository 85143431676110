export const IconGlobus = ({ width, height }: { width: string; height: string }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <path
          id="Ellipse 17"
          d="M36.027 17.8269C45.5991 17.8269 54.2254 19.8224 60.4292 23.0105C66.6749 26.2201 70.2541 30.5111 70.2541 35.0269C70.2541 39.5427 66.6749 43.8336 60.4292 47.0432C54.2254 50.2313 45.5991 52.2269 36.027 52.2269C26.4549 52.2269 17.8287 50.2313 11.6248 47.0432C5.37914 43.8336 1.8 39.5427 1.8 35.0269C1.8 30.5111 5.37914 26.2201 11.6248 23.0105C17.8287 19.8224 26.4549 17.8269 36.027 17.8269Z"
          fill="url(#paint0_linear_8147_13550)"
          fillOpacity="0.8"
          stroke="#B5B5B5"
          strokeWidth="1.6"
        />
        <circle id="Ellipse 15" cx="36" cy="36" r="35.2" stroke="#B5B5B5" strokeWidth="1.6" />
        <path
          id="Ellipse 16"
          d="M53.2 36.027C53.2 45.5991 51.2045 54.2254 48.0164 60.4292C44.8068 66.6749 40.5158 70.2541 36 70.2541C31.4842 70.2541 27.1932 66.6749 23.9836 60.4292C20.7955 54.2254 18.8 45.5991 18.8 36.027C18.8 26.4549 20.7955 17.8287 23.9836 11.6248C27.1932 5.37914 31.4842 1.8 36 1.8C40.5158 1.8 44.8068 5.37914 48.0164 11.6248C51.2045 17.8287 53.2 26.4549 53.2 36.027Z"
          stroke="#B5B5B5"
          strokeWidth="1.6"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_8147_13550"
          x1="70.691"
          y1="16.8403"
          x2="70.017"
          y2="49.3069"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7A95FF" stopOpacity="0.29" />
          <stop offset="1" stopColor="#3760FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
