export const IconSmartphone = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={36} height={36} viewBox="0 0 36 36" fill="none">
    <g clipPath="url(#a)">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M27 30.38V5.63c0-1.25-1-2.25-2.25-2.25h-13.5c-1.24 0-2.25 1-2.25 2.25v24.75c0 1.24 1 2.25 2.25 2.25h13.5c1.24 0 2.25-1.01 2.25-2.26Z"
      />
      <path fill="currentColor" d="M18 10.13a1.69 1.69 0 1 0 0-3.38 1.69 1.69 0 0 0 0 3.38Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);
