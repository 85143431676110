export const IconInfo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26 16C26 21.5228 21.5228 26 16 26C10.4772 26 6 21.5228 6 16C6 10.4772 10.4772 6 16 6C21.5228 6 26 10.4772 26 16ZM16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28ZM16 11C14.8542 11 14 11.7517 14 13C14 13.5523 13.5523 14 13 14C12.4477 14 12 13.5523 12 13C12 10.5664 13.8321 9 16 9C18.1679 9 20 10.5664 20 13C20 13.9174 19.7588 14.6417 19.3509 15.2253C18.9599 15.7846 18.4562 16.1526 18.0626 16.4205C17.9665 16.4859 17.8798 16.5439 17.801 16.5966L17.801 16.5966C17.5144 16.7884 17.3327 16.91 17.1839 17.0623C17.0551 17.1941 17 17.3015 17 17.5C17 18.0523 16.5523 18.5 16 18.5C15.4477 18.5 15 18.0523 15 17.5C15 16.6985 15.3199 16.108 15.7536 15.6643C16.064 15.3468 16.4673 15.0795 16.7692 14.8794L16.7694 14.8793C16.83 14.8391 16.8865 14.8016 16.9374 14.767C17.2938 14.5245 17.5401 14.3248 17.7116 14.0794C17.8662 13.8583 18 13.541 18 13C18 11.7517 17.1458 11 16 11ZM17 21C17 21.5523 16.5523 22 16 22C15.4477 22 15 21.5523 15 21C15 20.4477 15.4477 20 16 20C16.5523 20 17 20.4477 17 21Z"
      fill="currentColor"
    />
  </svg>
);
