import { useId } from 'react';

export const IconGiftBox = () => {
  const id1 = useId();
  const id2 = useId();
  const id3 = useId();

  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath={`url(#${id1})`}>
        <path d="M28.1208 14.6099L16.6533 21.0627V31.0919L28.1208 24.7945V14.6099Z" fill="#0A0A0A" />
        <path
          d="M4.30079 11.0576L16.3854 18.1789V31.7741L4.30078 24.8686L4.30079 11.0576Z"
          fill={`url(#${id3})`}
          fillOpacity="0.8"
        />
        <path
          d="M16.6679 31.2858V18.3405L28.1861 11.553V24.704L16.6679 31.2858ZM27.8915 11.0691L16.3847 17.8499L4.87793 11.0691L16.3847 4.90479L27.8915 11.0691Z"
          stroke="white"
          strokeWidth="0.566466"
        />
        <path d="M22.9316 14.4014V27.6819" stroke="white" strokeWidth="0.544218" strokeLinejoin="round" />
        <path d="M29.4381 9.17969V13.713L16.3807 21.3274L3.32422 13.713V9.17969" fill="black" />
        <path
          d="M29.4381 9.17969V13.713L16.3807 21.3274L3.32422 13.713V9.17969"
          stroke="white"
          strokeWidth="0.544218"
          strokeMiterlimit="10"
        />
        <path
          d="M18.5574 2.83141L23.6103 5.78004L29.4381 9.17831L16.3807 16.7935L3.32422 9.17831L16.1473 1.7041"
          fill="#0A0A0A"
        />
        <path
          d="M18.5574 2.83141L23.6103 5.78004L29.4381 9.17831L16.3807 16.7935L3.32422 9.17831L16.1473 1.7041"
          stroke="white"
          strokeWidth="0.544218"
          strokeLinejoin="round"
        />
        <path d="M16.3818 16.7944V21.327" stroke="white" strokeWidth="0.544218" strokeLinejoin="round" />
        <path
          d="M14.207 2.83219L16.3808 1.56494L18.7567 2.95036"
          stroke="white"
          strokeWidth="0.544218"
          strokeLinejoin="round"
        />
        <path
          d="M29.4385 13.7264V9.18555L16.6025 16.6943V21.1377L29.4385 13.7264Z"
          fill="#0A0A0A"
          stroke="white"
          strokeWidth="0.544218"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path d="M3.04785 9.35303L16.3872 17.021V21.6797L3.04785 13.8709V9.35303Z" fill={`url(#${id2})`} />
        <path d="M23.3975 12.7046V17.2379" stroke="white" strokeWidth="0.544218" strokeLinejoin="round" />
        <path
          d="M23.3968 12.7047L10.3154 5.06836"
          stroke="white"
          strokeWidth="0.544218"
          strokeLinejoin="round"
        />
        <path
          d="M9.71777 12.9972L22.7991 5.36084"
          stroke="white"
          strokeWidth="0.544218"
          strokeLinejoin="round"
        />
        <path
          d="M15.3648 2.34464C15.6599 2.88349 16.2286 4.45551 16.5809 5.97977C16.7566 6.73988 16.8739 7.46913 16.8802 8.0388C16.8834 8.32444 16.8583 8.55464 16.8074 8.72327C16.7561 8.89338 16.6894 8.96716 16.6341 8.9974C16.5788 9.02772 16.4808 9.0442 16.3098 8.99577C16.1403 8.94788 15.9329 8.84494 15.6939 8.68844C15.2173 8.37629 14.666 7.88472 14.1203 7.32729C13.0257 6.20945 12.0074 4.88367 11.7123 4.34483C11.16 3.33622 11.5299 2.07084 12.5385 1.51851C13.5471 0.966171 14.8125 1.33604 15.3648 2.34464Z"
          fill="#0A0A0A"
          stroke="white"
          strokeWidth="0.544218"
        />
        <path
          d="M20.7419 3.75884C20.2522 4.12975 19.0901 5.33144 18.1448 6.57805C17.6735 7.19969 17.268 7.81709 17.0291 8.33428C16.9094 8.59364 16.838 8.81397 16.8155 8.98859C16.7926 9.16484 16.8233 9.25938 16.8613 9.30968C16.8994 9.35998 16.9822 9.4151 17.158 9.44091C17.3322 9.46649 17.5637 9.45755 17.8457 9.41253C18.4083 9.32274 19.1124 9.09976 19.8386 8.81452C21.2947 8.24247 22.7663 7.44943 23.2561 7.07852C24.1728 6.38426 24.3531 5.07831 23.6588 4.1616C22.9646 3.2449 21.6586 3.06458 20.7419 3.75884Z"
          fill="#0A0A0A"
          stroke="white"
          strokeWidth="0.544218"
        />
      </g>
      <defs>
        <linearGradient
          id={id3}
          x1="16.448"
          y1="31.6668"
          x2="5.55086"
          y2="31.41"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7A95FF" stopOpacity="0.29" />
          <stop offset="1" stopColor="#3760FF" />
        </linearGradient>
        <linearGradient
          id={id2}
          x1="15.19"
          y1="29.3121"
          x2="4.29287"
          y2="29.0553"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#151C39" />
          <stop offset="1" stopColor="#4269FE" />
        </linearGradient>
        <clipPath id={id1}>
          <rect width="32" height="32" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export function IconGiftBoxLight() {
  const id1 = useId();
  const id2 = useId();
  const id3 = useId();
  const id4 = useId();

  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28.6208 14.1094L17.1533 20.5622V30.5914L28.6208 24.294V14.1094Z" fill="white" />
      <path
        d="M4.80079 10.5566L16.8854 17.678V31.2731L4.80078 24.3676L4.80079 10.5566Z"
        fill={`url(#${id1})`}
        fillOpacity="0.8"
      />
      <path
        d="M17.1679 30.7853V17.84L28.6861 11.0525V24.2035L17.1679 30.7853ZM28.3915 10.5687L16.8847 17.3494L5.37793 10.5687L16.8847 4.4043L28.3915 10.5687Z"
        stroke="#383838"
        strokeWidth="0.566466"
      />
      <path d="M23.4316 13.9004V27.1809" stroke="#383838" strokeWidth="0.544218" strokeLinejoin="round" />
      <path d="M29.9381 8.67969V13.213L16.8807 20.8274L3.82422 13.213V8.67969" fill="white" />
      <path
        d="M29.9381 8.67969V13.213L16.8807 20.8274L3.82422 13.213V8.67969"
        stroke="#383838"
        strokeWidth="0.544218"
        strokeMiterlimit="10"
      />
      <path
        d="M19.0574 2.33043L24.1103 5.27906L29.9381 8.67733L16.8807 16.2925L3.82422 8.67733L16.6473 1.20312"
        fill="white"
      />
      <path
        d="M19.0574 2.33043L24.1103 5.27906L29.9381 8.67733L16.8807 16.2925L3.82422 8.67733L16.6473 1.20312"
        stroke="#383838"
        strokeWidth="0.544218"
        strokeLinejoin="round"
      />
      <path d="M16.8818 16.2939V20.8265" stroke="#383838" strokeWidth="0.544218" strokeLinejoin="round" />
      <path
        d="M14.707 2.3317L16.8808 1.06445L19.2567 2.44988"
        stroke="#383838"
        strokeWidth="0.544218"
        strokeLinejoin="round"
      />
      <path
        d="M29.9385 13.2264V8.68555L17.1025 16.1943V20.6377L29.9385 13.2264Z"
        fill="white"
        stroke="#383838"
        strokeWidth="0.544218"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path d="M3.54785 8.85254L16.8872 16.5205V21.1792L3.54785 13.3704V8.85254Z" fill={`url(#${id2})`} />
      <path d="M23.8975 12.2041V16.7374" stroke="#383838" strokeWidth="0.544218" strokeLinejoin="round" />
      <path
        d="M23.8968 12.2047L10.8154 4.56836"
        stroke="#383838"
        strokeWidth="0.544218"
        strokeLinejoin="round"
      />
      <path
        d="M10.2178 12.4967L23.2991 4.86035"
        stroke="#383838"
        strokeWidth="0.544218"
        strokeLinejoin="round"
      />
      <path
        d="M15.8648 1.84415C16.1599 2.383 16.7286 3.95502 17.0809 5.47928C17.2566 6.23939 17.3739 6.96864 17.3802 7.53831C17.3834 7.82395 17.3583 8.05415 17.3074 8.22278C17.2561 8.39289 17.1894 8.46667 17.1341 8.49691C17.0788 8.52723 16.9808 8.54371 16.8098 8.49528C16.6403 8.44739 16.4329 8.34445 16.1939 8.18795C15.7173 7.8758 15.166 7.38424 14.6203 6.8268C13.5257 5.70896 12.5074 4.38318 12.2123 3.84434C11.66 2.83574 12.0299 1.57035 13.0385 1.01802C14.0471 0.465683 15.3125 0.835557 15.8648 1.84415Z"
        fill="white"
      />
      <path
        d="M15.8648 1.84415C16.1599 2.383 16.7286 3.95502 17.0809 5.47928C17.2566 6.23939 17.3739 6.96864 17.3802 7.53831C17.3834 7.82395 17.3583 8.05415 17.3074 8.22278C17.2561 8.39289 17.1894 8.46667 17.1341 8.49691C17.0788 8.52723 16.9808 8.54371 16.8098 8.49528C16.6403 8.44739 16.4329 8.34445 16.1939 8.18795C15.7173 7.8758 15.166 7.38424 14.6203 6.8268C13.5257 5.70896 12.5074 4.38318 12.2123 3.84434C11.66 2.83574 12.0299 1.57035 13.0385 1.01802C14.0471 0.465683 15.3125 0.835557 15.8648 1.84415Z"
        fill={`url(#${id3})`}
        fillOpacity="0.8"
      />
      <path
        d="M15.8648 1.84415C16.1599 2.383 16.7286 3.95502 17.0809 5.47928C17.2566 6.23939 17.3739 6.96864 17.3802 7.53831C17.3834 7.82395 17.3583 8.05415 17.3074 8.22278C17.2561 8.39289 17.1894 8.46667 17.1341 8.49691C17.0788 8.52723 16.9808 8.54371 16.8098 8.49528C16.6403 8.44739 16.4329 8.34445 16.1939 8.18795C15.7173 7.8758 15.166 7.38424 14.6203 6.8268C13.5257 5.70896 12.5074 4.38318 12.2123 3.84434C11.66 2.83574 12.0299 1.57035 13.0385 1.01802C14.0471 0.465683 15.3125 0.835557 15.8648 1.84415Z"
        stroke="#383838"
        strokeWidth="0.544218"
      />
      <path
        d="M21.2419 3.25836C20.7522 3.62926 19.5901 4.83095 18.6448 6.07756C18.1735 6.6992 17.768 7.3166 17.5291 7.8338C17.4094 8.09315 17.338 8.31348 17.3155 8.4881C17.2926 8.66435 17.3233 8.75889 17.3613 8.80919C17.3994 8.85949 17.4822 8.91461 17.658 8.94042C17.8322 8.966 18.0637 8.95706 18.3457 8.91205C18.9083 8.82225 19.6124 8.59928 20.3386 8.31403C21.7947 7.74198 23.2663 6.94894 23.7561 6.57804C24.6728 5.88377 24.8531 4.57782 24.1588 3.66112C23.4646 2.74441 22.1586 2.56409 21.2419 3.25836Z"
        fill="white"
      />
      <path
        d="M21.2419 3.25836C20.7522 3.62926 19.5901 4.83095 18.6448 6.07756C18.1735 6.6992 17.768 7.3166 17.5291 7.8338C17.4094 8.09315 17.338 8.31348 17.3155 8.4881C17.2926 8.66435 17.3233 8.75889 17.3613 8.80919C17.3994 8.85949 17.4822 8.91461 17.658 8.94042C17.8322 8.966 18.0637 8.95706 18.3457 8.91205C18.9083 8.82225 19.6124 8.59928 20.3386 8.31403C21.7947 7.74198 23.2663 6.94894 23.7561 6.57804C24.6728 5.88377 24.8531 4.57782 24.1588 3.66112C23.4646 2.74441 22.1586 2.56409 21.2419 3.25836Z"
        fill={`url(#${id4}`}
        fillOpacity="0.8"
      />
      <path
        d="M21.2419 3.25836C20.7522 3.62926 19.5901 4.83095 18.6448 6.07756C18.1735 6.6992 17.768 7.3166 17.5291 7.8338C17.4094 8.09315 17.338 8.31348 17.3155 8.4881C17.2926 8.66435 17.3233 8.75889 17.3613 8.80919C17.3994 8.85949 17.4822 8.91461 17.658 8.94042C17.8322 8.966 18.0637 8.95706 18.3457 8.91205C18.9083 8.82225 19.6124 8.59928 20.3386 8.31403C21.7947 7.74198 23.2663 6.94894 23.7561 6.57804C24.6728 5.88377 24.8531 4.57782 24.1588 3.66112C23.4646 2.74441 22.1586 2.56409 21.2419 3.25836Z"
        stroke="#383838"
        strokeWidth="0.544218"
      />
      <defs>
        <linearGradient
          id={id1}
          x1="16.948"
          y1="31.1658"
          x2="6.05086"
          y2="30.909"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7A95FF" stopOpacity="0.29" />
          <stop offset="1" stopColor="#3760FF" />
        </linearGradient>
        <linearGradient
          id={id2}
          x1="15.69"
          y1="28.8116"
          x2="4.79287"
          y2="28.5548"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#151C39" />
          <stop offset="1" stopColor="#4269FE" />
        </linearGradient>
        <linearGradient
          id={id3}
          x1="17.4086"
          y1="8.48565"
          x2="12.5184"
          y2="8.34763"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7A95FF" stopOpacity="0.29" />
          <stop offset="1" stopColor="#3760FF" />
        </linearGradient>
        <linearGradient
          id={id4}
          x1="24.619"
          y1="8.92285"
          x2="18.0715"
          y2="8.60842"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7A95FF" stopOpacity="0.29" />
          <stop offset="1" stopColor="#3760FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
