export const MonitorBarsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.78125 5.25C4.62592 5.25 4.5 5.37592 4.5 5.53125V14.8125C4.5 14.9678 4.62592 15.0938 4.78125 15.0938H12H19.2188C19.3741 15.0938 19.5 14.9678 19.5 14.8125V5.53125C19.5 5.37592 19.3741 5.25 19.2188 5.25H4.78125ZM3 5.53125C3 4.54748 3.7975 3.75 4.78125 3.75H19.2188C20.2025 3.75 21 4.54751 21 5.53125V14.8125C21 15.7963 20.2025 16.5938 19.2188 16.5938H12.75V18.75H16.125C16.5392 18.75 16.875 19.0858 16.875 19.5C16.875 19.9142 16.5392 20.25 16.125 20.25H12H7.875C7.46079 20.25 7.125 19.9142 7.125 19.5C7.125 19.0858 7.46079 18.75 7.875 18.75H11.25V16.5938H4.78125C3.79751 16.5938 3 15.7963 3 14.8125V5.53125ZM7.95001 9.44951C8.3228 9.44951 8.62501 9.75172 8.62501 10.1245V12.8245C8.62501 13.1973 8.3228 13.4995 7.95001 13.4995C7.57722 13.4995 7.27501 13.1973 7.27501 12.8245V10.1245C7.27501 9.75172 7.57722 9.44951 7.95001 9.44951ZM11.3251 8.32422C11.3251 7.95143 11.0229 7.64922 10.6501 7.64922C10.2773 7.64922 9.97509 7.95143 9.97509 8.32422V12.8242C9.97509 13.197 10.2773 13.4992 10.6501 13.4992C11.0229 13.4992 11.3251 13.197 11.3251 12.8242V8.32422ZM13.3501 9.44951C13.7229 9.44951 14.0251 9.75172 14.0251 10.1245V12.8245C14.0251 13.1973 13.7229 13.4995 13.3501 13.4995C12.9773 13.4995 12.6751 13.1973 12.6751 12.8245V10.1245C12.6751 9.75172 12.9773 9.44951 13.3501 9.44951ZM16.725 7.4248C16.725 7.05201 16.4228 6.7498 16.05 6.7498C15.6773 6.7498 15.375 7.05201 15.375 7.4248V12.8248C15.375 13.1976 15.6773 13.4998 16.05 13.4998C16.4228 13.4998 16.725 13.1976 16.725 12.8248V7.4248Z"
      fill="currentcolor"
    />
  </svg>
);
