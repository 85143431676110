import { useTranslations } from 'next-intl';

import { FreePairIcon } from '@/blocks/components/AssetIcon';

const mapper = (pair: string) => ({
  href: '/converters/' + pair.replace('/', '-').toLowerCase(),
  icon: <FreePairIcon pairName={pair} />,
  title: pair,
});

export function useTabsData() {
  const tr = useTranslations('menu');
  return [
    {
      title: tr('tools.submenu.3.subitems.0.title'),
      subitems: [
        'EUR/USD',
        'AUD/USD',
        'USD/CAD',
        'GBP/USD',
        'USD/EUR',
        'USD/ZAR',
        'SOL/USD',
        'USD/GBP',
        'USD/MXN',
        'CAD/USD',
        'USD/THB',
        'INR/USD',
        'USD/PKR',
        'GBP/EUR',
        'USD/PHP',
        'USD/INR',
        'PHP/USD',
        'USD/IDR',
        'USD/SGD',
        'USD/AUD',
        'LTC/USD',
        'USD/VND',
        'USD/EGP',
        'USD/MYR',
        'USD/NZD',
        'AUD/PKR',
        'GBP/INR',
      ].map(mapper),
    },
    {
      title: tr('tools.submenu.3.subitems.1.title'),
      subitems: [
        'BTC/USD',
        'ETH/USD',
        'DOGE/USD',
        'XRP/USD',
        'BCH/USD',
        'TRX/USD',
        'BTC/INR',
        'BTC/AUD',
        'BTC/EUR',
        'BTC/GBP',
        'BTC/CAD',
        'ETH/CAD',
        'TRX/INR',
        'BTC/PHP',
        'BTC/IDR',
        'ETH/GBP',
        'ETH/EUR',
        'XRP/PHP',
        'ETH/INR',
        'ETH/AUD',
        'ADA/PHP',
        'BTC/PKR',
        'TRX/PKR',
        'ETH/PHP',
        'BTC/CZK',
        'BTC/MYR',
        'ETH/IDR',
      ].map(mapper),
    },
  ];
}
