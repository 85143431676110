import { ReactNode, useContext, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';

import { Container } from '@/blocks/components/Container';
import { BurgerContext } from '@/blocks/sections/Header/Components/HeaderInner';
import context from '@/styles/contexts.module.scss';

import { MenuCtaBlock } from './CtaBlock';
import styles from './styles.module.scss';

function useStickyShadow() {
  let ctaBlockRef = useRef<HTMLDivElement | null>(null);
  let navItemsContainerRef = useRef<HTMLDivElement | null>(null);
  let commonContainerRef = useRef<HTMLDivElement | null>(null);
  const [ctaBlockShadow, setCtaBlockShadow] = useState(false);

  useEffect(() => {
    const ctaBlock = ctaBlockRef.current;
    const navItemsContainer = navItemsContainerRef.current;
    const commonContainer = commonContainerRef.current;

    if (!navItemsContainer || !ctaBlock || !commonContainer) {
      return;
    }

    const obs = new ResizeObserver(() => {
      const ctaRect = ctaBlock.getBoundingClientRect();
      const navItemsContainerRect = navItemsContainer.getBoundingClientRect();
      setCtaBlockShadow(navItemsContainerRect.bottom > ctaRect.top);
    });

    function onScrollOrResize() {
      const ctaRect = ctaBlock?.getBoundingClientRect();
      const navItemsContainerRect = navItemsContainer?.getBoundingClientRect();
      if (ctaRect && navItemsContainerRect) {
        setCtaBlockShadow(navItemsContainerRect.bottom > ctaRect.top);
      }
    }

    commonContainer.addEventListener('scroll', onScrollOrResize);
    window.addEventListener('resize', onScrollOrResize);
    obs.observe(navItemsContainer);

    return () => {
      commonContainer.removeEventListener('scroll', onScrollOrResize);
      window.removeEventListener('resize', onScrollOrResize);
      obs.disconnect();
    };
  }, []);

  return {
    commonContainerRef,
    navItemsContainerRef,
    ctaBlockRef,
    ctaBlockShadow,
  };
}

export function MenuLayout(props: {
  children: React.ReactNode;
  ctaBlockAddon?: ReactNode;
  menuUnderCtaButtonsSlot?: ReactNode;
  withoutHelpCenter?: boolean;
}) {
  const { menuOpened } = useContext(BurgerContext);
  const { commonContainerRef, navItemsContainerRef, ctaBlockRef, ctaBlockShadow } = useStickyShadow();

  return (
    <nav
      className={classNames(context.light, styles.menu, menuOpened && styles.menuOpened)}
      ref={commonContainerRef}
      data-event-category={'site_menu'}
      data-event-action={'element_click'}
    >
      <Container className={styles.menuItemsContainer} ref={navItemsContainerRef} variant={'header'}>
        {props.children}
      </Container>
      <MenuCtaBlock
        shadow={ctaBlockShadow}
        ref={ctaBlockRef}
        withoutHelpCenter={props.withoutHelpCenter}
        menuUnderCtaButtonsSlot={props.menuUnderCtaButtonsSlot}
      >
        {props.ctaBlockAddon}
      </MenuCtaBlock>
    </nav>
  );
}
