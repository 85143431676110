export const IconNestedDiamond = ({ width, height }: { width: string; height: string }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 132 132" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <rect
          id="1"
          x="2.7548"
          y="66.001"
          width="89.4453"
          height="89.4453"
          transform="rotate(-45 2.7548 66.001)"
          stroke="#555556"
          strokeWidth="2.8"
        />
        <rect
          id="3"
          x="22.5243"
          y="67.3247"
          width="63.0895"
          height="63.0895"
          transform="rotate(-45 22.5243 67.3247)"
          stroke="#555556"
          strokeWidth="2.8"
        />
        <rect
          id="5"
          x="42.287"
          y="68.6509"
          width="36.7337"
          height="36.7337"
          transform="rotate(-45 42.287 68.6509)"
          stroke="#555556"
          strokeWidth="2.8"
        />
        <rect
          id="center"
          x="59"
          y="68.3184"
          width="13.1779"
          height="13.1779"
          transform="rotate(-45 59 68.3184)"
          fill="url(#paint0_linear_8147_14470)"
          fillOpacity="0.8"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_8147_14470"
          x1="72.2462"
          y1="81.428"
          x2="60.3759"
          y2="80.9484"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7A95FF" stopOpacity="0.29" />
          <stop offset="1" stopColor="#3760FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
