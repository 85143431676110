import { Locale } from '@/i18n/i18n-config';

// https://en.wikipedia.org/wiki/IETF_language_tag
// https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/lang
// https://developer.mozilla.org/en-US/docs/Web/API/Navigator/language#value

type ISO639_1 = string;
type ISO639_2 = string;
type ISO639_3 = string;

type ISO15924 = string;
type ISO3166_1_alpha_2 = string;

type BCP47script = ISO15924;
type BCP47region = ISO3166_1_alpha_2;
type BCP47variant = string;
type BCP47extension = string;

export type BCP47Locale =
  `${ISO639_1 | ISO639_2 | ISO639_3}${BCP47script}${BCP47region}${BCP47variant}${BCP47extension}`;

// TODO understand which one of these two functions to use, they differ only in cn key

// used mainly for Help Center links, because zh-CN its a pathname for chinese help center
export const convertLangCode = (lang: Locale): BCP47Locale => {
  const languagesMap: Record<Locale, BCP47Locale> = {
    in: 'id',
    cn: 'zh-CN',
    jp: 'ja',
    kr: 'ko',
  };
  return languagesMap[lang] || lang;
};

// used mainly for SEO components and metatags
export function ourLocaleToBCP47(ourLocale: Locale): BCP47Locale {
  const map: Record<Locale, BCP47Locale> = {
    in: 'id',
    cn: 'zh-Hans',
    jp: 'ja',
    kr: 'ko',
  };
  return map[ourLocale] || ourLocale;
}
