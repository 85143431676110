'use client';

import Link from 'next/link';

import { Container } from '@/blocks/components/Container';
import { HeaderLogo } from '@/blocks/sections/Header/Components/Actions/Logo';

import styles from './styles.module.scss';

export function HeaderMini() {
  return (
    <header className={styles.headerMini}>
      <Container variant={'header'} tag={'nav'}>
        <HeaderLogo component={Link} />
      </Container>
    </header>
  );
}
