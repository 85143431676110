import React, { PropsWithChildren } from 'react';

import { DialogProps } from '@mui/material';
import { default as MuiDialog } from '@mui/material/Dialog';
import classNames from 'classnames';

import { CloseCrossButton } from '@/blocks/components/CloseCrossButton';
import { WithClassName } from '@/blocks/components/types';
import context from '@/styles/contexts.module.scss';
import typography from '@/styles/typography.module.scss';

import styles from './styles.module.scss';

export function Dialog({
  className,
  children,
  backdropBlurred,
  closeButton = false,
  ...props
}: DialogProps & { backdropBlurred?: boolean; closeButton?: boolean }) {
  return (
    <MuiDialog
      className={classNames(
        context.light,
        styles.dialog,
        backdropBlurred && styles.backdropBlurred,
        className,
      )}
      {...props}
    >
      {/*@ts-ignore*/}
      {closeButton && <CloseCrossButton className={styles.closeButton} onClick={props.onClose} />}
      {children}
    </MuiDialog>
  );
}

export function DialogHeadingWithCloseIcon({
  children,
  className,
  onClose,
}: PropsWithChildren &
  WithClassName & {
    onClose: () => void;
  }) {
  return (
    <div className={classNames(styles.modalHeadingWrapper, className)}>
      <div className={styles.modalHeading}>
        <div className={styles.spacing} />
        <p className={typography.h4}>{children}</p>
        <CloseCrossButton onClick={onClose} />
      </div>
    </div>
  );
}
