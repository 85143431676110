export const GlobeIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21 12C21 16.9706 16.9706 21 12 21M21 12C21 7.02944 16.9706 3 12 3M21 12C21 10.0118 16.9706 8.4 12 8.4C7.02944 8.4 3 10.0118 3 12M21 12C21 13.9882 16.9706 15.6 12 15.6C7.02944 15.6 3 13.9882 3 12M12 21C7.02944 21 3 16.9706 3 12M12 21C13.9882 21 15.6 16.9706 15.6 12C15.6 7.02944 13.9882 3 12 3M12 21C10.0118 21 8.4 16.9706 8.4 12C8.4 7.02944 10.0118 3 12 3M3 12C3 7.02944 7.02944 3 12 3"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
