import classNames from 'classnames';
import styles from './styles.module.scss';

export const IconPriceTrend = ({ negative, filled = false }: { filled?: boolean; negative?: boolean }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(
        styles.icon,
        negative ? styles.negative : styles.positive,
        filled && styles.filled,
      )}
    >
      <path
        d="M22 6V12M22 6H16M22 6L15 13C14.4548 13.5452 14.1821 13.8179 13.888 13.9636C13.3285 14.2409 12.6715 14.2409 12.112 13.9636C11.8179 13.8179 11.5452 13.5452 11 13V13C10.4547 12.4547 10.1821 12.1821 9.88799 12.0364C9.32844 11.7591 8.6715 11.7591 8.11195 12.0364C7.81785 12.1821 7.54522 12.4547 6.99995 13L2 17.9999"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
