export const IconCheck2 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} viewBox="0 0 25 24" fill="none">
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="m5 12.461 4.42 4.897a.75.75 0 0 0 1.133-.022L20 6"
    />
  </svg>
);
