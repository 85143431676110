import React, { HTMLAttributes } from 'react';

import classNames from 'classnames';

import { IconCross } from '@/blocks/components/Icons';

import styles from './styles.module.scss';

export function CloseCrossButton({
  className,
  size = 'L',
  ...props
}: HTMLAttributes<HTMLButtonElement> & { size?: 'L' | 'S' }) {
  return (
    <button
      className={classNames(
        styles.closeButton,
        {
          [styles.closeButtonL]: size === 'L',
          [styles.closeButtonS]: size === 'S',
        },
        className,
      )}
      {...props}
    >
      <IconCross />
    </button>
  );
}
