'use client';
import { RATINGS } from '@/global/public-settings';
import styles from './styles.module.scss';
import React, { useRef } from 'react';
import classNames from 'classnames';
import typography from '@/styles/typography.module.scss';
import yellowStar from './../img/yellowStar.svg';
import Image from 'next/image';
import { LogoSiteJabber } from '@/blocks/sections/Testimonials/components/ReviewsSlider/logos/LogoSiteJabber';
import { LogoTrustpilot } from '@/blocks/sections/Testimonials/components/ReviewsSlider/logos/LogoTrustpilot';
import { LogoFxempire } from '@/blocks/sections/Testimonials/components/ReviewsSlider/logos/LogoFxempire';
import { LogoReviews } from '@/blocks/sections/Testimonials/components/ReviewsSlider/logos/LogoReviews';
import { LogoProductHunt } from '@/blocks/sections/Testimonials/components/ReviewsSlider/logos/LogoProductHunt';
import { LogoRevine } from '@/blocks/sections/Testimonials/components/ReviewsSlider/logos/LogoRevine';
import { FormatNumber } from '@/blocks/formatters/format-number';

const data = [
  {
    icon: <LogoSiteJabber />,
    rating: RATINGS.SITEJABBER,
  },
  {
    icon: <LogoTrustpilot textColor={'#FFFFFF'} />,
    rating: RATINGS.TRUSTPILOT,
  },
  {
    icon: <LogoFxempire />,
    rating: RATINGS.FXEMPIRE,
  },
  {
    icon: <LogoReviews />,
    rating: RATINGS.REVIEWS_IO,
  },
  {
    icon: <LogoProductHunt />,
    rating: RATINGS.PRODUCTHUNT,
  },
  {
    icon: <LogoRevine />,
    rating: RATINGS.REVAIN,
  },
];

export function ReviewsSlider() {
  const rowRef = useRef<HTMLDivElement | null>(null);
  const rowWidth = rowRef?.current?.children[0].clientWidth;
  const AVG_CARD_WIDTH = 260;
  const speed = (rowWidth || AVG_CARD_WIDTH * data.length) / 60;

  const row = (
    <div className={styles.row} style={{ '--speed': speed + 's' }}>
      {data.map((el, key) => (
        <div className={classNames(styles.tickersTapeCard)} key={key}>
          <div className={styles.wrapperIcon}>{el.icon}</div>
          <div className={styles.wrapperRating}>
            <span className={typography.subheadingL}>
              <FormatNumber value={el.rating} />
            </span>
            <div className={styles.wrapperStar}>
              <Image src={yellowStar} alt={'icon'} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className={styles.tickersTape} ref={rowRef}>
      {row}
      {row}
    </div>
  );
}
