export const QuestionIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5 12C19.5 16.1421 16.1421 19.5 12 19.5C7.85786 19.5 4.5 16.1421 4.5 12C4.5 7.85786 7.85786 4.5 12 4.5C16.1421 4.5 19.5 7.85786 19.5 12ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM12 8.25C11.1406 8.25 10.5 8.81378 10.5 9.75C10.5 10.1642 10.1642 10.5 9.75 10.5C9.33579 10.5 9 10.1642 9 9.75C9 7.92479 10.3741 6.75 12 6.75C13.6259 6.75 15 7.92479 15 9.75C15 10.438 14.8191 10.9813 14.5131 11.419C14.2199 11.8385 13.8421 12.1145 13.547 12.3153C13.4751 12.3643 13.4102 12.4077 13.3513 12.4471L13.3508 12.4475L13.3508 12.4475C13.1358 12.5913 12.9995 12.6825 12.8879 12.7967C12.7913 12.8955 12.75 12.9761 12.75 13.125C12.75 13.5392 12.4142 13.875 12 13.875C11.5858 13.875 11.25 13.5392 11.25 13.125C11.25 12.5239 11.4899 12.081 11.8152 11.7482C12.048 11.5101 12.3504 11.3096 12.5769 11.1596L12.5769 11.1596C12.6224 11.1294 12.6648 11.1013 12.703 11.0753C12.9704 10.8934 13.1551 10.7436 13.2837 10.5596C13.3997 10.3937 13.5 10.1557 13.5 9.75C13.5 8.81378 12.8594 8.25 12 8.25ZM12.75 15.75C12.75 16.1642 12.4142 16.5 12 16.5C11.5858 16.5 11.25 16.1642 11.25 15.75C11.25 15.3358 11.5858 15 12 15C12.4142 15 12.75 15.3358 12.75 15.75Z"
      fill="currentcolor"
    />
  </svg>
);
