// eslint-disable-next-line react/display-name
import classNames from 'classnames';
import styles from './styles.module.scss';
import React from 'react';

type DropdownWrapperProps = {
  children: React.ReactNode;
  onMouseLeave: React.MouseEventHandler;
  scrollableSidebar: boolean;
};

function DropdownInner(props: DropdownWrapperProps) {
  return (
    <div
      className={classNames(styles.navItemDropDown, props.scrollableSidebar && styles.fixedHeight)}
      onMouseLeave={props.onMouseLeave}
    >
      {props.children}
    </div>
  );
}

// eslint-disable-next-line react/display-name
export const DropdownWrapper = React.forwardRef((props: DropdownWrapperProps, ref) => {
  return (
    <div
      // @ts-ignore
      ref={ref}
      className={classNames(styles.navItemDropDownWrapper)}
    >
      <DropdownInner {...props}>{props.children}</DropdownInner>
    </div>
  );
});
