export const IconArrowStar = () => {
  return (
    <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M60.6448 21.1756C60.2094 21.1004 59.7955 21.3924 59.7202 21.8278C59.645 22.2631 59.937 22.6771 60.3724 22.7523L60.6448 21.1756ZM17.95 36.8909L18.4883 37.4827L17.95 36.8909ZM26.3198 31.3296C26.4167 30.8985 26.1458 30.4705 25.7147 30.3736L18.69 28.7948C18.2589 28.6979 17.8309 28.9688 17.734 29.3999C17.6371 29.831 17.9081 30.259 18.3391 30.3559L24.5834 31.7593L23.18 38.0035C23.0831 38.4346 23.354 38.8626 23.7851 38.9595C24.2161 39.0564 24.6441 38.7854 24.741 38.3544L26.3198 31.3296ZM69.6078 28.4985C71.365 32.2666 69.9732 37.3064 65.8203 42.5395C61.7007 47.7307 55.0129 52.8974 46.7196 56.7646L47.3958 58.2147C55.8654 54.2652 62.7693 48.958 67.0736 43.5341C71.3447 38.1521 73.2092 32.4356 71.0579 27.8223L69.6078 28.4985ZM46.7196 56.7646C38.4263 60.6318 30.1695 62.4339 23.5448 62.2529C16.8666 62.0704 12.1113 59.8971 10.3542 56.1289L8.90406 56.8051C11.0553 61.4184 16.6328 63.6647 23.5011 63.8523C30.4228 64.0414 38.9262 62.1641 47.3958 58.2147L46.7196 56.7646ZM60.3724 22.7523C64.9887 23.5498 68.2233 25.5294 69.6078 28.4985L71.0579 27.8223C69.3613 24.1838 65.522 22.0182 60.6448 21.1756L60.3724 22.7523ZM10.3542 56.1289C9.21326 53.6823 9.39288 50.7124 10.8124 47.4684C12.2322 44.2236 14.8656 40.778 18.4883 37.4827L17.4117 36.2991C13.6782 39.6951 10.8825 43.3169 9.34659 46.827C7.81034 50.3378 7.50758 53.8103 8.90406 56.8051L10.3542 56.1289ZM18.4883 37.4827C20.655 35.5118 23.1669 33.6027 25.9672 31.8301L25.1114 30.4782C22.2389 32.2965 19.6521 34.2611 17.4117 36.2991L18.4883 37.4827Z"
        fill="#B5B5B5"
      />
      <path
        d="M60.5589 8.80005C60.5589 15.9929 54.728 21.8238 47.5352 21.8238C54.728 21.8238 60.5589 27.6547 60.5589 34.8475C60.5589 27.6547 66.3898 21.8238 73.5826 21.8238C66.3898 21.8238 60.5589 15.9929 60.5589 8.80005Z"
        fill="url(#paint0_linear_4956_18661)"
        fillOpacity="0.8"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4956_18661"
          x1="73.7176"
          y1="34.7125"
          x2="50.2548"
          y2="33.7646"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7A95FF" stopOpacity="0.29" />
          <stop offset="1" stopColor="#3760FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
