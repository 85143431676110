'use client';

import { ReactNode } from 'react';

import { Options } from '@splidejs/react-splide';

import { Slider } from '@/blocks/components/Slider';
import { useBreakpoint } from '@/hooks/useBreakpoint';

import { PromoCard } from './PromoCard';
import styles from './styles.module.scss';

export interface PromosSliderProps {
  promos: {
    title: ReactNode;
    reward: ReactNode;
    description: ReactNode;
    cta: ReactNode;
    enCta: ReactNode;
    footnote: ReactNode;
  }[];
}

export function PromosSlider({ promos }: PromosSliderProps) {
  const isMobile = useBreakpoint() === 'mobile';

  const config: Options = {
    focus: 'center',
    gap: '8px',
    padding: 16,
    breakpoints: {
      768: {
        gap: '0px',
        padding: 0,
        height: '294px',
        fixedWidth: '555px',
      },
    },
  };

  return (
    <Slider
      config={config}
      slideClassName={styles.slide}
      shadow
      dimNotActive
      isNavigation
      arrowsContainerClassName={styles.sliderArrows}
      trackContainerClassName={styles.sliderTrackContainer}
      key={isMobile ? 'mobile' : 'desktop'}
      pagination={false}
    >
      {promos.map((promo, index) => {
        return (
          <PromoCard
            key={index}
            params={{}}
            title={promo.title}
            cta={promo.cta}
            enCta={promo.enCta}
            description={promo.description}
            reward={promo.reward}
            footnote={promo.footnote}
          />
        );
      })}
    </Slider>
  );
}
