export const IconMenuChat = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 6H19C19.5523 6 20 6.44772 20 7V15C20 15.5523 19.5523 16 19 16H10.0523C8.91699 16 7.81543 16.3864 6.92887 17.0957L6 17.8387V7C6 6.44772 6.44772 6 7 6ZM4 7C4 5.34315 5.34315 4 7 4H19C20.6569 4 22 5.34315 22 7V15C22 16.6569 20.6569 18 19 18H10.0523C9.37113 18 8.7102 18.2318 8.17826 18.6574L5.6247 20.7002C4.96993 21.2241 4 20.7579 4 19.9194V7ZM26 14C26 13.4477 25.5523 13 25 13C24.4477 13 24 12.5523 24 12C24 11.4477 24.4477 11 25 11C26.6569 11 28 12.3431 28 14V27C28 27.4045 27.7564 27.7691 27.3827 27.9239C27.009 28.0787 26.5789 27.9931 26.2929 27.7071L23.5858 25H14C12.3431 25 11 23.6569 11 22V21C11 20.4477 11.4477 20 12 20C12.5523 20 13 20.4477 13 21V22C13 22.5523 13.4477 23 14 23H24C24.2652 23 24.5196 23.1054 24.7071 23.2929L26 24.5858V14Z"
        fill="currentColor"
      />
    </svg>
  );
};
