export const TrandUpIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.736 3.18057C21.0505 3.45014 21.087 3.92361 20.8174 4.23811L15.3181 10.6539C15.0712 10.9419 14.6482 11.0003 14.3326 10.7899L9.24937 7.40105L4.1661 10.7899C3.82146 11.0197 3.35581 10.9265 3.12604 10.5819C2.89628 10.2372 2.98941 9.77158 3.33405 9.54181L8.83334 5.87562C9.08527 5.70767 9.41347 5.70767 9.66539 5.87562L14.6117 9.17317L19.6785 3.26192C19.9481 2.94743 20.4215 2.911 20.736 3.18057ZM3.75008 14.9151C4.16429 14.9151 4.50008 15.2508 4.50008 15.6651V20.2478C4.50008 20.662 4.16429 20.9978 3.75008 20.9978C3.33587 20.9978 3.00008 20.662 3.00008 20.2478V15.6651C3.00008 15.2508 3.33587 14.9151 3.75008 14.9151ZM9.99947 12.9155C9.99947 12.5013 9.66368 12.1655 9.24947 12.1655C8.83526 12.1655 8.49947 12.5013 8.49947 12.9155V20.2479C8.49947 20.6621 8.83526 20.9979 9.24947 20.9979C9.66368 20.9979 9.99947 20.6621 9.99947 20.2479V12.9155ZM14.7487 14.9151C15.1629 14.9151 15.4987 15.2508 15.4987 15.6651V20.2478C15.4987 20.662 15.1629 20.9978 14.7487 20.9978C14.3345 20.9978 13.9987 20.662 13.9987 20.2478V15.6651C13.9987 15.2508 14.3345 14.9151 14.7487 14.9151ZM20.9979 11.083C20.9979 10.6688 20.6621 10.333 20.2479 10.333C19.8337 10.333 19.4979 10.6688 19.4979 11.083V20.2485C19.4979 20.6627 19.8337 20.9985 20.2479 20.9985C20.6621 20.9985 20.9979 20.6627 20.9979 20.2485V11.083Z"
      fill="currentcolor"
    />
  </svg>
);
