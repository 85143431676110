'use client';

import { ReactNode, createContext, useEffect, useState } from 'react';

import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useTranslations } from 'next-intl';
import { createPortal } from 'react-dom';

import * as Icons from '@/blocks/sections/Header/data/icons';
import { mainHeroTransition } from '@/blocks/animations/transition-utils';
import { Container } from '@/blocks/components/Container';
import { HideOnQueryParam } from '@/blocks/components/HideOnQueryParam';
import { NoScrollbarIncludingDescendants } from '@/blocks/components/NoScrollbar';
import { HeaderCommonActions } from '@/blocks/sections/Header/Components/Actions/ActionsContainer';
import { DownloadAppBanner } from '@/blocks/sections/Header/Components/DownloadAppBanner';
import { MenuLayout } from '@/blocks/sections/Header/Components/Mobmenu';
import { MenuCtaBlockMobileStoreButton } from '@/blocks/sections/Header/Components/Mobmenu/CtaBlock';
import { NavigateBack } from '@/blocks/sections/Header/Components/Mobmenu/NavigateBack';
import { CollapseItem } from '@/blocks/sections/Header/Components/Mobmenu/TopLevelItem/CollapseItem';
import { SecondNavRowItem } from '@/blocks/sections/Header/Components/SecondRow/NavItem/SecondNavRowItem';
import { SelectedProductLabel } from '@/blocks/sections/Header/Components/SecondRow/SelectedProductLabel';
import { DropdownLinkWidget } from '@/blocks/sections/Header/Components/Widgets/Link';
import { INavItem } from '@/blocks/sections/Header/data/types';
import { useHeaderScrollStates } from '@/blocks/sections/Header/hooks/useHeaderScrollStates';
import { isIncludesPage, useSelectedProduct } from '@/blocks/sections/Header/hooks/useSelectedProduct';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { useIsClient } from '@/hooks/useIsClient/useIsClient';
import { useScrollLock } from '@/hooks/useScrollLock/useScrollLock';
import { usePathname } from '@/i18n/routing';
import context from '@/styles/contexts.module.scss';
import typography from '@/styles/typography.module.scss';
import { getScroll } from '@/utils/scroll';

import { useHeroAnimationContext } from '@/app/[lang]/index/HeroAnimationProvider';

import { HeaderGiftBanner } from './Actions/GiftBanner';
import { HeaderLogo } from './Actions/Logo';
import { NavItems } from './Actions/NavItems';
import { MobmenuTopLevelCollapse, MobmenuTopLevelItem } from './Mobmenu/TopLevelItem';
import { SecondNavContainer } from './SecondRow';
import styles from './styles.module.scss';

function HeaderBody(props: { children: React.ReactNode }) {
  return <div className={styles.headerMain}>{props.children}</div>;
}

export const BurgerContext = createContext<{
  menuOpened: boolean;
  productMenuOpened: boolean;
  secondMenuLevel: boolean;
  setProductMenuOpened: (val: boolean) => void;
  setMenuOpened: (val: boolean) => void;
}>({
  menuOpened: false,
  secondMenuLevel: false,
  productMenuOpened: false,
  setMenuOpened: () => {},
  setProductMenuOpened: () => {},
});

function isNavItemArray(val: INavItem[] | INavItem): val is INavItem[] {
  return Array.isArray(val);
}

const hasSubitemsComparator = (a: INavItem, b: INavItem) =>
  // @ts-ignore
  (b.hasOwnProperty('subitems') && b.subitems?.length > 0) -
  // @ts-ignore
  (a.hasOwnProperty('subitems') && a.subitems?.length > 0);

export const HEADER_REGULAR_HEIGHT = 84;
export const HEADER_MAX_HEIGHT = 132;

export function HeaderInner({
  headerData,
  menuCtaBlockAddon,
  slotBanner = <DownloadAppBanner />,
  slotMenuUnderCtaButtons = <MenuCtaBlockMobileStoreButton />,
  slotActions = <HeaderCommonActions />,
  slotScrolledDown = <HeaderGiftBanner />,
  slotLogo = <HeaderLogo />,
  withoutHelpCenter = false,
}: {
  headerData: INavItem[];
  menuCtaBlockAddon?: ReactNode;
  slotActions?: ReactNode;
  slotBanner?: ReactNode;
  slotScrolledDown?: ReactNode;
  slotLogo?: ReactNode;
  slotMenuUnderCtaButtons?: ReactNode;
  withoutHelpCenter?: boolean;
}) {
  const { pageScrolled, pageScrolledDown } = useHeaderScrollStates();
  const breakpoint = useBreakpoint();
  const { setScrollLock } = useScrollLock(HeaderInner.name);
  const { isClient } = useIsClient();
  const { selectedProduct, selectedProductSection } = useSelectedProduct(headerData);
  const pathname = usePathname();
  const tr = useTranslations();

  const [menuOpened, setMenuOpened] = useState(false);
  const [navigationSteps, setNavigationSteps] = useState<Array<INavItem[] | INavItem>>([headerData]);
  const [productMenuOpened, setProductMenuOpened] = useState(false);
  const [clickedSecondRowNavItem, setClickedSecondRowNavItem] = useState<INavItem | null>(null);

  const isTopLevel = navigationSteps.length === 1;

  const currentStep = navigationSteps.at(-1);

  const { slideState } = useHeroAnimationContext();
  const headerIsHidden = breakpoint === 'desktop' && slideState === 'products' && getScroll().scrollY === 0;

  const MobmenuTitle = isTopLevel ? null : (
    <p className={classNames(typography.subheadingM, styles.burgerDeepSectionTitle)}>
      {(currentStep as INavItem).title}
    </p>
  );

  useEffect(() => {
    setMenuOpened(breakpoint === 'desktop' ? false : menuOpened);
    const root = document.querySelector<HTMLHtmlElement>(':root');
    const metaThemeColor = document.querySelector<HTMLMetaElement>('meta[name=theme-color]');
    root?.style.setProperty('--color-body-bg', menuOpened ? 'var(--palette-white)' : 'var(--palette-black)');
    if (metaThemeColor) {
      metaThemeColor.content = root
        ? getComputedStyle(root).getPropertyValue('--color-body-bg')
        : menuOpened
          ? '#ffffff'
          : '#000000';
    }
  }, [breakpoint, menuOpened]);

  useEffect(() => {
    if (!menuOpened) {
      setNavigationSteps([headerData]);
      setProductMenuOpened(false);
      setClickedSecondRowNavItem(null);
    }
  }, [menuOpened, headerData]);

  useEffect(() => {
    setScrollLock(menuOpened && breakpoint !== 'desktop');
  }, [breakpoint, menuOpened, setScrollLock]);

  function mobMenuNavigateBack() {
    const newStack = [...navigationSteps.slice(0, navigationSteps.length - 1)];
    setNavigationSteps(newStack);
  }

  function insertItemItselfAsHomePageIntoChildrens(navItem: INavItem) {
    return {
      ...navItem,
      subitems: [
        {
          ...navItem,
          icon: <Icons.HomeIcon />,
          title: navItem.altTitle || tr('menu.sectionHomePage'),
        },
        ...(navItem.subitems || []),
      ],
    };
  }

  function onCLickCollapseItem(subItem: INavItem) {
    if (!subItem.subitems && !subItem.widget) {
      return;
    }
    setNavigationSteps([
      ...navigationSteps,
      subItem.href ? insertItemItselfAsHomePageIntoChildrens(subItem) : subItem,
    ]);
  }

  function getComponentForBurgerItem(navItem: INavItem) {
    if (!isTopLevel) {
      return <DropdownLinkWidget key={navItem.title as string} navItem={navItem} />;
    }

    if (!navItem?.subitems?.length) {
      return <MobmenuTopLevelItem key={navItem.title as string} navItem={navItem} />;
    }

    const navSectionHomePage = navItem.href ? (
      <CollapseItem
        navItem={{
          ...navItem,
          title: navItem.altTitle || tr('menu.sectionHomePage'),
        }}
        key={navItem.title as string}
      />
    ) : null;

    return (
      <MobmenuTopLevelCollapse
        initiallyOpened={Boolean(
          // TODO idiotizm no rabotaet
          clickedSecondRowNavItem && JSON.stringify(navItem) === JSON.stringify(clickedSecondRowNavItem),
        )}
        title={navItem.title}
        key={navItem.title as string}
      >
        {navSectionHomePage}
        {navItem.subitems.map((subItem: INavItem) =>
          (subItem.subitems?.length || subItem.widget) && !navItem.hideSidebar ? (
            <CollapseItem
              navItem={{
                ...subItem,
                href: undefined,
              }}
              key={subItem.title as string}
              onClick={() => onCLickCollapseItem(subItem)}
            />
          ) : (
            <CollapseItem navItem={subItem} key={subItem.title as string} />
          ),
        )}
      </MobmenuTopLevelCollapse>
    );
  }

  const mobmenuItems = isTopLevel
    ? (currentStep as INavItem[]).sort(hasSubitemsComparator).map(getComponentForBurgerItem)
    : (currentStep as INavItem).subitems?.map(getComponentForBurgerItem);

  const mobmenuWidget = isTopLevel ? null : (currentStep as INavItem).widget;

  const HeaderLeftUpperCornerComponent = !isTopLevel ? (
    <NavigateBack onClick={mobMenuNavigateBack} />
  ) : productMenuOpened && selectedProduct ? (
    <SelectedProductLabel
      title={selectedProduct.title}
      href={selectedProduct.href}
      {...selectedProduct.subLinkProps}
    />
  ) : (
    slotLogo
  );

  function onSecondRowItemClick(e: MouseEvent, navItem: INavItem) {
    if (selectedProduct && navItem.subitems && selectedProduct.subitems && breakpoint !== 'desktop') {
      e.preventDefault();
      setMenuOpened(true);
      setNavigationSteps([selectedProduct.subitems]);
      setProductMenuOpened(true);
      setClickedSecondRowNavItem(navItem);
    }
  }

  const SecondNavRow = !selectedProduct ? null : (
    <SecondNavContainer
      product={
        <SelectedProductLabel
          title={selectedProduct.title}
          href={selectedProduct.href as string}
          {...selectedProduct.subLinkProps}
        />
      }
    >
      {selectedProduct?.subitems?.map((navItem) => {
        return (
          <SecondNavRowItem
            active={isIncludesPage(navItem.subitems || [], pathname) || navItem.href === pathname}
            navItem={navItem}
            key={navItem.title as string}
            onClick={(e) => onSecondRowItemClick(e, navItem)}
          />
        );
      })}
    </SecondNavContainer>
  );

  const headerClassname = classNames(styles.header, {
    [styles.scrolledDown]: pageScrolledDown,
    [styles.bgVisible]: pageScrolled,
    [styles.burgerOpened]: menuOpened,
    [context.light]: menuOpened,
  });

  return (
    <HideOnQueryParam param={'webview'}>
      <BurgerContext.Provider
        value={{
          menuOpened: menuOpened,
          productMenuOpened: productMenuOpened,
          secondMenuLevel: !isTopLevel,
          setMenuOpened,
          setProductMenuOpened,
        }}
      >
        <NoScrollbarIncludingDescendants>
          <motion.div
            className={styles.fixedContainer}
            variants={{
              hidden: {
                y: -200,
                opacity: 0,
                pointerEvents: 'none',
              },
              show: {
                y: 0,
                opacity: 1,
                pointerEvents: 'auto',
              },
            }}
            animate={headerIsHidden ? 'hidden' : 'show'}
            transition={mainHeroTransition}
          >
            {slotBanner}
            <header className={headerClassname} data-event-action={'element_click'}>
              <Container
                variant={'header'}
                tag={'nav'}
                data-event-category={'site_menu'}
                className={styles.headerInner}
              >
                {HeaderLeftUpperCornerComponent}
                <HeaderBody>
                  <NavItems headerData={headerData} />
                  {slotScrolledDown}
                </HeaderBody>
                {slotActions}
              </Container>
              {SecondNavRow}
              {isClient
                ? createPortal(
                    <MenuLayout
                      withoutHelpCenter={withoutHelpCenter}
                      ctaBlockAddon={menuCtaBlockAddon}
                      menuUnderCtaButtonsSlot={slotMenuUnderCtaButtons}
                    >
                      {MobmenuTitle}
                      {mobmenuItems}
                      {mobmenuWidget}
                    </MenuLayout>,
                    document.body,
                  )
                : null}
            </header>
          </motion.div>
        </NoScrollbarIncludingDescendants>
      </BurgerContext.Provider>
    </HideOnQueryParam>
  );
}
