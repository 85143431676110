export const IconHeadset = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" fill="none">
    <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}>
      <path d="M28.5 25v1a4 4 0 0 1-4 4h-7m11-14h-4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h4v-9Zm0 0a12 12 0 0 0-24 0m0 0v7a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-5a2 2 0 0 0-2-2h-4Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M.5 0h32v32H.5z" />
      </clipPath>
    </defs>
  </svg>
);
