export const LogoFxempire = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="148" height="40" viewBox="0 0 148 40" fill="none">
    <path
      d="M79.3722 9.34673H78.1225L69.3833 24.3086L60.5664 9.34673H59.3512L55.9555 29.2816H59.2909L61.3249 16.7329L68.7369 29.2816H70.021L77.3382 16.8363L79.3722 29.2816H82.7421L79.3722 9.34673Z"
      fill="white"
    />
    <path
      d="M99.8323 11.4497C98.8876 10.5202 97.6874 9.89307 96.3848 9.64844C94.6154 9.39735 92.8276 9.29931 91.0413 9.35541H85.9477V29.2817H89.3951V20.4562H90.438C92.4031 20.5179 94.3698 20.4083 96.3159 20.1287C97.6733 19.8994 98.931 19.2691 99.9271 18.3188C100.364 17.8594 100.706 17.3186 100.934 16.7271C101.161 16.1355 101.27 15.505 101.254 14.8713C101.263 14.2378 101.149 13.6085 100.918 13.0183C100.665 12.4281 100.296 11.8947 99.8323 11.4497ZM97.7294 14.8972C97.7276 15.4472 97.5426 15.9809 97.2036 16.4141C96.8251 16.8701 96.3173 17.2006 95.7471 17.3621C94.7046 17.6188 93.6309 17.7262 92.5582 17.681H89.3176V12.1651H92.765C93.8025 12.1222 94.841 12.2237 95.8505 12.4667C96.3877 12.6327 96.8604 12.9609 97.2036 13.4062C97.5378 13.8287 97.7226 14.3499 97.7294 14.8886V14.8972Z"
      fill="white"
    />
    <path d="M104.245 9.34673V29.2816H107.641V9.34673H104.245Z" fill="white" />
    <path
      d="M40.2256 9.34673V29.273H54.2137V26.4633H43.6214V20.4303H54.2998V17.6378H43.63V17.1552V12.165H54.3171V9.34673H40.2256Z"
      fill="white"
    />
    <path
      d="M130.791 9.34673V29.273H144.779V26.4633H134.186V20.4303H144.873V17.6378H134.195V17.1552V12.165H144.873V9.34673H130.791Z"
      fill="white"
    />
    <path
      d="M128.859 29.2902L121.964 19.8787L122.18 19.8356C123.103 19.5322 123.944 19.0189 124.636 18.336C125.074 17.8777 125.417 17.3371 125.645 16.7453C125.873 16.1535 125.981 15.5224 125.963 14.8885C125.981 14.257 125.867 13.6286 125.627 13.0441C125.396 12.4568 125.047 11.923 124.602 11.4755C123.66 10.538 122.459 9.90445 121.154 9.65701C119.371 9.39758 117.569 9.29377 115.767 9.34674H110.7V29.2988H114.095V20.4734H115.138C116.285 20.4734 117.276 20.4734 118.138 20.4217L124.645 29.2902H128.859ZM114.095 17.6637V12.1909H117.543C118.578 12.143 119.614 12.2446 120.62 12.4925C121.16 12.6574 121.636 12.9856 121.981 13.432C122.317 13.853 122.5 14.3758 122.499 14.9144C122.501 15.4611 122.319 15.9927 121.981 16.4226C121.618 16.8814 121.121 17.2158 120.559 17.3793C119.516 17.6308 118.443 17.7353 117.37 17.6896L114.095 17.6637Z"
      fill="white"
    />
    <path
      d="M7.14727 20.8613H19.7649L21.2732 18.9049L19.9632 17.1812H7.14727V15.7074V13.0615H16.6278L16.2313 12.3892L13.9129 9.34686H2.66559V29.3162H7.15589V22.7057L7.14727 20.8613Z"
      fill="white"
    />
    <path
      d="M34.0808 23.3693L31.1763 19.6891L28.6511 22.947L33.624 29.3334H38.7176L34.0808 23.3693Z"
      fill="white"
    />
    <path d="M38.2434 9.34673H33.1584L28.625 15.1643L31.1675 18.448L38.2434 9.34673Z" fill="white" />
    <path
      d="M25.5479 13.8801L22.0229 9.34673H16.9379L24.4706 19.0254L16.4553 29.3161H21.5747L27.0217 22.2747L29.547 19.0168L25.5479 13.8801Z"
      fill="white"
    />
  </svg>
);
