export const Clock = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1139_27324)">
      <path
        d="M12 7.5V12L15.75 14.25"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 9.75H21V6"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6625 18.0001C16.4831 19.113 15.002 19.8536 13.4041 20.1295C11.8063 20.4054 10.1625 20.2043 8.67833 19.5513C7.1941 18.8983 5.9352 17.8225 5.05893 16.4581C4.18265 15.0938 3.72785 13.5015 3.75136 11.8802C3.77486 10.2588 4.27563 8.68037 5.19109 7.34201C6.10655 6.00366 7.3961 4.96474 8.89863 4.35509C10.4012 3.74543 12.05 3.59206 13.6392 3.91415C15.2284 4.23623 16.6875 5.01949 17.834 6.16605C18.9375 7.28355 19.8487 8.3373 21 9.75011"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1139_27324">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
