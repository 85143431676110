export const IconDollar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
    <path d="M16.5 3V29" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M23.5 11C23.5 10.3434 23.3707 9.69321 23.1194 9.08658C22.8681 8.47996 22.4998 7.92876 22.0355 7.46447C21.5712 7.00017 21.02 6.63188 20.4134 6.3806C19.8068 6.12933 19.1566 6 18.5 6H14.5C13.1739 6 11.9021 6.52678 10.9645 7.46447C10.0268 8.40215 9.5 9.67392 9.5 11C9.5 12.3261 10.0268 13.5979 10.9645 14.5355C11.9021 15.4732 13.1739 16 14.5 16H19.5C20.8261 16 22.0979 16.5268 23.0355 17.4645C23.9732 18.4021 24.5 19.6739 24.5 21C24.5 22.3261 23.9732 23.5979 23.0355 24.5355C22.0979 25.4732 20.8261 26 19.5 26H13.5C12.1739 26 10.9021 25.4732 9.96447 24.5355C9.02678 23.5979 8.5 22.3261 8.5 21"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
