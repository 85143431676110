export const IconImage = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 32 32" fill="none">
    <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}>
      <path d="M26 5H6a1 1 0 0 0-1 1v20a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1Z" />
      <path d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM7.086 27l13.706-13.707a1.001 1.001 0 0 1 1.415 0L27 18.087" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);
