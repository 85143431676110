'use client';

import { useEffect } from 'react';

const FIBER_PATCHING_INTERVAL = 100;

function getIntercomDomNodes() {
  return document.querySelectorAll(
    'iframe#intercom-frame, .intercom-lightweight-app, #intercom-container, #intercom-css-container',
  );
}

export function patch(reactMarkerKey: string) {
  getIntercomDomNodes().forEach((node) => {
    // @ts-ignore
    node[reactMarkerKey] = true;
  });
}

export function getReactMarker() {
  const key = Object.keys(document.body).find((key) => key.includes('__reactFiber$'));
  const reactMarker = key?.replace('__reactFiber$', '__reactMarker$');

  if (!reactMarker) {
    console.warn('IntercomFiberPatcher: no reactMarker found');
    return;
  }

  return reactMarker;
}

export const IntercomFiberPatcher = () => {
  useEffect(() => {
    const reactMarker = getReactMarker();

    if (!reactMarker) {
      console.warn('IntercomFiberPatcher: no reactMarker found');
      return;
    }

    patch(reactMarker);

    const intervalId = setInterval(() => {
      patch(reactMarker);
    }, FIBER_PATCHING_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return '';
};
