import { ComponentPropsWithoutRef } from 'react';

import classNames from 'classnames';

import { SparklineChart } from '@/blocks/components/SparklineChart';

import styles from './../styles.module.scss';

const CellChart: React.FC<
  ComponentPropsWithoutRef<'td'> & {
    chartValues?: number[] | undefined;
    negative?: boolean;
  }
> = ({ chartValues, negative, className, ...rest }) => {
  return (
    <td className={classNames(className)} {...rest}>
      <div className={classNames(styles.cell)}>
        {chartValues ? <SparklineChart chartValues={chartValues} negative={negative} /> : null}
      </div>
    </td>
  );
};
export { CellChart };
