export function extractXDCookie(rawValue: string | undefined | null): any {
  if (!rawValue) {
    return null;
  }
  try {
    return JSON.parse(rawValue);
  } catch (e) {
    return null;
  }
}
