export const ReportsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.75 3H11.25H6.375C5.87772 3 5.40081 3.18964 5.04917 3.52721C4.69754 3.86477 4.5 4.32261 4.5 4.8V19.2C4.5 19.6774 4.69754 20.1352 5.04917 20.4728C5.40081 20.8104 5.87772 21 6.375 21H17.625C18.1223 21 18.5992 20.8104 18.9508 20.4728C19.3025 20.1352 19.5 19.6774 19.5 19.2V11.25V9.75L12.75 3ZM12.75 5.12132L17.3787 9.75H12.75V5.12132ZM18 19.2V11.25H12C11.5858 11.25 11.25 10.9142 11.25 10.5V4.5H6.375C6.25623 4.5 6.15404 4.54587 6.08797 4.60929C6.02399 4.67071 6 4.74065 6 4.8V19.2C6 19.2593 6.02399 19.3293 6.08797 19.3907C6.15404 19.4541 6.25624 19.5 6.375 19.5H17.625C17.7438 19.5 17.846 19.4541 17.912 19.3907C17.976 19.3293 18 19.2593 18 19.2ZM8.99995 14.25C8.58574 14.25 8.24995 14.5858 8.24995 15C8.24995 15.4142 8.58574 15.75 8.99995 15.75H15C15.4142 15.75 15.75 15.4142 15.75 15C15.75 14.5858 15.4142 14.25 15 14.25H8.99995Z"
      fill="currentcolor"
    />
  </svg>
);
