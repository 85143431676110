import { HTMLAttributes, PropsWithChildren, createElement } from 'react';

import classNames from 'classnames';

import styles from './CenterHeading.module.scss';

type Props = PropsWithChildren<HTMLAttributes<HTMLDivElement>> & {
  disableSpacing?: boolean;
  tag?: keyof JSX.IntrinsicElements;
};

export const CenterHeading = ({ children, className, disableSpacing, tag = 'div', ...props }: Props) => {
  return createElement(
    tag,
    {
      className: classNames(styles.centerHeading, { [styles.spacing]: !disableSpacing }, className),
      ...props,
    },
    children,
  );
};
