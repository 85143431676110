import { PropsWithChildren, ReactNode } from 'react';

import classNames from 'classnames';

import { Container } from '@/blocks/components/Container';
import { ShowIntercomBtn } from '@/blocks/components/Intercom';
import { CenterHeading } from '@/blocks/components/layout';
import { HeaderMini } from '@/blocks/sections/Header/Versions/HeaderMini';
import { Clock } from '@/blocks/sections/Header/data/icons';
import background from '@/styles/background.module.scss';
import buttons from '@/styles/button.module.scss';
import context from '@/styles/contexts.module.scss';
import links from '@/styles/link.module.scss';
import typography from '@/styles/typography.module.scss';

import styles from './styles.module.scss';

export type ErrorPageLayoutProps = {
  img: ReactNode;
  title: ReactNode;
  subtitle?: ReactNode;
  cta?: ReactNode;
};

export function CtaContainer({ children }: PropsWithChildren) {
  return <div className={styles.ctaContainer}>{children}</div>;
}

export function VerticalCtaContainer({ children }: PropsWithChildren) {
  return <div className={styles.ctaContainerVertical}>{children}</div>;
}

export function ErrorPageBaseLayout({ img, title, subtitle, cta }: ErrorPageLayoutProps) {
  return (
    <>
      <HeaderMini />
      <section className={classNames(context.dark, background.geometryBgDark, styles.section)} data-nosnippet>
        <Container>
          <div className={styles.pageContent}>
            {img}
            <CenterHeading>
              <h1 className={typography.h3}>{title}</h1>
              <p className={typography.subheadingSM}>{subtitle}</p>
              {cta}
            </CenterHeading>
          </div>
        </Container>
      </section>
    </>
  );
}

export function ErrorPageContent({ reset }: { reset: () => void }) {
  return (
    <ErrorPageBaseLayout
      title={'Oops, something went wrong!'}
      subtitle={
        <>
          We are sorry, minor issue occurred. Please{' '}
          <button onClick={reset} className={links.textLink}>
            try again
          </button>{' '}
          later, navigate to the{' '}
          <a href={'/'} className={links.textLink}>
            homepage
          </a>
          , or <ShowIntercomBtn className={links.textLink}>contact support</ShowIntercomBtn> if problem still
          exists
        </>
      }
      img={null}
      cta={
        <VerticalCtaContainer>
          <a className={classNames(buttons.btn, buttons.btnM, buttons.btnPrimary)} href={'/'}>
            Home Page
          </a>
          <button
            onClick={reset}
            className={classNames(buttons.btn, buttons.btnLink, buttons.btnM, buttons.btnIconRight)}
          >
            Try again
            <Clock />
          </button>
        </VerticalCtaContainer>
      }
    />
  );
}
