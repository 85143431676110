'use client';
import { PropsWithChildren } from 'react';
import { useSearchParams } from 'next/navigation';

type Props = PropsWithChildren & {
  param: string;
};

export const HideOnQueryParam = ({ children, param }: Props) => {
  const searchParams = useSearchParams();
  const queryParamIsExist = searchParams.has(param);

  if (queryParamIsExist) {
    return null;
  }

  return <>{children}</>;
};
