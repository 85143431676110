import { EasingDefinition } from 'framer-motion';
import { TRANSITION_EASINGS } from '@/blocks/animations/transition-utils';

interface UseTabVariantsConfig {
  duration?: number;
  ease?: EasingDefinition;
}

export const useTabContentVariants = ({
  duration = 0.5,
  ease = TRANSITION_EASINGS.easeOutCubic as EasingDefinition,
}: UseTabVariantsConfig = {}) => {
  const variants = {
    active: {
      opacity: 1,
      y: 0,
    },
    inactive: {
      opacity: 0,
      y: 10,
    },
  };

  const transition = { duration, ease };

  return {
    variants,
    transition,
  };
};
