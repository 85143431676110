export const IconArrows = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.45957 13.8558C5.26311 14.0499 4.94653 14.048 4.75248 13.8515L2.64427 11.7171C2.45022 11.5207 2.45217 11.2041 2.64863 11.01L4.78299 8.90184C4.97945 8.70778 5.29603 8.70973 5.49008 8.90619C5.68414 9.10266 5.68218 9.41923 5.48572 9.61329L4.21763 10.8658H14.0003C14.2764 10.8658 14.5003 11.0897 14.5003 11.3658C14.5003 11.642 14.2764 11.8658 14.0003 11.8658H4.19674L5.46393 13.1488C5.65799 13.3452 5.65603 13.6618 5.45957 13.8558ZM11.5404 2.1444C11.7369 1.95034 12.0535 1.95229 12.2475 2.14875L14.3557 4.28311C14.5498 4.47957 14.5478 4.79615 14.3514 4.9902L12.217 7.09841C12.0206 7.29246 11.704 7.29051 11.5099 7.09405C11.3159 6.89759 11.3178 6.58101 11.5143 6.38696L12.7824 5.1344L3.00019 5.1344C2.72405 5.1344 2.50019 4.91054 2.50019 4.6344C2.50019 4.35826 2.72405 4.1344 3.00019 4.1344L12.8033 4.1344L11.5361 2.85149C11.342 2.65503 11.344 2.33845 11.5404 2.1444Z"
      fill="currentcolor"
    />
  </svg>
);
