import React from 'react';

import classNames from 'classnames';
import Link from 'next/link';

import { ArrowRight } from '@/blocks/components/Icons';
import { INavItem } from '@/blocks/sections/Header/data/types';
import { LinkI18n } from '@/i18n/routing';
import typography from '@/styles/typography.module.scss';

import styles from './styles.module.scss';

export function CollapseItem(props: { navItem: INavItem; onClick?: (...args: any[]) => void }) {
  const Component = props.navItem.href ? (props.navItem.noLangPrefix ? Link : LinkI18n) : 'div';

  return (
    <li onClick={(e) => props.onClick && props.onClick(e)}>
      <Component
        // @ts-ignore
        href={props.navItem.href}
        className={styles.burgerMenuCollapseSectionItem}
        {...props.navItem.linkProps}
      >
        <div className={styles.summary}>
          <div className={classNames(styles.title, typography.bodyM)}>{props.navItem.title}</div>
          <div className={classNames(styles.description, typography.bodyS)}>{props.navItem.description}</div>
        </div>
        <div className={styles.arrow}>
          <ArrowRight />
        </div>
      </Component>
    </li>
  );
}
