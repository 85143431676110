import { NodeType, parse, Node } from 'node-html-parser';

function haveChildHTMLElements(element: Node) {
  return Array.from(element?.childNodes || []).some((node) => node.nodeType === NodeType.ELEMENT_NODE);
}

type TagName = string;
// @ts-ignore
type TagValue = string | JsonDom;
// @ts-ignore
type JsonDom = Record<TagName, TagValue>;

function serialize(dom: Node): JsonDom {
  return Array.from(dom.childNodes).reduce<JsonDom>((acc, node) => {
    if (node.nodeType !== NodeType.ELEMENT_NODE) {
      return acc;
    }
    const nodeName = node.rawTagName.toLowerCase();
    if (acc.hasOwnProperty(nodeName)) {
      return {
        ...acc,
        [nodeName]: [acc[nodeName], haveChildHTMLElements(node) ? serialize(node) : node.rawText],
      };
    }
    return {
      ...acc,
      [nodeName]: haveChildHTMLElements(node) ? serialize(node) : node.rawText,
    };
  }, {});
}

export const getStringHtmlTagsValues = (str: string) => {
  const dom = parse(str.replaceAll('<br></br>', '<br/>'));
  return serialize(dom);
};
