'use client';
import React from 'react';

export function NBSP() {
  return <>&nbsp;</>;
}

export function BR() {
  return <br />;
}
