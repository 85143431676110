export function IconCalculator({ width, height }: { width: string; height: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M16.5 6H7.5V10.5H16.5V6Z"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 20.25V3.75C19.5 3.33579 19.1642 3 18.75 3L5.25 3C4.83579 3 4.5 3.33579 4.5 3.75L4.5 20.25C4.5 20.6642 4.83579 21 5.25 21H18.75C19.1642 21 19.5 20.6642 19.5 20.25Z"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.32812 13.875C9.32812 14.4704 8.84543 14.9531 8.25 14.9531C7.65457 14.9531 7.17188 14.4704 7.17188 13.875C7.17188 13.2796 7.65457 12.7969 8.25 12.7969C8.84543 12.7969 9.32812 13.2796 9.32812 13.875Z"
        fill="currentcolor"
        stroke="currentcolor"
        strokeWidth="0.09375"
      />
      <path
        d="M13.0781 13.875C13.0781 14.4704 12.5954 14.9531 12 14.9531C11.4046 14.9531 10.9219 14.4704 10.9219 13.875C10.9219 13.2796 11.4046 12.7969 12 12.7969C12.5954 12.7969 13.0781 13.2796 13.0781 13.875Z"
        fill="currentcolor"
        stroke="currentcolor"
        strokeWidth="0.09375"
      />
      <path
        d="M16.8281 13.875C16.8281 14.4704 16.3454 14.9531 15.75 14.9531C15.1546 14.9531 14.6719 14.4704 14.6719 13.875C14.6719 13.2796 15.1546 12.7969 15.75 12.7969C16.3454 12.7969 16.8281 13.2796 16.8281 13.875Z"
        fill="currentcolor"
        stroke="currentcolor"
        strokeWidth="0.09375"
      />
      <path
        d="M9.32812 17.625C9.32812 18.2204 8.84543 18.7031 8.25 18.7031C7.65457 18.7031 7.17188 18.2204 7.17188 17.625C7.17188 17.0296 7.65457 16.5469 8.25 16.5469C8.84543 16.5469 9.32812 17.0296 9.32812 17.625Z"
        fill="currentcolor"
        stroke="currentcolor"
        strokeWidth="0.09375"
      />
      <path
        d="M13.0781 17.625C13.0781 18.2204 12.5954 18.7031 12 18.7031C11.4046 18.7031 10.9219 18.2204 10.9219 17.625C10.9219 17.0296 11.4046 16.5469 12 16.5469C12.5954 16.5469 13.0781 17.0296 13.0781 17.625Z"
        fill="currentcolor"
        stroke="currentcolor"
        strokeWidth="0.09375"
      />
      <path
        d="M15.75 18.75C16.3713 18.75 16.875 18.2463 16.875 17.625C16.875 17.0037 16.3713 16.5 15.75 16.5C15.1287 16.5 14.625 17.0037 14.625 17.625C14.625 18.2463 15.1287 18.75 15.75 18.75Z"
        fill="currentcolor"
      />
    </svg>
  );
}
