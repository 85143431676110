'use client';

import { PropsWithChildren, ReactNode, forwardRef } from 'react';

import classNames from 'classnames';
import { useTranslations } from 'next-intl';

import { Container } from '@/blocks/components/Container';
import { LinkSignin, LinkSignup } from '@/blocks/components/PlatformLink';
import { APKButton, AppStoreButton, GooglePlayButton } from '@/blocks/components/StoreButton';
import { HelpCenter } from '@/blocks/sections/HelpCenter';
import { useDeviceDetect } from '@/hooks/useDeviceDetect';
import buttons from '@/styles/button.module.scss';

import styles from './styles.module.scss';

export function MenuCtaBlockMobileStoreButton() {
  const { isIOS, isAndroid } = useDeviceDetect();

  return (
    <div className={styles.storeButtonWrapper}>
      {isIOS && <AppStoreButton className={styles.storeButton} location={'inside_top_menu'} />}
      {isAndroid && (
        <>
          <GooglePlayButton className={styles.storeButton} location={'inside_top_menu'} />
          <APKButton className={styles.storeButton} location={'inside_top_menu'} />
        </>
      )}
    </div>
  );
}

function CtaButtons({ children }: PropsWithChildren) {
  const tr = useTranslations('menu');
  return (
    <>
      <div className={styles.ctaButtons}>
        <LinkSignin
          className={classNames(buttons.btn, buttons.btnXS, buttons.btnFilled)}
          data-event-name={'site_menu_login_click'}
          data-event-subcategory={'login'}
        >
          {tr('btn.login')}
        </LinkSignin>
        <LinkSignup
          className={classNames(buttons.btn, buttons.btnXS, buttons.btnPrimary)}
          data-event-name={'site_menu_register_click'}
          data-event-subcategory={'register'}
          params={{ 'init-product': null }}
        >
          {tr('btn.register')}
        </LinkSignup>
      </div>
      {children}
    </>
  );
}

export const MenuCtaBlock = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{
    shadow?: boolean;
    menuUnderCtaButtonsSlot?: ReactNode;
    withoutHelpCenter?: boolean;
  }>
>(function MenuCtaBlock({ children, shadow, menuUnderCtaButtonsSlot, withoutHelpCenter }, ref) {
  return (
    <>
      <div className={classNames(styles.ctaBlockContainer, styles.sticky, shadow && styles.shadow)} ref={ref}>
        <Container variant={'header'}>
          <CtaButtons>{menuUnderCtaButtonsSlot}</CtaButtons>
          {withoutHelpCenter ? null : <HelpCenter className={classNames(styles.helpCenterPlate)} />}
        </Container>
      </div>
      {children ? (
        <div className={classNames(styles.ctaAddonContainer)}>
          <Container variant={'header'}>{children}</Container>
        </div>
      ) : null}
    </>
  );
});
