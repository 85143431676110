'use client';

import { useRef } from 'react';

import { motion } from 'framer-motion';

import { logoPath } from '@/blocks/sections/Footer/components/FooterBottomBigLogo/dataLogoPath';
import { Gradient } from '@/blocks/sections/Footer/components/FooterBottomBigLogo/gradientLogo';

import styles from './styles.module.scss';

export default function FooterBottomBigLogo() {
  const ref = useRef<HTMLDivElement>(null);

  const svgVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const createPathVariants = (delay = 0) => ({
    hidden: { y: -100, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.4, delay, ease: 'easeInOut' },
    },
  });

  return (
    <div ref={ref} style={{ overflow: 'hidden' }} className={styles.bottomLgLogoBlock}>
      <motion.svg
        viewBox="0 0 996 147"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        initial="hidden"
        whileInView="visible"
        variants={svgVariants}
      >
        {logoPath.map((el, key) => (
          <motion.path key={key} d={el.path} fill={el.fill} variants={createPathVariants(el.delay)} />
        ))}
        <Gradient />
      </motion.svg>
    </div>
  );
}
