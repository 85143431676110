import { hasSidebar } from '@/blocks/sections/Header/Components/Dropdown/hasSidebar';
import { DropdownLinkWidget } from '@/blocks/sections/Header/Components/Widgets/Link';
import { INavItem } from '@/blocks/sections/Header/data/types';
import classNames from 'classnames';
import styles from './styles.module.scss';

export function DropdownSubitems(props: { visible: boolean; dropdownItem: INavItem }) {
  if (!hasSidebar(props.dropdownItem)) {
    return null;
  }
  return (
    <div
      className={classNames(styles.dropdownSidebarItem, props.visible && styles.dropdownSidebarItemVisible)}
    >
      {!props.dropdownItem?.subitems?.length ? null : (
        <ul className={styles.dropdownSidebarItemsGroup}>
          {props.dropdownItem?.subitems.map((i, index) => <DropdownLinkWidget navItem={i} key={index} />)}
        </ul>
      )}
      {!props.dropdownItem.widget ? null : props.dropdownItem.widget}
    </div>
  );
}
