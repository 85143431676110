import React from 'react';

import { useLocale } from 'next-intl';

import { convertLangCode } from '@/utils/string/lang';

type FormatNumberProps = {
  value: number;
  options?: Intl.NumberFormatOptions;
};

export function useFormatter(options?: Intl.NumberFormatOptions) {
  const locale = useLocale();
  return new Intl.NumberFormat(convertLangCode(locale === 'ar' ? 'en' : locale), options);
}

export const FormatNumber = ({ value, options }: FormatNumberProps) => {
  const formatter = useFormatter({ minimumFractionDigits: 0, ...options });
  return <>{formatter.format(value)}</>;
};
