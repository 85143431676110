export const FiveIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_480_44854)">
      <path
        d="M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 7.5H10.5L9.75 12C9.9962 11.7555 10.2891 11.5632 10.6112 11.4343C10.9334 11.3055 11.2781 11.2428 11.625 11.25C12.3212 11.25 12.9889 11.5266 13.4812 12.0188C13.9734 12.5111 14.25 13.1788 14.25 13.875C14.25 14.5712 13.9734 15.2389 13.4812 15.7312C12.9889 16.2234 12.3212 16.5 11.625 16.5C11.2781 16.5072 10.9334 16.4445 10.6112 16.3157C10.2891 16.1868 9.9962 15.9945 9.75 15.75"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_480_44854">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
