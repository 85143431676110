import { useEffect, useRef, useState } from 'react';

import { Fade, Tooltip, TooltipProps } from '@mui/material';
import classNames from 'classnames';
import { useLocale } from 'next-intl';

import { ChevronDown } from '@/blocks/components/Icons/Chevron';
import { LangPopup } from '@/blocks/sections/Header/Components/Actions/ActionLang/LangPopup';
import { useHeaderScrollStates } from '@/blocks/sections/Header/hooks/useHeaderScrollStates';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { i18n } from '@/i18n/i18n-config';
import buttons from '@/styles/button.module.scss';

import styles from './styles.module.scss';

const commonProps: Partial<TooltipProps> = {
  arrow: true,
  enterTouchDelay: 0,
  TransitionComponent: Fade,
  TransitionProps: {
    timeout: {
      enter: 0,
      exit: 80,
    },
  },
  leaveDelay: 200,
  classes: {
    popper: styles.tooltip,
  },
};

function HoverTooltip() {
  const locale = useLocale();
  const langCode = locale.toUpperCase();
  const [popupOpened, setPopupOpened] = useState(false);

  return (
    <Tooltip
      {...commonProps}
      title={<LangPopup />}
      onOpen={() => setPopupOpened(true)}
      onClose={() => setPopupOpened(false)}
      PopperProps={{
        disablePortal: true,
        keepMounted: true,
      }}
    >
      <button
        className={classNames(
          buttons.btn,
          buttons.btnLink,
          buttons.btnIconRight,
          buttons.btnS,
          styles.button,
          popupOpened && styles.opened,
        )}
      >
        {langCode}
        {<ChevronDown />}
      </button>
    </Tooltip>
  );
}

function ClickTooltip() {
  const [popupOpened, setPopupOpened] = useState(false);
  const { pageScrolledDown } = useHeaderScrollStates();
  const locale = useLocale();
  const langCode = locale.toUpperCase();
  const breakpoint = useBreakpoint();
  const popupTriggerRef = useRef<HTMLButtonElement | null>(null);
  const popupRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setPopupOpened(false);
  }, [breakpoint, pageScrolledDown]);

  useEffect(() => {
    function onClick(e: any) {
      if (!popupTriggerRef?.current?.contains(e.target) && !popupRef?.current?.contains(e.target)) {
        setPopupOpened(false);
      }
    }
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, []);

  return (
    <Tooltip
      {...commonProps}
      title={<LangPopup ref={popupRef} />}
      onClose={() => setPopupOpened(false)}
      open={popupOpened}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      PopperProps={{
        disablePortal: true,
        keepMounted: true,
      }}
    >
      <button
        onClick={() => setPopupOpened(!popupOpened)}
        className={classNames(
          buttons.btn,
          buttons.btnLink,
          buttons.btnIconRight,
          buttons.btnS,
          styles.button,
          popupOpened && styles.opened,
        )}
        ref={popupTriggerRef}
      >
        {langCode}
        <ChevronDown />
      </button>
    </Tooltip>
  );
}

export function ActionLang() {
  const bp = useBreakpoint();
  if (i18n.locales.length <= 1) {
    return null;
  }
  return bp && bp === 'desktop' ? <HoverTooltip /> : <ClickTooltip />;
}
