'use client';

import { PropsWithChildren, useEffect } from 'react';

import { usePathname } from '@/i18n/routing';

export const queryParamStorage = [
  { name: 'am', api: 'AM' },
  { name: 'bta', api: 'BTA' },
];

export function WithQueryParamStorage({ children }: PropsWithChildren) {
  const location = usePathname();
  useEffect(() => {
    queryParamStorage.forEach((queryParam) => {
      const val = new URLSearchParams(window.location.search).get(queryParam.name);
      if (val) {
        localStorage.setItem(queryParam.name, val);
      }
    });
  }, [location]);
  return children;
}
