export const MarketResearchIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6025 5.36761C18.102 5.05538 18.75 5.41451 18.75 6.0036V11.2283V15.6972C18.75 16.2862 18.1021 16.6454 17.6025 16.3332L12.2243 12.9721C11.9859 12.8231 11.7104 12.7441 11.4293 12.7441H5.25V8.95718H11.4293C11.7104 8.95718 11.9859 8.87817 12.2243 8.72915L17.6025 5.36761ZM20.25 6.0036C20.25 4.23634 18.3061 3.15895 16.8074 4.09563L11.4293 7.45718H4.875C4.25368 7.45718 3.75 7.96086 3.75 8.58218V13.1191C3.75 13.7404 4.25368 14.2441 4.875 14.2441H5.23628L4.54053 17.7228C4.45152 18.1679 4.70785 18.6096 5.13845 18.7532L9.36421 20.1618C9.85031 20.3238 10.3734 20.0479 10.5142 19.5552L11.9404 14.5635L16.8076 17.6052C18.3062 18.5418 20.25 17.4644 20.25 15.6972V11.2283V6.0036ZM10.4716 14.2441H6.75013C6.75046 14.2944 6.74569 14.3456 6.73544 14.3969L6.11528 17.4976L9.24405 18.5406L10.4716 14.2441Z"
      fill="currentcolor"
    />
  </svg>
);
