export const IconCircle = () => {
  return (
    <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="40.626" cy="39.9998" r="36" stroke="#B5B5B5" strokeWidth="1.6" />
      <circle
        cx="40.6263"
        cy="39.9999"
        r="23.35"
        fill="url(#paint0_linear_4956_18633)"
        fillOpacity="0.8"
        stroke="#B5B5B5"
        strokeWidth="1.6"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4956_18633"
          x1="65.0266"
          y1="63.8996"
          x2="21.5195"
          y2="62.1418"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7A95FF" stopOpacity="0.29" />
          <stop offset="1" stopColor="#3760FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
