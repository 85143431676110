export const IconCovMyStrategies = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={33} height={32} viewBox="0 0 33 32">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m12.25 12.8 1.1-4.17a3.8 3.8 0 0 1 .32-.59l.03-.02c.03 0 .1-.02.24-.02h4.62c.14 0 .21.02.24.02l.03.02a3.8 3.8 0 0 1 .32.6l1.1 4.17-.02.17a.5.5 0 0 1-.03 0c-.12.02-.26.02-.49.02H12.8l-.49-.01h-.03l-.02-.18Zm1.7-6.8a2.3 2.3 0 0 0-1.62.56 3.67 3.67 0 0 0-.9 1.5l-1.15 4.37a1 1 0 0 0-.03.26c0 .8.22 1.58.94 2 .31.19.64.26.89.28.24.03.49.03.69.03h6.96c.2 0 .45 0 .7-.03.24-.02.57-.09.88-.27.72-.43.94-1.2.94-2.01a1 1 0 0 0-.03-.26l-1.16-4.37a3.67 3.67 0 0 0-.89-1.5A2.3 2.3 0 0 0 18.56 6h-4.62Zm-7.6 13.63-1.1 4.18.02.17h.03c.12.02.26.02.49.02h6.92l.49-.01h.03l.02-.18-1.1-4.18a3.8 3.8 0 0 0-.32-.59l-.03-.02a.84.84 0 0 0-.24-.02H6.94a.84.84 0 0 0-.24.02l-.03.02a3.81 3.81 0 0 0-.32.6Zm-1.02-2.07A2.29 2.29 0 0 1 6.94 17h4.62c.58 0 1.14.13 1.61.56a3.67 3.67 0 0 1 .9 1.5l1.15 4.37a1 1 0 0 1 .03.26c0 .8-.22 1.58-.94 2a2.2 2.2 0 0 1-.89.28c-.24.03-.49.03-.69.03H5.77c-.2 0-.45 0-.7-.03a2.2 2.2 0 0 1-.88-.27c-.72-.43-.94-1.2-.94-2.01a1 1 0 0 1 .03-.26l1.16-4.37a3.67 3.67 0 0 1 .89-1.5Zm15.02 2.07-1.1 4.18.02.17h.03c.12.02.26.02.49.02h6.92a4.73 4.73 0 0 0 .52-.02l.02-.17-1.1-4.18a3.8 3.8 0 0 0-.32-.59l-.03-.02a.84.84 0 0 0-.24-.02h-4.62a.84.84 0 0 0-.24.02l-.03.02a3.8 3.8 0 0 0-.32.6Zm-1.02-2.07a2.29 2.29 0 0 1 1.61-.56h4.62c.58 0 1.14.13 1.61.56a3.67 3.67 0 0 1 .9 1.5l1.15 4.37a1 1 0 0 1 .03.26c0 .8-.22 1.58-.94 2a2.2 2.2 0 0 1-.89.28c-.24.03-.49.03-.69.03h-6.96c-.2 0-.45 0-.7-.03a2.2 2.2 0 0 1-.88-.27c-.72-.43-.94-1.2-.94-2.01a1 1 0 0 1 .03-.26l1.16-4.37a3.67 3.67 0 0 1 .89-1.5Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M26.25 4.5c0 1.66-1.12 3-2.5 3 1.38 0 2.5 1.34 2.5 3 0-1.66 1.12-3 2.5-3-1.38 0-2.5-1.34-2.5-3Zm-20 0c0 1.66-1.12 3-2.5 3 1.38 0 2.5 1.34 2.5 3 0-1.66 1.12-3 2.5-3-1.38 0-2.5-1.34-2.5-3Z"
    />
  </svg>
);
