export const PortfolioIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 8.25H5.25C4.42157 8.25 3.75 8.92157 3.75 9.75V18.75C3.75 19.5784 4.42157 20.25 5.25 20.25H9M9 8.25V20.25M9 8.25H15M9 8.25V5.25C9 4.42157 9.67157 3.75 10.5 3.75H13.5C14.3284 3.75 15 4.42157 15 5.25V8.25M9 20.25H15M15 8.25H18.75C19.5784 8.25 20.25 8.92157 20.25 9.75V18.75C20.25 19.5784 19.5784 20.25 18.75 20.25H15M15 8.25V20.25"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
