export const IconFilter = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 25" fill="none">
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M20.25 4.404H3.75l6 9v5.25l4.5 2.25v-7.5l6-9Z"
    />
  </svg>
);
