import React, { ComponentPropsWithoutRef, ComponentPropsWithRef, forwardRef } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { ArrowLeft, ArrowRight } from '@/blocks/components/Icons';
import { ChevronLeft, ChevronRight } from '@/blocks/components/Icons/Chevron';

const arrowVariants = {
  arrow: styles.arrow,
  chevron: styles.chevron,
};

const arrowIcons = {
  arrow: { prev: <ArrowLeft />, next: <ArrowRight /> },
  chevron: { prev: <ChevronLeft />, next: <ChevronRight /> },
};

interface SlideArrowButtonProps extends ComponentPropsWithRef<'button'> {
  variant?: keyof typeof arrowVariants;
  direction: 'prev' | 'next';
}

type SlidePrevNextProps = Omit<SlideArrowButtonProps, 'direction'>;

// eslint-disable-next-line react/display-name
const SlideArrowButton = forwardRef<HTMLButtonElement, SlideArrowButtonProps>((props, ref) => {
  const { className, variant = 'arrow', direction, ...rest } = props;

  return (
    <button ref={ref} className={classNames(styles.arrowButton, arrowVariants[variant], className)} {...rest}>
      {arrowIcons[variant][direction]}
    </button>
  );
});

export const SlideNext = forwardRef<HTMLButtonElement, SlidePrevNextProps>((props, ref) => (
  <SlideArrowButton ref={ref} {...props} direction="next" />
));

SlideNext.displayName = 'SlideNext';

export const SlidePrev = forwardRef<HTMLButtonElement, SlidePrevNextProps>((props, ref) => (
  <SlideArrowButton ref={ref} {...props} direction="prev" />
));

SlidePrev.displayName = 'SlideNext';

interface SliderArrowsProps extends ComponentPropsWithoutRef<'div'> {
  arrowsContainerClassName?: string;
}

export function SliderArrows({ arrowsContainerClassName }: SliderArrowsProps) {
  return (
    <div className={classNames(styles.sliderArrows, 'splide__arrows')}>
      <div className={classNames(styles.sliderArrowsContainer, arrowsContainerClassName)}>
        <SlidePrev variant={'chevron'} className={'splide__arrow splide__arrow--prev'} />
        <SlideNext variant={'chevron'} className={'splide__arrow splide__arrow--next'} />
      </div>
    </div>
  );
}
