// @ts-nocheck

export class MiniChartCreator {
  padding = 1;
  path;

  chartHeight = 26;
  chartWidth = 105;
  maxE;
  minE;
  pointInterval;

  constructor(width, height) {
    if (width) {
      this.chartWidth = width;
    }
    if (height) {
      this.chartHeight = height;
    }
  }

  getViewBox() {
    return `0 0 ${this.chartWidth} ${this.chartHeight}`;
  }

  getPath(values) {
    if (!values.length) {
      return;
    }

    this.setE(values);
    this.pointInterval = this.getPointInterval(values.length);
    return this.buildPath(values);
  }

  setE(values) {
    const exs = this.findExtremum(values || []);

    this.maxE = exs.maxE;
    this.minE = exs.minE;

    if (this.maxE < 0) {
      this.maxE = 0;
    }
    if (this.minE > 0) {
      this.minE = 0;
    }
  }

  toY(value) {
    if (this.maxE === 0 && this.minE === 0 && value === 0) {
      return this.chartHeight - this.padding;
    }

    if (this.maxE === this.minE) {
      return this.chartHeight - this.padding;
    }

    return (
      this.padding + ((this.maxE - value) / (this.maxE - this.minE)) * (this.chartHeight - this.padding * 2)
    );
  }

  findExtremum(values) {
    let minE;
    let maxE;

    for (const v of values) {
      if (minE === undefined || minE > v) {
        minE = v;
      }
      if (maxE === undefined || maxE < v) {
        maxE = v;
      }
    }

    return { minE: minE, maxE: maxE };
  }

  getPointInterval(pointsCount = 1) {
    if (pointsCount <= 2) return this.chartWidth - this.padding * 2;

    return (this.chartWidth - this.padding * 2) / (pointsCount - 1);
  }

  buildPath(data) {
    const y0 = this.toY(0); // 0 is profit barrier

    let path = `M${this.padding} ${y0}`;

    let x, y;
    for (let i = 0; i < data.length; i++) {
      x = this.padding + this.pointInterval * i;
      y = this.toY(data[i]);
      path += ` L ${x} ${y}`;
    }

    if (data.length === 1) {
      path += ` L ${this.chartWidth} ${y}`;
    } else {
      // hack. according to design
      // add additional line out of border
      // it makes to possible of drawing fill & stroke with a single path
      path += ` L ${x * 1.5} ${y}`;
      path += ` L ${x * 1.5} ${y0}`;
    }

    return path;
  }
}
