import classNames from 'classnames';
import { useTranslations } from 'next-intl';

import { LinkSignup } from '@/blocks/components/PlatformLink';
import { GiftIcon } from '@/blocks/sections/Header/data/icons';
import buttons from '@/styles/button.module.scss';

import styles from './styles.module.scss';

export function ActionSignupPromoButton() {
  const tr = useTranslations();

  return (
    <LinkSignup
      className={classNames(
        buttons.btn,
        buttons.btnPrimary,
        buttons.btnXS,
        buttons.btnIconLeft,
        styles.actionSignupButton,
      )}
      data-event-name={'site_menu_register_click'}
      data-event-subcategory={'register'}
      params={{ 'init-product': null }}
    >
      <GiftIcon />
      {tr.rich('sections.button.register')}
    </LinkSignup>
  );
}

export function ActionSignupButton() {
  const tr = useTranslations();

  return (
    <LinkSignup
      data-event-name={'site_menu_register_click'}
      data-event-subcategory={'register'}
      className={classNames(buttons.btn, buttons.btnPrimary, buttons.btnXS, styles.actionSignupButton)}
      params={{ 'init-product': null }}
    >
      {tr.rich('sections.button.register')}
    </LinkSignup>
  );
}
