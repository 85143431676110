import { WithClassName } from '@/blocks/components/types';
import { partnersSiteDomain } from '@/global/public-settings';
import classNames from 'classnames';
import Link from 'next/link';
import { PropsWithChildren, useEffect, useState } from 'react';

export function usePartnersLink() {
  const [domain, setDomain] = useState(partnersSiteDomain);

  useEffect(() => {
    setDomain(typeof window !== 'undefined' ? window.location.hostname : partnersSiteDomain);
  }, []);

  return {
    login: `https://link.${domain}/login/`,
    signupApi: `https://link.${domain}/api/registeraffiliate/`,
    // adminLogin: `https://link.${partnersSiteDomain}/adminlogin/`,
    // signinApi: `https://link.${domain}/api/loginaffiliate/`,
  };
}

export function PartnersSigninLink({ children, className }: Omit<PropsWithChildren<WithClassName>, 'href'>) {
  const { login } = usePartnersLink();
  return (
    <Link href={login} className={classNames(className)}>
      {children}
    </Link>
  );
}
