export const DiscountIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 10.5C13.6569 10.5 15 9.15685 15 7.5C15 5.84315 13.6569 4.5 12 4.5C10.3431 4.5 9 5.84315 9 7.5C9 9.15685 10.3431 10.5 12 10.5ZM12 12C14.4853 12 16.5 9.98528 16.5 7.5C16.5 5.01472 14.4853 3 12 3C9.51472 3 7.5 5.01472 7.5 7.5C7.5 9.98528 9.51472 12 12 12ZM7.5 15.75C5.84315 15.75 4.5 17.0931 4.5 18.75V20.25C4.5 20.6642 4.16421 21 3.75 21C3.33579 21 3 20.6642 3 20.25V18.75C3 16.2647 5.01472 14.25 7.5 14.25H11.625C12.0392 14.25 12.375 14.5858 12.375 15C12.375 15.4142 12.0392 15.75 11.625 15.75H7.5ZM20.7617 15.5483C21.0646 15.2657 21.0809 14.7911 20.7983 14.4883C20.5157 14.1855 20.0411 14.1691 19.7383 14.4517L14.1133 19.7017C13.8105 19.9843 13.7941 20.4589 14.0767 20.7617C14.3593 21.0646 14.8339 21.0809 15.1367 20.7983L20.7617 15.5483ZM15 16.5C15.6213 16.5 16.125 15.9963 16.125 15.375C16.125 14.7537 15.6213 14.25 15 14.25C14.3787 14.25 13.875 14.7537 13.875 15.375C13.875 15.9963 14.3787 16.5 15 16.5ZM21 19.875C21 20.4963 20.4963 21 19.875 21C19.2537 21 18.75 20.4963 18.75 19.875C18.75 19.2537 19.2537 18.75 19.875 18.75C20.4963 18.75 21 19.2537 21 19.875Z"
      fill="currentcolor"
    />
  </svg>
);
