export const IconLogIn = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.827 5C18.827 4.44772 19.2747 4 19.827 4H24.5002C26.7093 4 28.5002 5.79086 28.5002 8L28.5002 24C28.5002 26.2091 26.7093 28 24.5002 28H19.827C19.2747 28 18.827 27.5523 18.827 27C18.827 26.4477 19.2747 26 19.827 26H24.5002C25.6048 26 26.5002 25.1046 26.5002 24L26.5002 8C26.5002 6.89543 25.6048 6 24.5002 6H19.827C19.2747 6 18.827 5.55228 18.827 5ZM4.50191 16.0005C4.50191 15.4482 4.94962 15.0005 5.50191 15.0005L18.0543 15.0005L13.5573 10.605C13.1623 10.219 13.1551 9.58586 13.5411 9.1909C13.9272 8.79595 14.5603 8.78872 14.9553 9.17476L21.2073 15.2857C21.3998 15.4738 21.5083 15.7316 21.5083 16.0008C21.5083 16.27 21.3998 16.5278 21.2073 16.7159L14.9553 22.8268C14.5603 23.2129 13.9272 23.2056 13.5411 22.8107C13.1551 22.4157 13.1623 21.7826 13.5573 21.3966L18.0549 17.0005L5.50191 17.0005C4.94962 17.0005 4.50191 16.5528 4.50191 16.0005Z"
      fill="currentColor"
    />
  </svg>
);
