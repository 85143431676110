import { LogoProps } from '@/blocks/components/Logo/index';

export const LogoShortChristmas = (props: LogoProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="20" fill="none" viewBox="0 0 36 20" {...props}>
    <path
      fill="#072D5A"
      d="M11.806 7.778a12.956 12.956 0 0 0-.896.89 16.299 16.299 0 0 0-.936 1.112l-.168.218c-.33.433-.63.85-.915 1.235a.5.5 0 0 1-.06.067l-.005.006a.446.446 0 0 1-.06.045v.002s1.732-.009 4.002-2.647c2.215-2.576 4.613-3.848 4.613-3.848-2.535.67-4.276 1.75-5.575 2.92Z"
    />
    <path
      fill="currentcolor"
      d="M4.928 13.928a1.928 1.928 0 1 0 0-3.856 1.928 1.928 0 0 0 0 3.856ZM29.47 4h3.307L22.254 20h-3.35L29.47 4ZM23.518 11.233 18.979 4h3.6l1.78 3.18a2.91 2.91 0 0 1-.166 3.106c-.222.313-.45.631-.674.948ZM28.15 12.75 32.703 20h-3.6l-1.791-3.197a2.906 2.906 0 0 1 .166-3.104l.674-.95ZM19.497 6.648a4.967 4.967 0 0 0-2.023-1.95C16.6 4.233 15.562 4 14.359 4H7.766v6.064h1.93a1.925 1.925 0 0 0 1.924-1.925V7.126h1.9c.588 0 1.08.098 1.477.295.398.197.698.477.903.838.203.362.306.793.306 1.294 0 .5-.102.912-.306 1.277a2.1 2.1 0 0 1-.903.854c-.397.204-.886.306-1.465.306H9.715a1.95 1.95 0 0 0-1.95 1.95V20h3.855v-4.96h2.631c1.218 0 2.273-.228 3.168-.683.895-.455 1.584-1.093 2.067-1.916.483-.824.725-1.786.725-2.89 0-1.102-.238-2.07-.714-2.903Z"
    />
    <path
      fill="#EBF6FF"
      d="M15.164 2.293c-1.035.268-5.762 2.518-7.857 5.413-.032.34.062.682.356.999.572.616 1.348.434 1.992.033.693-.43 1.243-1.031 1.846-1.572.904-.81 1.889-1.514 2.986-2.039.63-.302 1.291-.518 1.968-.688.316-.08.64-.16.966-.205-.32-.831-1.404-2.161-2.257-1.94Zm.123 1.855c-2.293.835-3.083 1.714-3.77 2.107-.686.393-.862-.573-.612-.816 1.842-1.79 3.136-2.159 3.825-2.176.69-.017.74.818.556.885Z"
    />
    <path
      fill="#B2D8F6"
      d="M14.487 5.127c-1.097.526-2.082 1.23-2.986 2.04-.603.54-1.153 1.141-1.846 1.572-.644.4-1.42.583-1.992-.034-.294-.317-.388-.66-.356-.999a6.916 6.916 0 0 0-.222.324l-.003.006a6.554 6.554 0 0 0-.097.155l-.007.012c-.03.049-.058.098-.086.147l-.012.021-.074.138-.016.03a5.277 5.277 0 0 0-.065.132l-.018.037-.058.128-.018.041a4.672 4.672 0 0 0-.068.169.46.46 0 0 0-.015.225c.008.058.023.122.045.189.032.1.08.21.138.324.058.114.128.233.207.351a4.501 4.501 0 0 0 .353.467c.162.186.336.358.512.496.07.055.14.104.21.147.069.043.137.08.204.108.235.099.45.101.609-.047a.537.537 0 0 0 .065-.073c.285-.385.584-.802.915-1.235a25.817 25.817 0 0 1 .521-.66 16.315 16.315 0 0 1 1.012-1.117c.15-.149.305-.296.467-.443 1.299-1.17 3.04-2.25 5.575-2.92a.169.169 0 0 0 .047-.02l.005-.004a.152.152 0 0 0 .04-.039.193.193 0 0 0 .021-.042l.003-.009a.288.288 0 0 0 .013-.054v-.005a.45.45 0 0 0 .003-.062v-.01a.719.719 0 0 0-.006-.07l-.003-.016a1.044 1.044 0 0 0-.015-.075l-.004-.019a1.502 1.502 0 0 0-.023-.083l-.005-.015a2.103 2.103 0 0 0-.03-.084l-.005-.015-.001-.002a8.99 8.99 0 0 0-.966.205c-.677.17-1.337.386-1.968.688Z"
    />
    <path
      fill="#2575D2"
      d="M7.302 3.036c.59 1.562.64-.522 4.279-1.505C12.699 1.228 7.824.23 6.23.982c-.237.112-.317.268-.17.405 0 0 .652.088 1.242 1.65Z"
    />
    <path
      fill="#fff"
      d="M14.73 3.263c-.69.017-1.983.386-3.825 2.176-.25.243-.074 1.209.613.816.686-.393 1.476-1.272 3.768-2.107.185-.067.133-.902-.556-.885Z"
    />
    <path
      fill="#0A4994"
      d="M14.092 1.633c-.869.158-4.72.89-6.731 4.65-.813 1.521-1.26-4.219-2.774-1.919a7.127 7.127 0 0 0-1.131 3.292c.056 2.272.717 3.203.717 3.203l.76-.244a4.816 4.816 0 0 1-.164-.493c-.385-1.393-.575-2.878.113-4.149.09-.166.184-.327.282-.484.086-.138.298-.085.31.078.141 1.895 1.089 3.479 1.089 3.479.021-.057.044-.113.068-.169l.018-.04.058-.129.018-.038c.021-.043.042-.087.065-.13l.016-.031.074-.138.012-.022a6.05 6.05 0 0 1 .086-.146l.007-.013c.031-.052.064-.103.097-.155l.003-.005.106-.16.001-.001c2.033-2.973 6.918-5.303 7.972-5.576 0 0-.183-.212-1.072-.66Z"
    />
    <path
      fill="#155BAE"
      d="M10.735.437a5.559 5.559 0 0 1-.81-.177C7.495-.457 5.55.492 5.042.915c-.353.293-2.1 3.777-2.04 6.829 0 0 .004 2.06.81 3.45l.361-.335s-.25-.664-.457-1.541a6.343 6.343 0 0 1 .87-4.954c1.516-2.3 1.962 3.44 2.775 1.92 2.01-3.762 5.862-4.493 6.73-4.651-.836-.458-2.067-1.017-3.356-1.196Zm.846 1.093c-3.638.984-3.688 3.068-4.279 1.506-.59-1.562-1.242-1.65-1.242-1.65-.147-.136-.067-.293.17-.404 1.594-.752 6.47.246 5.35.548Z"
    />
    <path
      fill="#EBF6FF"
      d="M5.569 10.179a1.97 1.97 0 0 0-.32-.093c-.03-.006-.061-.01-.093-.015l-.072-.01a1.903 1.903 0 0 0-.301-.01l-.02.002c-.19.01-.376.05-.55.115a1.846 1.846 0 0 0-1.148 1.26c-.214.8.117 1.63.764 2.1.328.024.667-.036.993-.198.975-.482 1.505-1.844.987-2.879a2.416 2.416 0 0 0-.13-.226l-.021-.01a2.02 2.02 0 0 0-.09-.036Zm-.412 1.091c-.726.485-.844.675-.836.93.01.257.09.817-.462.686-.355-.084-.748-1.333-.036-2.002.71-.67 1.314-.333 1.386-.274.072.06.269.446-.052.66Z"
    />
    <path
      fill="#B2D8F6"
      d="M4.822 13.33a1.904 1.904 0 0 1-.993.198h-.004l.045.03a2.233 2.233 0 0 0 .159.098l.062.034c.036.019.074.036.112.053.02.008.037.017.056.024a1.9 1.9 0 0 0 1.797-.186 1.844 1.844 0 0 0 .78-1.236l.007-.06a1.92 1.92 0 0 0 .014-.124l.002-.074a1.897 1.897 0 0 0 0-.11c0-.026-.003-.051-.004-.077a1.94 1.94 0 0 0-.06-.362 2.046 2.046 0 0 0-.09-.26 2.005 2.005 0 0 0-.077-.162c-.014-.026-.027-.052-.042-.077-.015-.026-.032-.051-.049-.077l-.049-.075c-.017-.024-.036-.046-.054-.07-.02-.024-.037-.049-.057-.072-.017-.02-.035-.037-.053-.056a2.02 2.02 0 0 0-.144-.14c-.019-.017-.037-.035-.057-.05-.026-.022-.054-.042-.081-.062l-.066-.048-.086-.053c-.024-.014-.047-.03-.071-.042-.03-.017-.06-.031-.091-.046-.017-.008-.033-.018-.05-.025l.001.002c.047.073.09.148.13.226.518 1.035-.012 2.398-.987 2.88Z"
    />
    <path
      fill="#fff"
      d="M3.823 10.884c-.712.67-.319 1.918.036 2.002.552.131.471-.43.462-.685-.008-.256.11-.446.836-.93.32-.215.124-.6.052-.66-.072-.06-.675-.396-1.386.273Z"
    />
  </svg>
);
