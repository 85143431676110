export const TradesIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2501 9C15.4927 9 16.5001 7.99264 16.5001 6.75C16.5001 5.50736 15.4927 4.5 14.2501 4.5C13.0074 4.5 12.0001 5.50736 12.0001 6.75C12.0001 7.99264 13.0074 9 14.2501 9ZM14.2501 10.5C16.3211 10.5 18.0001 8.82107 18.0001 6.75C18.0001 4.67893 16.3211 3 14.2501 3C12.179 3 10.5001 4.67893 10.5001 6.75C10.5001 8.82107 12.179 10.5 14.2501 10.5ZM11.2509 12C10.8367 12 10.5009 12.3358 10.5009 12.75C10.5009 13.1642 10.8367 13.5 11.2509 13.5H15.7509C17.822 13.5 19.5009 15.1789 19.5009 17.25V20.25C19.5009 20.6642 19.8367 21 20.2509 21C20.6651 21 21.0009 20.6642 21.0009 20.25V17.25C21.0009 14.3505 18.6504 12 15.7509 12H11.2509ZM4.50136 13.4977C4.50139 13.0835 4.16562 12.7477 3.7514 12.7476C3.33719 12.7476 3.00139 13.0834 3.00136 13.4976L3.00098 20.25C3.00095 20.6642 3.33672 21 3.75093 21C4.16515 21 4.50095 20.6643 4.50098 20.25L4.50136 13.4977ZM8.25006 7.5C8.25006 7.08579 7.91427 6.75 7.50006 6.75C7.08585 6.75 6.75006 7.08579 6.75006 7.5L6.75006 20.25C6.75006 20.6642 7.08585 21 7.50006 21C7.91428 21 8.25006 20.6642 8.25006 20.25L8.25006 7.5Z"
      fill="currentcolor"
    />
  </svg>
);
