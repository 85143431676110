import { EasingDefinition, Transition } from 'framer-motion';

export const TRANSITION_EASINGS = {
  // ease: [0.25, 0.1, 0.25, 1],
  // easeIn: [0.4, 0, 1, 1],
  // easeOut: [0, 0, 0.2, 1],
  // easeInOut: [0.4, 0, 0.2, 1],
  easeOutCubic: [0.17, 0.55, 0.55, 1],
  easeInCubic: [0.55, 0.055, 0.675, 0.19],
} as const;

const _spring: Transition = {
  damping: 70,
  mass: 1,
  stiffness: 400,
  type: 'spring',
  // duration: 0.3, // duration and bounce will be overridden if stiffness, damping or mass are set. https://www.framer.com/motion/transition/#spring
};

export const mainHeroTransition: Transition = _spring;
