export const OpenBookIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.38462 4.125C4.01739 4.125 3.66521 4.27088 3.40554 4.53054C3.14588 4.79021 3 5.14239 3 5.50962V16.4139C3 16.7811 3.14588 17.1333 3.40554 17.3929C3.66522 17.6526 4.01741 17.7985 4.38462 17.7985H9.46154C9.93587 17.7985 10.3908 17.9869 10.7262 18.3223C10.7894 18.3855 10.8397 18.4348 10.8841 18.4783C10.9391 18.5321 10.9852 18.5772 11.0356 18.6291C11.115 18.7108 11.1567 18.7615 11.1827 18.8005C11.2115 18.8437 11.25 18.9058 11.25 19.125C11.25 19.5392 11.5858 19.875 12 19.875C12.4142 19.875 12.75 19.5392 12.75 19.125C12.75 18.9058 12.7885 18.8436 12.8174 18.8003C12.8434 18.7613 12.8851 18.7105 12.9644 18.6289C13.0149 18.5769 13.061 18.5318 13.1161 18.4778L13.1161 18.4778L13.1162 18.4778C13.1606 18.4343 13.2108 18.3851 13.2738 18.3221C13.6092 17.9867 14.0641 17.7983 14.5385 17.7983H19.6154C19.9826 17.7983 20.3348 17.6524 20.5945 17.3927C20.8541 17.1331 21 16.7809 21 16.4137V5.50962C21 5.14239 20.8541 4.79021 20.5945 4.53054C20.3348 4.27088 19.9826 4.125 19.6154 4.125H14.5385C13.6663 4.125 12.8299 4.47146 12.2132 5.08817C12.1381 5.16328 12.067 5.24165 12 5.32295C11.933 5.24165 11.8619 5.16328 11.7868 5.08817C11.1701 4.47146 10.3337 4.125 9.46154 4.125H4.38462ZM12.75 7.41346C12.75 6.93913 12.9384 6.48423 13.2738 6.14883C13.6092 5.81343 14.0641 5.625 14.5385 5.625H19.5V16.2983H14.5385C13.8989 16.2983 13.2786 16.4846 12.75 16.8271V7.41346ZM11.25 7.41346C11.25 6.93913 11.0616 6.48423 10.7262 6.14883C10.3908 5.81343 9.93587 5.625 9.46154 5.625H4.5V16.2985H9.46154C10.1011 16.2985 10.7214 16.4848 11.25 16.8273V7.41346Z"
      fill="currentcolor"
    />
  </svg>
);
