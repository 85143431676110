import React, { ElementType, ReactNode } from 'react';

import classNames from 'classnames';

import { AnimatedText } from '@/blocks/animations/animated-text';
import { IconCurvedArrow } from '@/blocks/components/Icons';
import { LinkSignup } from '@/blocks/components/PlatformLink';
import { VStack } from '@/blocks/components/layout';
import { GenericCtaProps } from '@/blocks/components/types';
import buttons from '@/styles/button.module.scss';
import typography from '@/styles/typography.module.scss';

import styles from './styles.module.scss';

export function UspCta<T extends ElementType>({
  children,
  // @ts-ignore
  Component = LinkSignup,
  className,
  ...props
}: GenericCtaProps<T>) {
  const ComponentElement = Component as ElementType;
  return (
    <ComponentElement
      className={classNames(buttons.btn, buttons.btnPrimary, buttons.btnM, className)}
      {...props}
    >
      {children}
    </ComponentElement>
  );
}

export type UspHeaderProps = {
  title: ReactNode;
  subtitle: ReactNode;
  cta?: ReactNode;
  children: ReactNode;
};

export const UspHeader = ({ title, subtitle, children }: UspHeaderProps) => {
  return (
    <VStack>
      {/* Header */}
      <div className={styles.headerWrapper}>
        <div className={styles.header}>
          <AnimatedText el={'h2'} className={typography.h2}>
            {title}
          </AnimatedText>
          <p className={classNames(typography.subheadingSM, typography.headerSubhead)}>{subtitle}</p>
        </div>
        <div className={styles.tablet}>
          <IconCurvedArrow animated={true} />
        </div>
      </div>
      {/* Tabs & Content slot*/}
      {children}
    </VStack>
  );
};
