import rtl from '@/styles/rtl.module.scss';

export const ArrowRight = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={rtl.dirDependent}
  >
    <path
      d="M4.5 11.25C4.08579 11.25 3.75 11.5858 3.75 12C3.75 12.4142 4.08579 12.75 4.5 12.75V11.25ZM20.0303 12.5303C20.3232 12.2374 20.3232 11.7626 20.0303 11.4697L15.2574 6.6967C14.9645 6.40381 14.4896 6.40381 14.1967 6.6967C13.9038 6.98959 13.9038 7.46447 14.1967 7.75736L18.4393 12L14.1967 16.2426C13.9038 16.5355 13.9038 17.0104 14.1967 17.3033C14.4896 17.5962 14.9645 17.5962 15.2574 17.3033L20.0303 12.5303ZM4.5 12.75H19.5V11.25H4.5V12.75Z"
      fill="currentcolor"
    />
  </svg>
);

export const ArrowLeft = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={rtl.dirDependent}
  >
    <path
      d="M19.5 11.25C19.9142 11.25 20.25 11.5858 20.25 12C20.25 12.4142 19.9142 12.75 19.5 12.75V11.25ZM3.96967 12.5303C3.67678 12.2374 3.67678 11.7626 3.96967 11.4697L8.74264 6.6967C9.03553 6.40381 9.51041 6.40381 9.8033 6.6967C10.0962 6.98959 10.0962 7.46447 9.8033 7.75736L5.56066 12L9.8033 16.2426C10.0962 16.5355 10.0962 17.0104 9.8033 17.3033C9.51041 17.5962 9.03553 17.5962 8.74264 17.3033L3.96967 12.5303ZM19.5 12.75H4.5V11.25H19.5V12.75Z"
      fill="currentcolor"
    />
  </svg>
);

export const ArrowUp = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 26C15 26.5523 15.4477 27 16 27C16.5523 27 17 26.5523 17 26L15 26ZM16.7071 5.29289C16.3166 4.90237 15.6834 4.90237 15.2929 5.29289L8.92893 11.6569C8.53841 12.0474 8.53841 12.6805 8.92893 13.0711C9.31946 13.4616 9.95262 13.4616 10.3431 13.0711L16 7.41421L21.6569 13.0711C22.0474 13.4616 22.6805 13.4616 23.0711 13.0711C23.4616 12.6805 23.4616 12.0474 23.0711 11.6569L16.7071 5.29289ZM17 26L17 6L15 6L15 26L17 26Z"
      fill="currentcolor"
    />
  </svg>
);

export const ArrowDown = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.6667 6C15.6667 5.44772 16.1144 5 16.6667 5C17.2189 5 17.6667 5.44772 17.6667 6L15.6667 6ZM17.3738 26.7071C16.9832 27.0976 16.3501 27.0976 15.9595 26.7071L9.59559 20.3431C9.20506 19.9526 9.20506 19.3195 9.59559 18.9289C9.98611 18.5384 10.6193 18.5384 11.0098 18.9289L16.6667 24.5858L22.3235 18.9289C22.714 18.5384 23.3472 18.5384 23.7377 18.9289C24.1282 19.3195 24.1282 19.9526 23.7377 20.3431L17.3738 26.7071ZM17.6667 6L17.6667 26L15.6667 26L15.6667 6L17.6667 6Z"
      fill="currentcolor"
    />
  </svg>
);

export const IconArrowSwitch = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.89427 4.95957C2.70022 4.76311 2.70217 4.44653 2.89863 4.25248L5.03299 2.14427C5.22945 1.95022 5.54603 1.95217 5.74008 2.14863L7.84829 4.28299C8.04234 4.47945 8.04039 4.79603 7.84393 4.99008C7.64747 5.18414 7.33089 5.18218 7.13683 4.98572L5.88428 3.71763V13.5003C5.88428 13.7764 5.66042 14.0003 5.38428 14.0003C5.10814 14.0003 4.88428 13.7764 4.88428 13.5003V3.69674L3.60137 4.96393C3.40491 5.15799 3.08833 5.15603 2.89427 4.95957ZM14.6057 11.0404C14.7998 11.2369 14.7978 11.5535 14.6014 11.7475L12.467 13.8557C12.2706 14.0498 11.954 14.0478 11.7599 13.8514L9.65171 11.717C9.45766 11.5206 9.45961 11.204 9.65607 11.0099C9.85253 10.8159 10.1691 10.8178 10.3632 11.0143L11.6157 12.2824L11.6157 2.50019C11.6157 2.22405 11.8396 2.00019 12.1157 2.00019C12.3919 2.00019 12.6157 2.22405 12.6157 2.50019L12.6157 12.3033L13.8986 11.0361C14.0951 10.842 14.4117 10.844 14.6057 11.0404Z"
      fill="currentcolor"
    />
  </svg>
);

export const ArrowUpRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    className={rtl.dirDependent}
  >
    <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" clipPath="url(#a)">
      <path d="m4 12 8-8M5.5 4H12v6.5" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
