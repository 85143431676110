export function Gradient() {
  return (
    <defs>
      <linearGradient
        id="paint0_linear_1897_6239"
        x1="722.232"
        y1="0.120422"
        x2="722.232"
        y2="146.143"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#686868" />
        <stop offset="1" stopColor="#2F2F2F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1897_6239"
        x1="685.853"
        y1="0.120422"
        x2="685.853"
        y2="66.1422"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#686868" />
        <stop offset="1" stopColor="#2F2F2F" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1897_6239"
        x1="758.567"
        y1="79.9709"
        x2="758.567"
        y2="146.142"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#686868" />
        <stop offset="1" stopColor="#2F2F2F" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1897_6239"
        x1="847.616"
        y1="0.121582"
        x2="847.616"
        y2="146.158"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#686868" />
        <stop offset="1" stopColor="#2F2F2F" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1897_6239"
        x1="948.319"
        y1="0"
        x2="948.319"
        y2="146.022"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#686868" />
        <stop offset="1" stopColor="#2F2F2F" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1897_6239"
        x1="224.678"
        y1="0.120422"
        x2="224.678"
        y2="146.15"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#686868" />
        <stop offset="1" stopColor="#2F2F2F" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1897_6239"
        x1="309.868"
        y1="0.120422"
        x2="309.868"
        y2="146.143"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#686868" />
        <stop offset="1" stopColor="#2F2F2F" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1897_6239"
        x1="574.779"
        y1="0.120422"
        x2="574.779"
        y2="146.143"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#686868" />
        <stop offset="1" stopColor="#2F2F2F" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_1897_6239"
        x1="100.278"
        y1="0.120422"
        x2="100.278"
        y2="146.143"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#686868" />
        <stop offset="1" stopColor="#2F2F2F" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_1897_6239"
        x1="391.745"
        y1="0.120422"
        x2="391.745"
        y2="146.143"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#686868" />
        <stop offset="1" stopColor="#2F2F2F" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_1897_6239"
        x1="469.463"
        y1="0.120422"
        x2="469.463"
        y2="146.136"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#686868" />
        <stop offset="1" stopColor="#2F2F2F" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_1897_6239"
        x1="17.594"
        y1="55.5337"
        x2="17.594"
        y2="90.7218"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#686868" />
        <stop offset="1" stopColor="#2F2F2F" />
      </linearGradient>
      <clipPath id="clip0_1897_6239">
        <rect width="995.875" height="146.183" fill="white" />
      </clipPath>
    </defs>
  );
}
