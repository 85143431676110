export const UserStarIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 7.5C15 9.15685 13.6569 10.5 12 10.5C10.3431 10.5 9 9.15685 9 7.5C9 5.84315 10.3431 4.5 12 4.5C13.6569 4.5 15 5.84315 15 7.5ZM16.5 7.5C16.5 9.98528 14.4853 12 12 12C9.51472 12 7.5 9.98528 7.5 7.5C7.5 5.01472 9.51472 3 12 3C14.4853 3 16.5 5.01472 16.5 7.5ZM4.5 18.75C4.5 17.0931 5.84315 15.75 7.5 15.75H12.375C12.7892 15.75 13.125 15.4142 13.125 15C13.125 14.5858 12.7892 14.25 12.375 14.25H7.5C5.01472 14.25 3 16.2647 3 18.75V20.25C3 20.6642 3.33579 21 3.75 21C4.16421 21 4.5 20.6642 4.5 20.25V18.75Z"
      fill="currentcolor"
    />
    <path
      d="M17.625 14.625C17.625 16.2819 16.2819 17.625 14.625 17.625C16.2819 17.625 17.625 18.9681 17.625 20.625C17.625 18.9681 18.9681 17.625 20.625 17.625C18.9681 17.625 17.625 16.2819 17.625 14.625Z"
      fill="currentcolor"
      stroke="currentcolor"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
