'use client';

import { motion } from 'framer-motion';

import rtl from '@/styles/rtl.module.scss';

const getArrowVariants = (appearDirection: 'horizontal' | 'vertical') => ({
  hidden: {
    clipPath: appearDirection === 'horizontal' ? 'inset(0 100% 0 0)' : 'inset(0 0 100% 0)',
    opacity: 0,
  },
  visible: {
    clipPath: 'inset(0 0 0 0)',
    opacity: 1,
    transition: {
      easing: 'easeInOut',
      duration: 0.9,
      delay: 0,
    },
  },
});

export const IconCurvedArrow = ({ animated = true }: { animated?: boolean }) => {
  return (
    // framer animation should be on wrapper, not svg because of rtl.dirDependent
    <motion.span
      initial={'hidden'}
      whileInView={'visible'}
      viewport={{ once: false }}
      variants={animated ? getArrowVariants('vertical') : {}}
    >
      <svg
        width="78"
        height="61"
        viewBox="0 0 78 61"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={rtl.dirDependent}
      >
        <path
          d="M10.09 54.143c.945-.602 1.462-.946 2.064-1.204 1.462-.774 2.839-1.634 4.301-2.409a7.438 7.438 0 0 1 1.979-.516c.774-.086 1.548.258 1.806 1.032.258.86-.172 1.463-.86 1.893-2.409 1.635-4.818 3.441-7.226 5.075a21.46 21.46 0 0 1-3.011 1.807c-3.183 1.72-5.42.86-6.194-2.667-1.032-4.903-1.72-9.893-2.494-14.796-.172-.946.086-2.064.344-3.01.172-.86.689-1.463 1.893-1.377 1.032 1.979 1.892 4.043 2.408 6.28.516 2.064 1.032 4.215 1.892 6.28.43-1.033.86-2.151 1.377-3.184 1.463-3.096 2.84-6.28 4.56-9.204 3.527-6.194 8.946-9.29 16-9.72 3.786-.173 7.57.344 11.27 1.032l4.903.774c3.957.516 7.656-.258 11.183-1.979 5.678-2.752 10.065-6.968 12.99-12.559 2.065-3.957 3.613-8.172 4.732-12.473.172-.603.086-1.205.258-1.807.172-.43.516-1.118.86-1.204.43-.086 1.29.086 1.549.516.43.516.602 1.376.602 2.065 0 1.29-.258 2.58-.517 3.87-1.72 7.399-4.646 14.28-10.323 19.614-4.302 4.043-9.205 6.968-14.969 8.172-3.613.774-7.226.258-10.839-.344-2.236-.344-4.473-.86-6.71-1.118-1.978-.172-3.956-.086-5.935.086-3.527.344-6.452 1.978-8.689 4.731-1.032 1.204-1.892 2.495-2.666 3.871-1.98 3.613-3.872 7.312-5.85 10.925-.259.344-.345.774-.689 1.548Z"
          fill={'currentColor'}
        />
      </svg>
    </motion.span>
  );
};

export const IconCurvedArrowRight = ({ animated = true }: { animated?: boolean }) => {
  return (
    // framer animation should be on wrapper, not svg because of rtl.dirDependent
    <motion.span
      initial={'hidden'}
      whileInView={'visible'}
      viewport={{ once: false }}
      variants={animated ? getArrowVariants('horizontal') : {}}
    >
      <svg
        width="113"
        height="114"
        viewBox="0 0 113 114"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={rtl.dirDependent}
      >
        <g clipPath="url(#clip0_5289_236633)">
          <path
            d="M93.4442 58.6667C92.3358 58.4953 91.7209 58.4136 91.098 58.2104C89.4881 57.8279 87.8861 57.5668 86.2762 57.1843C85.6533 56.9812 85.0226 56.6567 84.4485 56.2675C83.8098 55.8216 83.4548 55.0523 83.7714 54.3002C84.1448 53.4834 84.8652 53.3144 85.6662 53.4449C88.5548 53.8049 91.5568 54.0355 94.4454 54.3954C95.6106 54.5022 96.7837 54.7303 97.9001 55.0231C101.427 55.829 102.541 57.9506 100.797 61.1123C98.3392 65.4788 95.5658 69.6831 92.9139 73.8793C92.4192 74.7041 91.4876 75.3745 90.6695 75.9156C89.9728 76.4487 89.1877 76.561 88.3391 75.702C88.8681 73.534 89.5831 71.4149 90.6702 69.3933C91.6439 67.501 92.6743 65.5441 93.3893 63.4249C92.3851 63.9171 91.3242 64.4741 90.2554 64.9096C87.1137 66.273 83.9799 67.7578 80.7576 68.8216C74.0214 71.1512 67.9051 69.9049 62.319 65.5762C59.3603 63.2093 56.8554 60.3251 54.5288 57.3684C53.4703 56.0965 52.4119 54.8246 51.3535 53.5527C48.7194 50.5551 45.4282 48.6975 41.6423 47.6647C35.5589 45.9896 29.481 46.2647 23.5946 48.5388C19.4329 50.1516 15.4889 52.2989 11.8115 54.7944C11.285 55.1336 10.9525 55.643 10.4261 55.9821C10.0131 56.192 9.30052 56.4823 8.98513 56.3201C8.60508 56.1011 8.07191 55.4044 8.16159 54.9109C8.17868 54.2393 8.6167 53.4792 9.07058 52.9619C9.92161 51.992 10.9666 51.1923 12.0117 50.3926C18.1842 45.9663 24.9216 42.7223 32.707 42.4576C38.6067 42.2551 44.2216 43.2903 49.3484 46.1861C52.5749 47.9869 54.9504 50.7576 57.2691 53.593C58.7235 55.3267 60.0643 57.1897 61.5754 58.8587C62.9492 60.2928 64.4932 61.533 66.094 62.7085C68.9721 64.7759 72.2487 65.4762 75.7455 64.8821C77.3157 64.6576 78.8134 64.255 80.3032 63.731C84.1733 62.3201 88.0355 60.7877 91.9057 59.3768C92.3266 59.2883 92.675 59.0218 93.4442 58.6667Z"
            fill={'currentColor'}
          />
        </g>
        <defs>
          <clipPath id="clip0_5289_236633">
            <rect
              width="80.0046"
              height="80.0015"
              fill={'currentColor'}
              transform="matrix(0.751707 0.659497 -0.659534 0.751675 52.7637 0.551025)"
            />
          </clipPath>
        </defs>
      </svg>
    </motion.span>
  );
};
