import { ComponentPropsWithoutRef } from 'react';

import styles from './styles.module.scss';

function CellHeading({ children, ...rest }: ComponentPropsWithoutRef<'td'>) {
  return (
    <th {...rest}>
      <div className={styles.cellHeading}>{children}</div>
    </th>
  );
}

export { CellHeading };
