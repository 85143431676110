'use client';
import React, { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';

type Position = DOMRect | null;

type Context = {
  position: Position;
  setPosition: {
    (ref: React.RefObject<any>): void;
    (): void;
  };
};

const BlockViewportPositionContext = createContext<Context | undefined>(undefined);

export function BlockViewportPositionProvider({ children }: PropsWithChildren) {
  const [position, setPositionState] = useState<Position>(null);

  const setPosition = useCallback((arg?: React.RefObject<any>) => {
    if (arg && arg.current) {
      setPositionState(arg.current.getBoundingClientRect());
    } else {
      setPositionState(null);
    }
  }, []);

  const contextValue = useMemo(
    () => ({
      position,
      setPosition,
    }),
    [position, setPosition],
  );

  return (
    <BlockViewportPositionContext.Provider value={contextValue}>
      {children}
    </BlockViewportPositionContext.Provider>
  );
}

export const useBlockViewportPosition = () => {
  const context = useContext(BlockViewportPositionContext);
  if (!context) {
    throw new Error('useBlockViewportPosition must be used within a BlockViewportPositionProvider');
  }
  return context;
};
