export const AwardIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 16.5001L6 22.0001C7.98192 21.6698 9.98451 21.2208 12 21.2208C14.0155 21.2208 16.0181 21.6698 18 22.0001L17 16.5001M12 18.0001C7.58173 18.0001 4 14.4184 4 10.0001C4 5.58181 7.58172 2.00009 12 2.00009C16.4183 2.00009 20 5.58181 20 10.0001C20 14.4184 16.4183 18.0001 12 18.0001Z"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
