export const IconRhombus = () => {
  return (
    <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="4.73489"
        y="40.0002"
        width="51.1116"
        height="51.1116"
        transform="rotate(-45 4.73489 40.0002)"
        stroke="#B5B5B5"
        strokeWidth="1.6"
      />
      <rect
        x="16.0318"
        y="40.7571"
        width="36.0511"
        height="36.0511"
        transform="rotate(-45 16.0318 40.7571)"
        stroke="#B5B5B5"
        strokeWidth="1.6"
      />
      <rect
        x="27.3247"
        y="41.5144"
        width="20.9907"
        height="20.9907"
        transform="rotate(-45 27.3247 41.5144)"
        stroke="#B5B5B5"
        strokeWidth="1.6"
      />
      <rect
        x="36.875"
        y="41.3247"
        width="7.53023"
        height="7.53023"
        transform="rotate(-45 36.875 41.3247)"
        fill="url(#paint0_linear_4956_18642)"
        fillOpacity="0.8"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4956_18642"
          x1="44.4442"
          y1="48.8159"
          x2="37.6613"
          y2="48.5419"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7A95FF" stopOpacity="0.29" />
          <stop offset="1" stopColor="#3760FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
