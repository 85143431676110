export const ConverterIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.25C11.5858 1.25 11.25 1.58579 11.25 2V5.33333C11.25 5.74755 11.5858 6.08333 12 6.08333C12.4142 6.08333 12.75 5.74755 12.75 5.33333V2.77997C17.5079 3.16173 21.25 7.14386 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12C2.75 9.44548 3.78452 7.134 5.45926 5.45926C5.75216 5.16637 5.75216 4.6915 5.45926 4.3986C5.16637 4.10571 4.6915 4.10571 4.3986 4.3986C2.45406 6.34315 1.25 9.03168 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM13.5606 8.49827C13.8357 8.18869 13.8079 7.71463 13.4983 7.43944C13.1887 7.16425 12.7146 7.19214 12.4394 7.50173L8.43944 12.0017C8.24323 12.2225 8.19483 12.5378 8.31582 12.8072C8.43681 13.0767 8.70466 13.25 9 13.25H13.3299L10.4394 16.5017C10.1643 16.8113 10.1921 17.2854 10.5017 17.5606C10.8113 17.8357 11.2854 17.8079 11.5606 17.4983L15.5606 12.9983C15.7568 12.7775 15.8052 12.4622 15.6842 12.1928C15.5632 11.9233 15.2953 11.75 15 11.75H10.6701L13.5606 8.49827Z"
      fill="currentcolor"
    />
  </svg>
);
