export const CalendarIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 3.75C9 3.33579 8.66421 3 8.25 3C7.83579 3 7.5 3.33579 7.5 3.75V4.5H5C3.89543 4.5 3 5.32081 3 6.33333V9.75V11.25V19.1667C3 20.1792 3.89543 21 5 21H19C20.1046 21 21 20.1792 21 19.1667V11.25V9.75V6.33333C21 5.32081 20.1046 4.5 19 4.5H16.5V3.75C16.5 3.33579 16.1642 3 15.75 3C15.3358 3 15 3.33579 15 3.75V4.5H9V3.75ZM15 6H9V6.75C9 7.16421 8.66421 7.5 8.25 7.5C7.83579 7.5 7.5 7.16421 7.5 6.75V6H5C4.82064 6 4.68292 6.06612 4.59937 6.1427C4.518 6.21729 4.5 6.28581 4.5 6.33333V9.75H19.5V6.33333C19.5 6.28581 19.482 6.21729 19.4006 6.1427C19.3171 6.06612 19.1794 6 19 6H16.5V6.75C16.5 7.16421 16.1642 7.5 15.75 7.5C15.3358 7.5 15 7.16421 15 6.75V6ZM4.5 19.1667V11.25H19.5V19.1667C19.5 19.2142 19.482 19.2827 19.4006 19.3573C19.3171 19.4339 19.1794 19.5 19 19.5H5C4.82064 19.5 4.68292 19.4339 4.59937 19.3573C4.518 19.2827 4.5 19.2142 4.5 19.1667Z"
      fill="currentcolor"
    />
  </svg>
);
