export const IconAvatar = () => (
  <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="90" height="90" rx="45" fill="#121212" />
    <rect width="90" height="90" rx="45" fill="#0A0A0A" />
    <rect width="90" height="90" rx="45" fill="url(#paint0_angular_18611_61212)" fillOpacity="0.3" />
    <rect width="90" height="90" rx="45" fill="url(#paint1_angular_18611_61212)" fillOpacity="0.4" />
    <g opacity="0.6">
      <path
        d="M26.858 47.3894C28.3552 47.3894 29.5689 46.1757 29.5689 44.6785C29.5689 43.1813 28.3552 41.9675 26.858 41.9675C25.3608 41.9675 24.1471 43.1813 24.1471 44.6785C24.1471 46.1757 25.3608 47.3894 26.858 47.3894Z"
        fill="white"
      />
      <path d="M61.3704 33.4286H66.0209L51.2227 55.928H46.5115L61.3704 33.4286Z" fill="white" />
      <path
        d="M53.0013 43.6006L46.6169 33.4288H51.6782L54.1822 37.8997C54.9546 39.2792 54.8636 40.9795 53.9481 42.2681C53.6357 42.7081 53.3169 43.1564 53.0013 43.6016V43.6006Z"
        fill="white"
      />
      <path
        d="M59.5158 45.7327L65.9158 55.9284H60.8545L58.3348 51.4325C57.5625 50.0551 57.6534 48.3558 58.5679 47.0683C58.8814 46.6273 59.2002 46.1779 59.5158 45.7327Z"
        fill="white"
      />
      <path
        d="M47.3463 37.1518C46.6775 35.9792 45.7285 35.0658 44.5006 34.4105C43.2716 33.7563 41.8126 33.4292 40.1207 33.4292H30.8488V41.956H33.5628C35.0583 41.956 36.2696 40.7437 36.2696 39.2492V37.8237H38.9418C39.7674 37.8237 40.4593 37.9627 41.0184 38.2397C41.5775 38.5166 41.9997 38.9096 42.2871 39.4175C42.5735 39.9264 42.7177 40.5326 42.7177 41.237C42.7177 41.9413 42.5745 42.5203 42.2871 43.0334C41.9997 43.5476 41.5775 43.9479 41.0184 44.2342C40.4593 44.5216 39.7727 44.6648 38.9575 44.6648H33.59C32.0757 44.6648 30.8488 45.8928 30.8488 47.406V55.9286H36.2696V48.9527H39.9691C41.681 48.9527 43.165 48.633 44.4243 47.9934C45.6826 47.3538 46.6513 46.4561 47.3306 45.2981C48.0099 44.1402 48.3496 42.7868 48.3496 41.2359C48.3496 39.685 48.0152 38.3243 47.3453 37.1518H47.3463Z"
        fill="white"
      />
    </g>
    <defs>
      <radialGradient
        id="paint0_angular_18611_61212"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(74.1176 66.7059) rotate(-133.668) scale(48.3043 45.8617)"
      >
        <stop stopColor="#626161" />
        <stop offset="1" stopColor="#252525" />
      </radialGradient>
      <radialGradient
        id="paint1_angular_18611_61212"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(73.5882 63) rotate(-79.4792) scale(37.6925 26.4747)"
      >
        <stop stopColor="#41403E" />
        <stop offset="1" stopColor="#252523" />
      </radialGradient>
    </defs>
  </svg>
);
