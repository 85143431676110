import useSWRSubscription from 'swr/subscription';

import { getClientApiDomain } from '@/api/fetchers/fetchClient';
import { RecapAsset } from '@/api/trading/types';
import { mapAssetPlatform } from '@/api/trading/utils';
import { ApiPlatform } from '@/api/types';

export const useRecap = (
  platform?: ApiPlatform,
  initialData: RecapAsset[] | null = [],
): { markets: RecapAsset[] | null; error: boolean; loading: boolean } => {
  const { data = initialData, error } = useSWRSubscription<
    (RecapAsset & { platform: 'PFX' | 'FX' })[],
    any,
    string
  >(`wss://${getClientApiDomain()}/v2/ws/`, (key, { next: updateState }) => {
    const socket = new WebSocket(key);

    socket.addEventListener('message', (event) => {
      updateState(null, JSON.parse(event.data).body.data);
    });

    socket.addEventListener('open', () => {
      socket.send(
        JSON.stringify({
          action: 'recap',
          type: 'SUBSCRIPTION',
          rid: 0,
          body: {
            platform,
          },
        }),
      );
    });

    socket.addEventListener('error', () => {
      updateState(true);
    });

    return () => socket.close();
  });

  return {
    markets: !data
      ? null
      : platform
        ? data.map(mapAssetPlatform).filter((asset) => asset.platform === platform)
        : data.map(mapAssetPlatform),
    loading: !error && !data?.length,
    error,
  };
};
