import { WithClassName } from '@/blocks/components/types';
import { partnersSiteDomain } from '@/global/public-settings';
import classNames from 'classnames';
import { useLocale } from 'next-intl';
import { getLocale } from 'next-intl/server';
import Link from 'next/link';
import { PropsWithChildren } from 'react';

export const partnersSiteUrl = `https://${partnersSiteDomain}`;

export function PartnersSignupLink({ children, className }: Omit<PropsWithChildren<WithClassName>, 'href'>) {
  return (
    <Link href={'/signup'} className={classNames(className)}>
      {children}
    </Link>
  );
}

export async function getPartnersSiteLink() {
  const locale = await getLocale();
  return new URL(locale, partnersSiteUrl).toString();
}

export function PartnersSiteLink({ children, className }: Omit<PropsWithChildren<WithClassName>, 'href'>) {
  const locale = useLocale();
  return (
    <Link className={classNames(className)} href={new URL(locale, partnersSiteUrl).toString()}>
      {children}
    </Link>
  );
}
