'use client';

import { useContext, useEffect, useState } from 'react';

import classNames from 'classnames';
import { useTranslations } from 'next-intl';
import Link from 'next/link';

import { IconCross, IconDownload } from '@/blocks/components/Icons';
import { LogoShort } from '@/blocks/components/Logo';
import { useMobileStoreLink } from '@/blocks/components/PlatformLink/useMobileStoreLink';
import { BurgerContext } from '@/blocks/sections/Header/Components/HeaderInner';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { useDeviceDetect } from '@/hooks/useDeviceDetect';
import { downloadAppEventProps } from '@/providers/analytics/types';
import buttons from '@/styles/button.module.scss';
import context from '@/styles/contexts.module.scss';
import typography from '@/styles/typography.module.scss';
import { getCookie, setCookie } from '@/utils/cookies';

import styles from './styles.module.scss';

const BANNER_DESKTOP_HEIGHT = '0px'; // 60px
const BANNER_MOBILE_HEIGHT = '52px';
const bannerCookieName = 'app-banner-closed';

const checkBannerVisibility = (): boolean => {
  return !getCookie(bannerCookieName);
};

const getBannerHeight = (
  isVisible: boolean,
  isDesktop: boolean | null,
  isIOS: boolean,
  isAndroid: boolean,
) => {
  if (!isVisible) return '0px';
  if (isDesktop) return BANNER_DESKTOP_HEIGHT;
  if (isIOS || isAndroid) return BANNER_MOBILE_HEIGHT;
  return '0px';
};

export const DownloadAppBanner = () => {
  const tr = useTranslations('downloadAppBanner');
  const { menuOpened } = useContext(BurgerContext);
  const { universalLink } = useMobileStoreLink({ location: 'top_banner' });
  const { isAndroid, isIOS } = useDeviceDetect();
  const [isVisible, setIsVisible] = useState(false);
  const bp = useBreakpoint();
  const isDesktop = bp === 'desktop';

  useEffect(() => {
    setIsVisible(checkBannerVisibility());
  }, []);

  useEffect(() => {
    const bannerHeight = getBannerHeight(isVisible, isDesktop, isIOS, isAndroid);
    document?.documentElement.style.setProperty('--height-app-banner', bannerHeight);
  }, [isVisible, isDesktop, isAndroid, isIOS]);

  const handleClose = () => {
    setIsVisible(false);
    document?.documentElement.style.setProperty('--height-app-banner', '0px');
    setCookie(
      bannerCookieName,
      JSON.stringify({
        createdAt: Date.now(),
        value: {
          close: 1,
        },
      }),
    );
  };

  if (!isVisible) {
    return null;
  }

  return (
    <>
      {/*{isDesktop && (*/}
      {/*  <div className={styles.banner}>*/}
      {/*    <div className={styles.container}>*/}
      {/*      <div className={styles.desktop}>*/}
      {/*        <Image src={icon} width={32} height={32} alt="" className={styles.icon} />*/}
      {/*        <span className={classNames(typography.bodyS, styles.title)}>{tr('desktop.title')}</span>*/}
      {/*        <div className={styles.storeButtons}>*/}
      {/*          <AppStoreButton*/}
      {/*            variant={'light'}*/}
      {/*            size={'s'}*/}
      {/*            className={styles.storeButton}*/}
      {/*            location={'top_banner'}*/}
      {/*          />*/}
      {/*          <GooglePlayButton*/}
      {/*            variant={'light'}*/}
      {/*            size={'s'}*/}
      {/*            className={styles.storeButton}*/}
      {/*            location={'top_banner'}*/}
      {/*          />*/}
      {/*        </div>*/}
      {/*        <button className={classNames(buttons.btn, styles.closeButton)} onClick={handleClose}>*/}
      {/*          <IconCross />*/}
      {/*        </button>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}

      {(isIOS || isAndroid) && bp !== 'desktop' && (
        <div
          className={classNames(styles.banner, {
            [context.light]: menuOpened,
          })}
        >
          <div className={styles.container}>
            <div className={styles.mobile}>
              <button
                className={classNames(buttons.btn, styles.closeButton, {
                  [styles.closeButtonLight]: menuOpened,
                })}
                onClick={handleClose}
              >
                <IconCross />
              </button>
              <div className={classNames(context.dark, styles.logoContainer)}>
                <LogoShort />
              </div>
              <div className={styles.content}>
                <div className={classNames(typography.bodyS, styles.title)}>{tr('mobile.title')}</div>
                <div className={classNames(typography.bodyXS)}>{tr('mobile.subhead')}</div>
              </div>

              <Link
                href={universalLink}
                target={'_blank'}
                className={classNames(buttons.btn, buttons.btnOutline, buttons.btnXXS, buttons.btnIcon)}
                data-event-name={downloadAppEventProps.event_name}
                data-event-category={downloadAppEventProps.event_category}
                data-event-subcategory={downloadAppEventProps.event_subcategory}
                data-event-action={downloadAppEventProps.event_action}
                data-event-label={isIOS ? 'apple_store' : isAndroid ? 'google_play' : ''}
                data-event-location={'top_banner'}
                data-event-include-url
              >
                <IconDownload />
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
