export const IconDiamond = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="81" height="80" viewBox="0 0 81 80" fill="none">
      <path
        d="M28.625 29H7.125L39.125 69L28.625 29Z"
        fill="url(#paint0_linear_15695_23429)"
        fillOpacity="0.8"
      />
      <path
        d="M17.57 8L6.52344 29.2352M17.57 8L28.8836 29.2352M17.57 8H40.1234M6.52344 29.2352L40.059 71.2M6.52344 29.2352H28.8836M73.7234 29.2352L62.6769 8M73.7234 29.2352L40.059 71.2M73.7234 29.2352H51.2344M62.6769 8L51.2344 29.2352M62.6769 8H40.1234M40.059 71.2L28.8836 29.2352M40.059 71.2L51.2344 29.2352M28.8836 29.2352H51.2344M28.8836 29.2352L40.1234 8M51.2344 29.2352L40.1234 8"
        stroke="#B5B5B5"
        strokeWidth="1.6"
      />
      <defs>
        <linearGradient
          id="paint0_linear_15695_23429"
          x1="8.12273"
          y1="33.4908"
          x2="33.0048"
          y2="35.4655"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7A95FF" stopOpacity="0.29" />
          <stop offset="1" stopColor="#3760FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
