import React, { ComponentPropsWithRef } from 'react';
import styles from './VStack.module.scss';
import classNames from 'classnames';

type Props = ComponentPropsWithRef<'div'>;

// eslint-disable-next-line react/display-name
export const VStack = React.forwardRef(({ children, className, ...rest }: Props, ref) => {
  return (
    // @ts-ignore
    <div className={classNames(styles.VStack, className)} ref={ref} {...rest}>
      {children}
    </div>
  );
});
