export function extractNumber(val: any, defaultVal: number = 0): number {
  return isFiniteNumber(val) ? val : defaultVal;
}

export function isFiniteNumber(val: any): val is number {
  return typeof val === 'number' && isFinite(val);
}

export function getDecimalPlaceCount(number?: string | number) {
  return number?.toString()?.split('.')?.[1]?.length || 0;
}

// TODO replace
export function beautifyNumber(num: number) {
  if (num < 10) return num;

  const roundTo = num >= 100 ? 50 : 10;
  const remainder = num % roundTo;

  if (remainder === 0) return num;

  return num - remainder;
}
