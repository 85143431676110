'use client';
import { ComponentProps } from 'react';
import { TypeAnimation } from 'react-type-animation';

export const Typing = ({
  words,
  delayMs = 1500,
  speed = 40,
}: {
  speed?: ComponentProps<typeof TypeAnimation>['speed'];
  words: string[];
  delayMs?: number;
}) => {
  return (
    <TypeAnimation
      preRenderFirstString={true}
      style={{ whiteSpace: 'pre-line' }}
      omitDeletionAnimation={true}
      speed={speed}
      sequence={words.map((word) => [word, delayMs]).flat()}
      repeat={Infinity}
    />
  );
};
