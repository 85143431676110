import styles from './styles.module.scss';
import classNames from 'classnames';
import React, { ComponentPropsWithoutRef } from 'react';

export const SliderDots = ({ className }: ComponentPropsWithoutRef<'div'>) => {
  return (
    <div className={classNames(styles.sliderControlsContainer, className)}>
      <ul className={classNames(styles.sliderPaginationContainer, 'splide__pagination')}></ul>
    </div>
  );
};
