import { ArrowLeft } from '@/blocks/components/Icons';
import buttons from '@/styles/button.module.scss';
import classNames from 'classnames';
import { useTranslations } from 'next-intl';

export function NavigateBack(props: { onClick: any }) {
  const tr = useTranslations('menu');
  return (
    <button
      className={classNames(buttons.btn, buttons.btnLink, buttons.btnM, buttons.btnIconLeft)}
      onClick={props.onClick}
    >
      <ArrowLeft />
      {tr('btn.back')}
    </button>
  );
}
