import styles from './styles.module.scss';

export function ActionBurger(props: { onClick: any; opened: boolean }) {
  return (
    <button className={styles.actionsBurger} onClick={props.onClick}>
      {props.opened ? (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.5444 29.5981L29.9704 10.1722" stroke="#0A0A0A" strokeWidth="2" strokeLinecap="round" />
          <path d="M10 10L19.7403 19.7403" stroke="#0A0A0A" strokeWidth="2" strokeLinecap="round" />
          <path d="M20.2612 20.2598L30.0015 30" stroke="#0A0A0A" strokeWidth="2" strokeLinecap="round" />
        </svg>
      ) : (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 20H34" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" />
          <path d="M7 10H34" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" />
          <path d="M7 30L34 30" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" />
        </svg>
      )}
    </button>
  );
}
