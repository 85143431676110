export enum EventBlockNames {
  legal_docs_list = 'legal_docs_list',
  fees = 'fees',
  icon_card_list = 'icon_card_list',
  help_center = 'help_center',
  hero = 'hero',
  benefits = 'benefits',
  promos_slider = 'promos_slider',
  image_articles = 'image_articles',
  checkmarks_articles = 'checkmarks_articles',
  cta = 'cta',
  assets_table = 'assets_table',
  voucher = 'voucher',
  rewards_banner = 'rewards_banner',
  confidence_cards = 'confidence_cards',
  usp_tabs = 'usp_tabs',
  product_cards = 'product_cards',
  platforms = 'platforms',
  steps = 'steps',
  social_links = 'social_links',
  vertical_articles_cards_list = 'vertical_articles_cards_list',
  strategies_slider = 'strategies_slider',
  posts_grid = 'posts_grid',
  last_post = 'last_post',
  all_promos_cards_list = 'all_promos_cards_list',
  welcome_bonus = 'welcome_bonus',
  certificate = 'certificate',
  ongoing_contests = 'ongoing_contests',
  best_trader_widget = 'best_trader_widget',
  similar_assets = 'similar_assets',
  buy_sell_widget = 'buy_sell_widget',
  buy_or_trade_widget = 'buy_or_trade_widget',
  scrolling_assets_line = 'scrolling_assets_line',
  strategies_table = 'strategies_table',
  strategies_table_search = 'strategies_table_search',
  assets_table_search = 'assets_table_search',
  buy_crypto_widget = 'buy_crypto_widget',
  invest = 'invest',
  features = 'features',
  about = 'about',
  profitable_trader = 'profitable_trader',
  top_trader = 'top_trader',
  earn_tabs = 'earn_tabs',
  expert_banner = 'expert_banner',
  traders_table = 'traders_table',
  awards = 'awards',
  community = 'community',
  modal_apk_download = 'modal_apk_download',
  currencyConverter = 'currency_converter',
  conversionsTable = 'conversions_table',
  breadcrumbs = 'breadcrumbs',
  header = 'header',
  assetsGrid = 'assets_grid',
  download_mt5 = 'download_mt5',
  access_mt5 = 'access_mt5',
  modal_mt5 = 'modal_mt5',
  faq = 'faq',
  quote = 'quote',
  become_pro_progress = 'become_pro_progress',
  turnover_rewards = 'turnover_rewards',
  contests_voucher = 'contests_voucher',
  promo_row_cards = 'promo_row_cards',
}

export const ctaEventProps = {
  event_name: 'site_cta_buttons_click',
  event_category: 'site_cta_buttons',
  event_action: 'button_click',
};

export const formEventProps = {
  event_name: 'site_form_submit',
  event_category: 'site_form',
  event_action: 'form_submit',
};

export const downloadAppEventProps = {
  // store button
  event_name: 'site_go_to_app_click',
  event_category: 'site',
  event_subcategory: 'go_to_app',
  event_action: 'button_click',
  label_google: 'google_play',
  label_apple: 'apple_store',
  label_apk: 'apk_download',

  // location: 'inside_top_menu',

  // app banner
  // event_name: 'site_go_to_app_click',
  // event_category: 'site',
  // event_subcategory: 'go_to_app',
  // event_action: 'button_click',
  // location: 'top_banner',

  //footer
  // event_name: 'site_go_to_app_click',
  // event_category: 'site',
  // event_subcategory: 'go_to_app',
  // event_action: 'button_click',
  // // event_label: '{apple_store|google_play}',
  // location: 'footer',
};
