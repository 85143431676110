import styles from './styles.module.scss';
import { IconPriceTrend } from '@/blocks/components/Table/IconPriceTrend/IconPriceTrend';
import { FormatPrice } from '@/blocks/formatters';
import classNames from 'classnames';
import { isNumber } from '@/blocks/components/Table/Cells/utils';
import { ComponentPropsWithoutRef } from 'react';

type CellPriceProps = ComponentPropsWithoutRef<'td'> & {
  price?: number | null;
  negative?: boolean;
  icon?: boolean;
  priceScale?: number | undefined;
};

const CellPrice = ({ price, className, negative, icon, priceScale, ...rest }: CellPriceProps) => {
  return (
    <td className={classNames(className)} {...rest}>
      {isNumber(price) ? (
        <div className={classNames(styles.cell, styles.cellPrice)}>
          {icon && <IconPriceTrend negative={negative} />}
          <FormatPrice price={price} priceScale={priceScale} />
        </div>
      ) : null}
    </td>
  );
};
export { CellPrice };
