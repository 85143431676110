export function IconCircleInfo() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 6C9.75 8.07107 8.07107 9.75 6 9.75C3.92893 9.75 2.25 8.07107 2.25 6C2.25 3.92893 3.92893 2.25 6 2.25C8.07107 2.25 9.75 3.92893 9.75 6ZM10.5 6C10.5 8.48528 8.48528 10.5 6 10.5C3.51472 10.5 1.5 8.48528 1.5 6C1.5 3.51472 3.51472 1.5 6 1.5C8.48528 1.5 10.5 3.51472 10.5 6ZM6 4.5C6.31066 4.5 6.5625 4.24816 6.5625 3.9375C6.5625 3.62684 6.31066 3.375 6 3.375C5.68934 3.375 5.4375 3.62684 5.4375 3.9375C5.4375 4.24816 5.68934 4.5 6 4.5ZM4.875 5.625C4.875 5.41789 5.04289 5.25 5.25 5.25H6H6.375V5.625V7.6875H6.75C6.95711 7.6875 7.125 7.85539 7.125 8.0625C7.125 8.26961 6.95711 8.4375 6.75 8.4375H6H5.25C5.04289 8.4375 4.875 8.26961 4.875 8.0625C4.875 7.85539 5.04289 7.6875 5.25 7.6875H5.625V6H5.25C5.04289 6 4.875 5.83211 4.875 5.625Z"
        fill="currentcolor"
      />
    </svg>
  );
}
