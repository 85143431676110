export const IconEuro = () => (
  <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icons / website / euro" clipPath="url(#clip0_1739_202)">
      <path
        id="Vector"
        d="M46.625 49.4171C44.035 51.7336 40.8298 53.251 37.3964 53.786C33.963 54.321 30.4481 53.8508 27.276 52.4321C24.104 51.0134 21.4104 48.7069 19.5204 45.791C17.6304 42.8751 16.6248 39.4744 16.625 35.9996V27.9996C16.6248 24.5247 17.6304 21.1241 19.5204 18.2082C21.4104 15.2923 24.104 12.9857 27.276 11.567C30.4481 10.1483 33.963 9.6781 37.3964 10.2131C40.8298 10.7481 44.035 12.2655 46.625 14.5821"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M10.625 28H34.625"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M10.625 36H30.625"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1739_202">
        <rect width="64" height="64" fill="white" transform="translate(0.625)" />
      </clipPath>
    </defs>
  </svg>
);
