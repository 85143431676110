import { usePathname } from 'next/navigation';
import { useId, useLayoutEffect } from 'react';
import styles from './styles.module.scss';

let scrollLocksRegistry: Record<string, boolean> = {};
let lastScroll: number | null;

function update() {
  if (Object.values(scrollLocksRegistry).filter(Boolean).length) {
    document.body.classList.add(styles.customScrollLock);
  } else {
    document.body.classList.remove(styles.customScrollLock);
    if (typeof lastScroll === 'number') {
      window.scrollTo(0, lastScroll);
      lastScroll = null;
    }
  }
}

export function useScrollLock(name?: string) {
  const id = useId();
  const pathname = usePathname();

  useLayoutEffect(() => {
    scrollLocksRegistry = {};
    lastScroll = null;
  }, [pathname]);

  return {
    setScrollLock(v: boolean) {
      scrollLocksRegistry[name ? name : id] = v;
      if (v && typeof lastScroll !== 'number') {
        lastScroll = window.scrollY;
      }
      update();
    },
  };
}
