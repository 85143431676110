import React from 'react';

export const IconEye = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5.25C7.77636 5.25 4.20163 8.08789 3 12C4.20163 15.9121 7.77636 18.75 12 18.75C16.2236 18.75 19.7984 15.9121 21 12C19.7984 8.08789 16.2236 5.25 12 5.25ZM14.6677 9.27259C15.3753 9.99594 15.7728 10.977 15.7728 12C15.7728 13.023 15.3753 14.0041 14.6677 14.7274C13.9602 15.4508 13.0006 15.8571 12 15.8571C10.9994 15.8571 10.0398 15.4508 9.33223 14.7274C8.6247 14.0041 8.22721 13.023 8.22721 12C8.22721 10.977 8.6247 9.99594 9.33223 9.27259C10.0398 8.54924 10.9994 8.14286 12 8.14286C13.0006 8.14286 13.9602 8.54924 14.6677 9.27259ZM13.591 13.591C13.169 14.013 12.5967 14.25 12 14.25C11.4033 14.25 10.831 14.013 10.409 13.591C9.98705 13.169 9.75 12.5967 9.75 12C9.75 11.4033 9.98705 10.831 10.409 10.409C10.831 9.98705 11.4033 9.75 12 9.75C12.5967 9.75 13.169 9.98705 13.591 10.409C14.013 10.831 14.25 11.4033 14.25 12C14.25 12.5967 14.013 13.169 13.591 13.591Z"
      fill="currentcolor"
    />
  </svg>
);
