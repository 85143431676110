export const IconHome = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6 13.133 16 6l10 7.133v12.42c0 .06-.024.157-.133.256a.797.797 0 0 1-.534.19H21V15.773a1 1 0 0 0-1-1h-8a1 1 0 0 0-1 1V26H6.667a.797.797 0 0 1-.534-.19c-.109-.1-.133-.197-.133-.256V13.133ZM13 26h6v-9.228h-6V26ZM4 13.133a2 2 0 0 1 .839-1.628l10-7.134a2 2 0 0 1 2.322 0l10 7.134A2 2 0 0 1 28 13.133v12.42c0 .65-.281 1.272-.781 1.73-.5.459-1.178.716-1.886.716H6.667a2.794 2.794 0 0 1-1.886-.716A2.347 2.347 0 0 1 4 25.553v-12.42Z"
      clipRule="evenodd"
    />
  </svg>
);
